import React, {useState} from "react";
import { ITextInput, TextInput } from "./TextInput";

export type PhoneMask = string | string[];
export type PhoneFormat = string | {[k: string]: string};

interface ITextInputPhone extends Omit<ITextInput, 'onChange'> {
  onChange: (s: string) => void;
  mask?: PhoneMask,
  format?: PhoneFormat,
  persistentMask?: boolean
}

const formatPhoneNumber = (s: string, mask : PhoneMask, format : PhoneFormat, recursive = true) : [string, boolean] => {
  s = s.replace(/[^\d+]/g, '').trim()
  let f = ''
  if (typeof mask === 'string')
    mask = [mask]

  // consume mask
  let hasMask = false;
  for (let m of mask) {
    if (s.substring(0, m.length) === m) {
      f += m
      s = s.substring(m.length)
      if (typeof format !== 'string')
        format = format[m] || Object.keys(format)[0]
      hasMask = true
      break
    }
  }

  if (!hasMask) {
    if (s === '') return ['', false];
    let m = mask[0];
    for (let m_ of mask) {
      if (m_.startsWith(s)) {
        m = m_;
        s = ""
        break;
      }
    }

    if (typeof format !== 'string')
      format = format[m] || Object.keys(format)[0]
    let numberCount = format.replace(/[^_]/g, '').length;
    if (s.length > numberCount) {
      s = s.substring(s.length - numberCount)
    }
    return recursive ? formatPhoneNumber(m + s, m, format, false) : [m + s, false];
  }
  format = format as string; // for ts

  s = s.replace(/\+/g, '');

  let fullBody = false;
  for (let i = 0, j = 0; i < s.length;) {
    if (format.charAt(j) === '_') {
      f += s.charAt(i);
      i++;
      j++;
    } else {
      f += format.charAt(j);
      j++;
    }
    if (j >= format.length) {
      fullBody = true;
      break;
    }
  }

  return [f, hasMask && fullBody];
}

// only russian numbers are supported
export function isCompletedPhoneNumber (s: string, mask : PhoneMask, format: PhoneFormat) {
  return formatPhoneNumber(s, mask, format)[1]
}

export function TextInputPhone({value, type, onChange, mask, format, persistentMask = true, onFocus, ...props}: ITextInputPhone) {
  const [wasFocused, setWasFocused] = useState(false);

  const fmt = (s : string) : [string, boolean] => {
    if (!mask || !format)
      return [s, true];
    let result = formatPhoneNumber(s, mask, format)
    if (wasFocused && persistentMask) {
      let m = Array.isArray(mask) ? mask[0] : mask;
      if (m && result[0].length < m.length) {
        result[0] = m;
      }
    }
    return result
  }

  return <TextInput
    value={fmt(value)[0]}
    placeholderInput={mask && format ? (Array.isArray(mask) ? mask[0] : mask) + format : ''}
    onFormat={(s: any) => fmt(s)[0]}
    onChange={(s: any) => {
      onChange(s);
    }}
    onFocus={(e: any) => {
      setWasFocused(true)
      if (onFocus)
        return onFocus(e);
    }}
    inputmode="numeric"
    onPaste={(e: any) => {
      let clipboardData = e.clipboardData?.getData('Text')
      if (clipboardData) {
        const [phone, complete] = fmt(clipboardData)
        if (complete) {
          e.preventDefault();
          onChange(phone);
        }
      }
    }}
    {...props}/>
}
