import React from 'react'
import Product from '../../common/products/Product'
import GiftProduct from '../../common/products/GiftProduct'

export default function Products({ order, className='' }: any) {
  return (
    <div className={`flex flex-col md:gap-y-5 ${className}`}>
      {
        order.goods.filter((p: any) => !p?.isGift).map((product: any, index: number) => (
            <Product
              key={`${index}_${product.total}`}
              productData={{ 
                image: product.good.image,
                title: product.good.title,
                skuGroupsValues: product.good.skuGroupsValues,
                options: product.options.map((option: any) => ({title: option.option.title, count: option.quantity})),
                weight: product.good.weight,
                size: product.good.size,
                measure_unit: product.good.measureUnit,
                count: product.quantity,
                amount: Math.round(product.total * 100 / product.quantity) / 100
              }}
              isLastProduct={order.goods.length - 1 == index}
            />
          )
        )
      }
      {
        order.goods.filter((p: any) => p?.isGift).map((product: any, index: number) => (
            <GiftProduct
              key={`${index}_${product.total}`}
              productData={{ 
                image: product.good.image,
                title: product.good.title,
                skuGroupsValues: product.good.skuGroupsValues,
                options: product.options.map((option: any) => ({title: option.option.title, count: option.quantity})),
                weight: product.good.weight,
                size: product.good.size,
                measure_unit: product.good.measureUnit,
                count: product.quantity,
              }}
            />
          )
        )
      }
    </div>
  )
}
