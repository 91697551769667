import React from 'react'

export default function GiftIcon({className='w-[15px] h-[18px]', colorClassName='fill-white'}: {
  className?: string;
  colorClassName?: string;
}) {
  return (
    <svg className={ className } viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className={ colorClassName } fillRule="evenodd" clipRule="evenodd" d="M12.0697 5.32991C11.1276 5.85718 9.53879 6.11202 8.86212 6.17354H14.6094V9.18777H8.22939V6.17354H6.73546V9.18777H0.390625V6.17354H6.34C6.31657 6.17354 6.06406 6.11905 5.24152 5.90111C4.21334 5.62869 3.24668 5.04869 2.58759 4.6093C1.9285 4.16991 1.62972 3.62506 1.50669 2.91324C1.40827 2.34379 1.75275 1.81476 1.93729 1.62143C2.38547 1.28456 3.3539 0.573917 3.64214 0.42628C4.00244 0.241735 4.56486 0.136281 4.94274 0.250523C5.32062 0.364765 5.76001 0.610826 6.09395 1.11173C6.42789 1.61264 6.76183 2.45627 6.93758 3.17688C7.07819 3.75336 7.24223 4.82314 7.30667 5.28596C7.36526 5.10142 7.52461 4.59172 7.69334 4.0293C7.90425 3.32627 8.33485 2.29809 8.86212 1.62143C9.3894 0.944764 9.76727 0.725068 10.4176 0.610826C10.9378 0.519433 11.5131 0.871533 11.7358 1.05901L12.3421 1.66537C12.6702 1.99052 13.351 2.77967 13.4494 3.33506C13.5724 4.0293 13.2473 4.67082 12.0697 5.32991ZM6.73546 17.4484V9.57444H1.09365V17.4484H6.73546ZM8.25576 9.59201H13.8097V17.4484H8.25576V9.59201ZM5.11135 3.36138C5.72299 4.14174 6.54377 5.45581 6.87771 6.01531C6.73418 5.93329 6.38032 5.71652 6.11317 5.50561C5.77923 5.24198 4.43469 4.03804 3.87227 3.2735C3.30984 2.50895 3.16924 1.66532 3.37136 1.72683C3.57348 1.78835 4.34681 2.38592 5.11135 3.36138ZM7.56282 6.05027C8.09009 5.43219 9.35378 4.02906 10.1904 3.36118C11.2361 2.52634 11.5701 2.37694 11.614 2.51755C11.658 2.65816 11.8161 3.3524 10.4716 4.2927C9.39596 5.04494 8.08423 5.77784 7.56282 6.05027Z"/>
    </svg>
  )
}
