import { useEffect, useState } from "react";
import FirstStep from "./firstStep";
import SecondStep from "./secondStep";
import ThirdStep from "./thirdStep";
import { IQuestionnaire } from "../../../pages/[city]/account/history";


export default function FeedbacksPopupContent({questionnaire}: {questionnaire: IQuestionnaire}) {
  const [step, setStep] = useState(1);
  let maxLengthTextarea = 1000;

  useEffect(() => {
    // orderReviewCreatedAt заполнен первый шаг
    // goodReviewCreatedAt заполнен второй шаг
    // questionAnswerCreatedAt заполнен третий шаг
    if (questionnaire?.questionAnswerCreatedAt) {
      nextStepFrom(4)
    } else if (questionnaire?.goodReviewCreatedAt) {
      nextStepFrom(3)
    } else if(questionnaire?.orderReviewCreatedAt) {
      nextStepFrom(2)
    }
  }, [])

  const nextStepFrom = (newStep: number) => {
    for (; newStep < 4; newStep++) {
      // условие пропуска второго шага
      if (newStep === 2 && !questionnaire.goods.length) {
        continue
      }
      // условие пропуска 3 шага
      if (newStep === 3 && !questionnaire.questions.length) {
        continue
      }
      break
    }
    setStep(newStep)
  }

  const nextStep = () => nextStepFrom(step + 1)

  return (
      <>
        <div className={`xl:max-h-[690px] max-h-[510px] overflow-y-scroll no-scroll-bar ${step === 4? 'xl:h-fit h-fit' : '' }`}>
          {step < 4 && (
            <div className="mb-[30px]">
              <h3 className="text-2xl font-bold text-dark dark:text-light">
                {step < 2? 'Оставьте отзыв' : step === 2 && 'Оцените блюдо из вашего заказа'}
              </h3>
            </div>
          )}
          {step === 1 && (
            <FirstStep inPopup={true} maxLengthTextarea={maxLengthTextarea} nextStep={nextStep} questionnaireId={questionnaire.id}/>
          )}

          {step === 2 &&
            <SecondStep inPopup={true} goods={questionnaire?.goods} nextStep={nextStep} questionnaireId={questionnaire.id}/>
          }

          {step === 3 &&
            <ThirdStep inPopup={true} maxLengthTextarea={maxLengthTextarea} questions={questionnaire?.questions} nextStep={nextStep} questionnaireId={questionnaire.id}/>
          }

          {step === 4 &&
            <div className="mb-[20px] m-auto">
              <h3 className=" text-xl md:text-2xl font-bold mb-[15px] text-dark dark:text-light">Спасибо за отзыв и обратную связь!</h3>
              <h3 className=" text-xl md:text-2xl font-bold text-main">Это очень важно для нас!</h3>
            </div>
          }

          {step < 4 && (
            <p className="text-gray-50 text-sm font-medium opacity-70 mt-[15px] leading-4 text-center mb-[10px] dark:text-gray-10">
              Размещая отзыв, вы даете согласие на использование{" "}
              <a href="" className="text-main">
                данных отзыва на сторонних ресурсах
              </a>
            </p>
          )}
        </div>
    </>

  );
}
