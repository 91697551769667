import { useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "../../contexts/App";
import { localStorageWrap } from "./localStorageWrap";


export default function useSelectCityAndBranch() {
  const {reloadWithoutReload, city, branch} = useAppContext()
  const navigate = useNavigate();
  let location = useLocation();

  return async function selectCityAndBranch (slug: string, branchId?: string | number | undefined | null) {
    // Если город не передали, то это сразу ошибка
    if (!slug) {
      console.error(`nothing is passed to selectCity`)
      return false
    }

    let changed = false
    let path = null

    // Если передали отличающийся от нынешнего город, то меняем его
    if (slug !== city?.slug) {
      localStorageWrap.setItem('city', slug);
      console.log(`reloading city ${city?.slug}->${slug}`)
      path = `/${slug}`

      if (!branchId) {
        localStorageWrap.removeItem('branchId');
      }

      changed = true
    } 
    
    // Если город не изменился, но выбран новый филиал, то меняем только филиал
    if (branchId && branchId !== branch.id) { 
      localStorageWrap.setItem('branchId', branchId + '');
      console.log(`reloading branch ${branch?.id}->${branchId}`);
      changed = true
    }

    // Если хоть что-то изменилось обновляем данные
    if (changed) {
      if (path) {
        navigate(path)
      } 
      await reloadWithoutReload();
    }

    return true
  }

}