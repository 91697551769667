import React, { useEffect, useMemo, useRef, useState } from "react";

import {
  Map,
  Placemark,
  GeolocationControl,
  ZoomControl,
} from "@pbe/react-yandex-maps";
import ymaps, { control, EventMap, IEvent } from "yandex-maps";

import PopupActionSheet from "../PopupActionSheet";
import { useAppContext } from "../../../../contexts/App";
import { ChangeBranchPopup } from "./Modals";
import { apiClient } from "../../../../libs/api/apiClient";
import { formatAddress } from "../../../../libs/helpers/formatAddress";
import { timeoutUntilSuccess } from "../../../../libs/helpers/timeoutUntilSuccess";
import { useWindowResize } from "../../../../hooks/useWindowResize";
import CustomRadioButton from "../../form/radio/CustomRadioButton";
import { BaseButton } from "../../button/BaseButton";
import {
  InputSelectorAddressSearchWithSaved,
  InputSelectorCitySelectSearch,
  InputSelectorPointSearch,
} from "../../form/input/InputSelectorDadata";
import { initRoundButtonsLayoutModule } from "../../../../libs/ymaps/roundButtonsModule";
import { Preloader } from "../../preloader";
import { useAddressContext } from "../../../../contexts/AddressContext";
import geolocationIcon from "../../../../images/geolocation.svg";
import { useCartContext } from "../../../../contexts/CartContext";

interface IAddressSelectorPopup {
  isActive: boolean;
  setActive: (v: boolean) => void;
  allPointCity?: boolean;
  type?: string;
}

export default function AddressSelectorPopup({
  isActive,
  setActive,
  allPointCity = true,
  type = "pickup",
}: IAddressSelectorPopup) {
  const { city, company, branch, address } = useAppContext();
  const {
    trySaveDeliveryPoint,
    trySavePickupPoint,
    allUserAddresses,
    addressError,
    setAddressError,
    successSelectCallback,
    setSuccessSelectCallback,
    changeBranchPopupCallback
  } = useAddressContext();
  const { addressPreloader, setAddressPreloader } = useCartContext();

  const [mode, setMode] = useState<string>(type); //pickup delivery notZone
  const [newCity, setNewCity] = useState(city); //current city
  const [newBranch, setNewBranch] = useState(branch);

  //---------------useState for pickup
  const [newPickupPoint, setNewPickupPoint] = useState<any>(null); //current pickup points
  const [pickupPoints, setPickupPoints] = useState<any>([]); //all pickup points
  const [animPoint, setAnimPoint] = useState(false);

  //---------------useState for delivery
  const [deliveryAddress, setDeliveryAddress] = useState<any>({ name: "" }); //query address
  const [deliveryPoint, setDeliveryPoint] = useState<any>(null); //cur address
  const clickOnAddress = useRef<any>(false); //cur address

  const [isLoading, setLoading] = useState(false);
  const [isMouseDragging, setMouseDragging] = useState(false);
  const [mapHeight, setMapHeight] = useState(0);

  const mapRef = useRef<ymaps.Map | undefined>();
  const geolocationRef = useRef<any>();
  const placemarksRefs = useRef<{
    [k: string]: React.MutableRefObject<ymaps.Placemark | null>;
  }>({}).current;
  const deliveryPointRef = useRef<ymaps.Placemark | undefined>();
  const mapContainerRef = useRef<HTMLDivElement | null>(null);

  //useEffect(() => console.log("currently selected address: ", address), []);
  const [isMapRenderEnabled, setMapRenderEnabled] = useState(false);
  useEffect(() => {
    if (isActive) {
      setMapRenderEnabled(true);
    }
  }, [isActive]);

  const [cities, setCities] = useState<any>({})
  useEffect(() => {
    apiClient.delivery.getCities(company.id).then(({data}) => {
      setCities(data.cities)
    })
  }, [])

  // чтобы менялся мод при открытии модалки когда нет адреса из деталки товара
  useEffect(() => {
    // Если нет в городе зон доставки не даём установить тип выбора адреса кроме как самовывоз
    if (
      city.branches.reduce(
        (accumulator: boolean, currentValue: any) =>
          accumulator ||
          (currentValue.has_delivery_zones && !currentValue.pickup_only),
        false
      )
    ) {
      setMode(type);
    } else {
      setMode("pickup");
    }
  }, [type]);

  useEffect(() => {
    if (!isActive) {
      setTimeout(() => setSuccessSelectCallback(() => () => {}), 2000);
    }
  }, [isActive]);

  useEffect(() => {
    if (city?.slug !== newCity?.slug) {
      setNewCity(city);
      if (mode === "delivery") {
        setDeliveryPoint(null);
        setDeliveryAddress({ name: "" });
      }
    }
  }, [city?.slug]);

  //-------------------select branch for city-------------------
  useEffect(() => {
    if (city?.slug === newCity?.slug) {
      setNewBranch(branch);
    }
  }, [newCity?.slug]);

  //-------------------get all pickup/delivery points---------------------
  useEffect(() => {
    let temp: any = []
    if (allPointCity) {
      newCity.branches.forEach((branch: any) => {
        branch.pickupPoints?.forEach((pickupPoint: any) => {
          temp.push({city: newCity.slug, branchId: branch.id, ...pickupPoint})
        });
      });
    } else {
      newCity.branches.filter((b: any) => b.id === branch.id)[0]
        ?.pickupPoints?.forEach((pickupPoint: any) => {
        temp.push({city: newCity.slug, branchId: branch.id, ...pickupPoint})
      });
    }

    setPickupPoints(temp)

    if(temp.length === 0) setMode('delivery')

    if (address?.point?.city === newCity?.slug && address?.type === 'pickup') {
      setNewPickupPoint(address.point)
      // setMode('pickup')
    } else {
      setNewPickupPoint(temp[0])
    }
  }, [newCity, address?.point])

  useEffect(() => {
    if (address?.type === "delivery") {
      setDeliveryPoint(address.point);
      setDeliveryAddress({
        name: formatAddress(address.point, ["city"]) || "",
        addressId: address.point?.addressId,
      });
      setMode("delivery");
    }
  }, [address?.point]);

  const selectPickupPoint = (p: any) => {
    setNewPickupPoint(p);
    if (p && mode === "pickup") {
      // panToMap(p.lat, p.lon)
    }
  };

  const centerAtCurrentCity = () => {
    const citySlug = newCity?.slug;

    if (cities[citySlug]) {
      mapRef.current?.setCenter([+cities[citySlug].lat, +cities[citySlug].lon], 12)
      mapRef.current?.container.fitToViewport()
    }
  }

  //----------------------get delivery address pickupPoint depending on query in dadata------------
  const selectDeliveryAddress = (addr: {
    name: string;
    addressId?: string;
  }) => {
    setDeliveryAddress(addr);
    if (addr.addressId) {
      const existingAddress = allUserAddresses.find(
        (o) => o.id === addr.addressId
      );
      if (existingAddress) {
        setAddressError(null);
        setDeliveryPoint({
          ...existingAddress.address,
          addressId: addr.addressId,
        });
        clickOnAddress.current = true;
        panToAddress({
          coords: [existingAddress.address.lat, existingAddress.address.lon],
          addZoom: false,
        });
        return;
      }
    }

    if (mode === "delivery") {
      apiClient.suggestions
        .getAddressSuggestions(
          addr.name,
          newCity ? [newCity.guid] : [],
          newCity.lat,
          newCity.lon
        )
        .then(({ data, isCoordinatesCanBeClarify }: any) => {
          if (isCoordinatesCanBeClarify && data[0]?.isCoordinatesNeedToClarify) {
            clarifyCoords(data[0])
            return
          }
  
          if (data[0]) {
            setAddressError(null);
            setDeliveryPoint(data[0]);
            clickOnAddress.current = true;
            panToAddress({
              coords: [data[0].lat, data[0].lon],
              addZoom: false,
            });
          }
        });
    }
  };

  const clarifyCoords = (addr: any) => {
    apiClient.suggestions
      .getCoordsByAddress(formatAddress(addr, [], true))
      .then(({ data }: any) => {
        if (data) {
          setAddressError(null);
          setDeliveryPoint({ ...addr, lat: data.lat, lon: data.lon, });
          clickOnAddress.current = true;
          panToAddress({ coords: [data.lat, data.lon], addZoom: false });
        } else {
          //setAddressError({ type: 'message', message: 'Не удалось найти адрес' })
        }
      });
  };

  useEffect(() => {
    return timeoutUntilSuccess(() => {
      if (mapContainerRef.current && mapContainerRef.current.offsetHeight > 0) {
        setMapHeight(mapContainerRef.current.offsetHeight);
        return true;
      }
      return false;
    });
  });

  useEffect(() => {
    if (mode === "delivery" && deliveryPoint && isActive)
      return timeoutUntilSuccess(() => {
        if (deliveryPointRef.current && mapRef.current) {
          const curZoom = mapRef.current.getZoom();
          mapRef.current.setCenter(
            [deliveryPoint.lat, deliveryPoint.lon],
            curZoom,
            { duration: 500 }
          );
          mapRef.current.container.fitToViewport();
          // deliveryPointRef.current.balloon.open()
          return true;
        }
        return false;
      });
  }, [mode, isActive]);

  useEffect(() => {
    if (mode === "delivery" && deliveryPoint && isActive) {
    } else if (mode === "delivery" && isActive) return centerAtCurrentCity();
  }, [newCity?.slug, isActive]);

  useEffect(() => {
    if (mode === "pickup" && newPickupPoint && isActive)
      return timeoutUntilSuccess(async () => {
        let placemark = placemarksRefs[newPickupPoint?.id]?.current;
        if (placemark && mapRef.current) {
          mapRef.current?.setCenter([newPickupPoint.lat, newPickupPoint.lon]);
          mapRef.current?.container.fitToViewport();
          // placemark.balloon.open();
          return true;
        }
        return false;
      });
  }, [mode, newPickupPoint, isActive]);

  function checkMapCenterChanged(): () => boolean {
    const center = mapRef.current?.getCenter();
    if (!center) return () => !!mapRef.current;
    return () => {
      if (!mapRef.current) return false;
      const newCenter = mapRef.current.getCenter();
      return !(center[0] === newCenter[0] && center[1] === newCenter[1]);
    };
  }

  const updateDeliveryAddressByCoords = async (
    coords: [number, number],
    wasLoading: boolean
  ): Promise<boolean> => {
    if (!coords) {
      if (wasLoading) setLoading(false);
      return false;
    }
    const checkIsChanged = checkMapCenterChanged();
    return apiClient.suggestions
      .getAddressByCoords(coords[0], coords[1], 200)
      .then(({ data }) => {
        if (checkIsChanged()) return false;
        if (wasLoading) setLoading(false);
        if (!data || data.length === 0) {
          setAddressError({
            type: "message",
            message: "Не удалось найти адрес",
          });
          setDeliveryPoint(null);
          setDeliveryAddress({ name: "" });
          return false;
        }
        if (data && data[0]) {
          setAddressError(null);
          setDeliveryPoint({ ...data[0], lat: coords[0], lon: coords[1] });
          setDeliveryAddress({
            name: formatAddress(data[0], ["city"]),
            street: data[0].streetWithType,
            house: data[0].house,
            block: data[0].block,
          });
          if (data[0].cityFiasId !== newCity.guid) {
            const newCity = Object.values(cities).find((c: any) => c.guid === data[0].cityFiasId)
            if (newCity) {
              setNewCity(newCity)
            }
          }
          return true;
        } else {
          //setDeliveryPoint((x: any) => ({...x}))
          return false;
        }
      });
  };

  const updateDeliveryAddressAtMapCenter = async (
    delay: number,
    wasLoading: boolean
  ): Promise<boolean> => {
    const checkIsChanged = checkMapCenterChanged();
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const coords = mapRef.current?.getCenter();
        if (coords && !checkIsChanged())
          resolve(
            updateDeliveryAddressByCoords([coords[0], coords[1]], wasLoading)
          );
        else {
          if (wasLoading) setLoading(false);
          resolve(false);
        }
      }, delay);
    });
  };

  useEffect(() => {
    if (!isActive) return;
    if (addressError?.type === "notZone") return;
    if (mode == "delivery") {
      let isMousePressed = false;

      const handlePressIn = () => {
        //console.log("IN")
        if (!mapRef.current) return;
        setMouseDragging(true);
        isMousePressed = true;
        setAnimPoint(true);
      };

      const handlePressOut = () => {
        if (!isMousePressed) return;
        isMousePressed = false;
        setAnimPoint(false);
        setTimeout(() => {
          if (!isMousePressed) setMouseDragging(false);
        }, 500);
      };

      const handleBoundsChange = (e: any) => {
        if (clickOnAddress.current) {
          clickOnAddress.current = false;
          return;
        }
        //console.log("AI")
        const { oldCenter, newCenter } = e.originalEvent;
        if (oldCenter[0] === newCenter[0] && oldCenter[1] === newCenter[1]) {
          return;
        }
        if (!mapRef.current) {
          return;
        }
        setLoading(true);
        updateDeliveryAddressAtMapCenter(500, true).then();
      };

      const handleDbClick = (event: any) => {
        event.preventDefault();
      };

      let handleWheelPromise = Promise.resolve();
      const handleWheel = (e: any) => {
        e.preventDefault();
        const wheel =
          e._sourceEvent.originalEvent.domEvent.originalEvent.deltaY;
        handleWheelPromise.then(() => {
          if (!mapRef.current) return;
          if (wheel < 0) {
            const zoom = mapRef.current.getZoom();
            handleWheelPromise = mapRef.current?.setZoom(zoom + 1, {
              duration: 200,
            });
          } else {
            const zoom = mapRef.current.getZoom();
            handleWheelPromise = mapRef.current?.setZoom(zoom - 1, {
              duration: 200,
            });
          }
        });
      };

      let stop = timeoutUntilSuccess(() => {
        if (mapRef.current) {
          window.addEventListener("mouseup", handlePressOut);
          mapRef.current.events.add("mousedown", handlePressIn);
          mapRef.current.events.add("mouseleave", handlePressOut);
          mapRef.current.events.add("boundschange", handleBoundsChange);
          mapRef.current.events.add("dblclick", handleDbClick);
          mapRef.current.events.add("wheel", handleWheel);
          return true;
        }
        return false;
      });
      return () => {
        stop();
        window.removeEventListener("mouseup", handlePressOut);
        if (!mapRef.current) return;
        mapRef.current.events.remove("mousedown", handlePressIn);
        mapRef.current.events.remove("mouseleave", handlePressOut);
        mapRef.current.events.remove("boundschange", handleBoundsChange);
        mapRef.current.events.remove("dblclick", handleDbClick);
        mapRef.current.events.remove("wheel", handleWheel);
      };
    }
  }, [mode, newCity.slug, isActive, addressError?.type === "notZone"]);

  function panToGeolocation(event: any) {
    let position = event.get("position");
    mapRef.current?.setCenter(position, 17, { duration: 1000 });
  }

  function panToAddress({
    coords,
    addZoom = false,
  }: {
    coords: [number, number];
    addZoom: boolean;
  }) {
    let curZoom = mapRef.current?.getZoom();
    curZoom = addZoom ? 17 : curZoom;
    //console.log([coords[0], coords[1]])
    mapRef.current?.setCenter([coords[0], coords[1]], curZoom, {
      duration: 1000,
    });
  }

  const isMobileScreen = useWindowResize({ cvt: (w) => w < 768 });

  const hoverOnMobileOrderbtn = isMobileScreen
    ? "active:bg-orderbtn active:text-main"
    : "hover:bg-orderbtn hover:text-main";
  const hoverOnMobileMain = isMobileScreen
    ? "active:bg-main active:text-white"
    : "hover:bg-main hover:text-white";

  //----------------------------------------NotZONE POPUP--------------------------------

  if (addressError?.type === "notZone") {
    return (
      <PopupActionSheet
        isActive={isActive}
        close={() => {
          setActive(false);
          setAddressError(null);
          setMode(newPickupPoint ? "pickup" : "delivery");
        }}
        closeIcon={true}
        width={"470px"}
      >
        <div className={"flex flex-col gap-y-4"}>
          <p className={"text-xl font-medium text-dark dark:text-light"}>Зона доставки не определена</p>
          <div>
            <p className={"text-gray-50 dark:text-gray-20"}>
              Возможность доставки на указанный адрес можно уточнить у
              администратора по номеру телефона:
            </p>
            <p className={"text-main"}>
              {newBranch?.contacts?.items?.length ? newBranch?.contacts?.items[0]?.phone : ''}
            </p>
          </div>
          {pickupPoints.length !== 0 && (
            <>
              <p className={"font-medium text-dark dark:text-light"}>
                Вы можете оформить заказ на самовывоз
              </p>
              <div className={"flex flex-col gap-y-2"}>
                {pickupPoints.map((c: any) => {
                  return (
                    <div
                      key={c.id}
                      onClick={() => {
                        setNewPickupPoint(c);
                      }}
                      className={
                        "w-full flex items-center flex-row gap-x-6 text-gray-50 dark:text-gray-20"
                      }
                    >
                      <CustomRadioButton checked={c.id === newPickupPoint.id} />{" "}
                      <p>{c.title}</p>
                    </div>
                  );
                })}
              </div>
            </>
          )}

          <div className={"flex flex-col gap-y-4 mt-6"}>
            <BaseButton
              onClick={() => {
                setMode("delivery");
                setDeliveryAddress("");
                setDeliveryPoint(null);
                setAddressError(null);
              }}
              className={"bg-orderbtn text-main"}
            >
              Указать другой адрес
            </BaseButton>
            {pickupPoints.length !== 0 && (
              <BaseButton
                disabled={addressPreloader}
                onClick={() => {
                  setAddressPreloader(true);
                  trySavePickupPoint(newCity, newPickupPoint).then((success: any) => {
                    if (success) {
                      setActive(false)
                      setAddressError(null)
                      setMode('pickup')
                      setAddressPreloader(false);
                    }
                  });
                }}
                className={"bg-orderbtn text-main"}
              >
                {addressPreloader ? (
                  <Preloader
                    countOfDot={4}
                    color={"white"}
                    className={"gap-x-2 py-1.5"}
                    size={"6px"}
                  />
                ) : (
                  "Заказать на самовывоз"
                )}
              </BaseButton>
            )}
          </div>
        </div>
        <ChangeBranchPopup/>
      </PopupActionSheet>
    );
  }

  //----------------------------------------PICKUP/DELIVERY POPUP------------------------
  return (
    <PopupActionSheet
      isActive={isActive}
      close={() => setActive(false)}
      closeIcon={true}
      classNamePopup={`xs:!h-[min(550px,80vh)] !h-[min(1000px,91.5vh)] p-0 md:py-0 py-0 md:px-0 px-0`}
      isMap={true}
      movePopup={true}
    >
      <div className={"flex flex-col h-full"}>
        <div className="flex flex-col md:flex-row gap-3 mt-[25px] md:mt-8 px-4 md:px-[30px]">
          <InputSelectorCitySelectSearch
            cities={cities || {}}
            value={newCity.slug}
            onChange={(slug: any) => {
              let nCity = cities[slug]
              if (nCity) {
                setNewCity(nCity)
              }
              setNewBranch(null);
              setAddressError(null);
              setDeliveryAddress({ name: "" });
              setDeliveryPoint(null);
            }}
            showArrowIcon={Object.values(cities).length > 1}
            placeholderInput={"Введите город..."}
            classNameInactive={`${
              isLoading || isMouseDragging
                ? "opacity-50 pointer-events-none"
                : ""
            } bg-transparent `}
            className={"w-full md:w-[400px]"}
            activeIcon={"iconSearch"}
          />

          {
            // Если у всех филиалов в этом городе нет зон доставки или включена настройка "Только самовывоз", то даже не показываем этот блок
            city.branches.reduce((accumulator: boolean, currentValue: any) => accumulator || (currentValue.hasDeliveryZones && !currentValue.pickupOnly), false) &&
            <div className="flex gap-3 w-full">
              <BaseButton
                onClick={() =>  setMode('delivery')}
                className={`w-1/2 ${mode === 'delivery' ? 'text-white bg-main' : 'text-main bg-orderbtn'}  ${hoverOnMobileMain} font-medium`}
                disabled={isLoading || isMouseDragging }
              >
                Доставка</BaseButton>

              <BaseButton
                onClick={() => {
                  setAddressError(null)
                  setMode('pickup')
                }}
                className={`w-1/2 ${mode === 'pickup' ? 'text-white bg-main' : 'text-main bg-orderbtn'} ${hoverOnMobileMain} font-medium disabled:opacity-50`}
                disabled={pickupPoints?.length === 0 || isLoading || isMouseDragging }
              >
                Самовывоз</BaseButton>
            </div>
          }
        </div>

        <div
          className="flex flex-col md:flex-row mt-[15px] gap-3 px-4 md:px-[30px]"
          onClick={() => setAddressError(null)}
        >
          <div
            className={`relative w-full ${
              addressError ? "border-yellow" : "border-transparent"
            } border-[1.5px] rounded-[11px]`}
          >
            {mode === "pickup" && (
              <InputSelectorPointSearch
                variants={pickupPoints.map(
                  ({ id, lat, lon, ...point }: any) => ({
                    name: formatAddress(point, ["city"]),
                    id,
                    lat,
                    lon,
                  })
                )}
                value={newPickupPoint?.id}
                onChange={(id: any) => {
                  selectPickupPoint(pickupPoints.find((p: any) => p.id === id));
                }}
                guids={newCity ? [newCity.guid] : []}
                lat={newCity ? newCity.lat : null}
                lon={newCity ? newCity.lon : null}
                classNameInactive={`${
                  isLoading || isMouseDragging
                    ? "opacity-50 pointer-events-none"
                    : ""
                } bg-gray-20 dark:bg-gray-40`}
                icon={"geoMark"}
                activeIcon={"iconSearch"}
              />
            )}

            {mode === "delivery" && (
              <InputSelectorAddressSearchWithSaved
                value={deliveryAddress}
                placeholder={"Добавить новый адрес"}
                onChange={selectDeliveryAddress}
                guids={newCity ? [newCity.guid] : []}
                lat={newCity ? newCity.lat : null}
                lon={newCity ? newCity.lon : null}
                allUserAddresses={allUserAddresses}
                classNameInactive={`${
                  isLoading || isMouseDragging
                    ? "opacity-50 pointer-events-none"
                    : ""
                } bg-gray-20 dark:bg-gray-40`}
                icon={"geoMark"}
                activeIcon={"iconSearch"}
              />
            )}
            <div className="absolute -bottom-7 left-0 flex flex-col md:flex-row mt-[5px] h-[20px] gap-3">
              <span className={"text-yellow text-xs font-medium"}>
                {addressError?.message}
              </span>
            </div>
          </div>
          <div
            className={`w-full md:w-1/3 border-y-[1.5px] border-transparent ${
              isMobileScreen
                ? addressError?.message
                  ? addressError.message.length > 50
                    ? "mt-10"
                    : "mt-6"
                  : "mt-1"
                : ""
            }`}
          >
            {mode === "delivery" && (
              <BaseButton
                disabled={
                  isLoading ||
                  isMouseDragging ||
                  !!addressError ||
                  !deliveryPoint ||
                  addressPreloader
                }
                className={`w-full bg-orderbtn whitespace-nowrap text-main ${hoverOnMobileMain}`}
                onClick={() => {
                  setAddressPreloader(true);
                  setLoading(true);
                  trySaveDeliveryPoint(deliveryPoint).then((result: any) => {
                    if (result === 'notHouse') {
                      setAddressError({type: 'message', message: 'Пожалуйста, укажите номер дома'})
                    } else if (result === true) {
                      setActive(false)
                      successSelectCallback()
                    }
                    
                    setLoading(false);
                    setAddressPreloader(false);
                  });
                }}
              >
                {addressPreloader ? (
                  <Preloader
                    countOfDot={4}
                    color={"white"}
                    className={"gap-x-2 py-1.5"}
                    size={"6px"}
                  />
                ) : (
                  "Привезти сюда"
                )}
              </BaseButton>
            )}
            {mode === "pickup" && (
              <BaseButton
                disabled={isLoading || isMouseDragging || addressPreloader}
                className={`w-full bg-orderbtn  whitespace-nowrap text-main ${hoverOnMobileMain}`}
                onClick={() => {
                  setAddressPreloader(true);
                  setLoading(true);
                  trySavePickupPoint(newCity, newPickupPoint).then(() => {
                    successSelectCallback()
                    setLoading(false);
                    setActive(false)
                    setAddressPreloader(false);
                  });
                }}
              >
                {addressPreloader ? (
                  <Preloader
                    countOfDot={4}
                    color={"white"}
                    className={"gap-x-2 py-1.5"}
                    size={"6px"}
                  />
                ) : (
                  "Заберу отсюда"
                )}
              </BaseButton>
            )}
          </div>
        </div>

        <div
          className={`relative ${
            isMobileScreen
              ? `mt-4`
              : `${
                  addressError?.message && addressError.message.length > 50
                    ? "mt-14"
                    : "mt-9"
                }`
          } m-0 w-full overflow-hidden h-full`}
          ref={mapContainerRef}
        >
          <div
            className={`z-1 absolute ${
              isMobileScreen ? "top-[50%] left-[42%]" : "top-[40%] left-[44%] "
            }`}
          >
            <Preloader countOfDot={4} size={"10px"} />
          </div>

          {mode === "delivery" && (
            <div
              ref={deliveryPointRef as any}
              style={{
                position: "absolute",
                bottom: mapHeight / 2,
                left: "50%",
              }}
              className={"z-[20] "}
            >
              <div
                className={`relative h-[20px] w-[4px] bg-black rounded-full`}
              >
                <div
                  className={`absolute  -left-[10.5px] ${
                    animPoint ? "h-[24px] bottom-4" : "h-[25px] bottom-3"
                  } duration-200 w-[25px] bg-main rounded-full`}
                ></div>
              </div>
            </div>
          )}

          <Map
            instanceRef={mapRef}
            modules={["geolocation", "geocode"]}
            onLoad={(ymapsApi: any) => {
              initRoundButtonsLayoutModule(ymapsApi);
            }}
            width={"100%"}
            height={`${mapHeight}px`}
            defaultState={{
              zoom: 15,
              center: city ? [city.lat, city.lon] : [55.7522, 37.6156],
              controls: [],
            }}
            defaultOptions={{
              yandexMapDisablePoiInteractivity: true,
              suppressMapOpenBlock: true,
            }}
          >
            {mode === "delivery" && (
              <GeolocationControl
                data={{
                  image: `${geolocationIcon}`,
                }}
                instanceRef={geolocationRef}
                options={{
                  float: "right",
                  layout: "round#buttonLayout",
                  // @ts-ignore
                  noPlacemark: true,
                }}
                onLocationChange={panToGeolocation}
              />
            )}

            <ZoomControl
              options={{
                position: {
                  top: "55px",
                  right: "10px",
                },
                //@ts-ignore
                layout: "round#zoomLayout",
                size: "small",
              }}
            />
            {mode === "pickup" &&
              pickupPoints.map((c: any) => {
                if (!placemarksRefs[c.id]) {
                  placemarksRefs[c.id] = { current: null };
                }
                return (
                  <Placemark
                    instanceRef={placemarksRefs[c.id] as any}
                    key={c.id}
                    geometry={[c.lat, c.lon]}
                    onLoad={(api: any) => {
                      const MyIconContentLayout =
                        api.templateLayoutFactory.createClass(
                          `<div class="text-white ml-[-4px] mt-[-4px] w-[27px] h-[27px] bg-white rounded-full overflow-hidden border-[3px] p-0.5 border-main">
                      <img class="rounded-full" src="${company.template.logoThumbnail}"/>
                    </div>`
                        );

                      api.ready(() => {
                        placemarksRefs[c.id].current?.options.set({
                          iconContentLayout: MyIconContentLayout,
                        });
                      });
                    }}
                    options={{
                      hideIconOnBalloonOpen: false,
                      iconLayout: "default#imageWithContent",
                      iconImageHref: company.template.logoThumbnail,
                      iconImageSize: [20, 18],
                      iconImageOffset: [-10, -9],
                    }}
                    properties={
                      {
                        // iconContent: `<img class="rounded-full border-main" src="${company.template.logoThumbnail}"/>`,
                        // balloonContent : `<div class="border-[1px] border-gray-20 w-full py-1.5 px-3 rounded-full">
                        //                   <p class="font-bold text-gray-50">${formatAddress(c, ['city'])}</p>
                        //                </div>`,
                      }
                    }
                    onClick={() => {
                      setNewPickupPoint(c);
                    }}
                  />
                );
              })}
          </Map>
        </div>
      </div>

      <ChangeBranchPopup />
    </PopupActionSheet>
  );
}
