import { DaysOfWeekDictionary } from "../../constants/daysOfWeek";

export const formatOpenHours = (branch: any) => {
  const openHours: any = [];

  Object.keys(branch.openHours).map((key) => {
    if(branch.openHours[key].active){
      openHours.push({
        [DaysOfWeekDictionary[key as keyof typeof DaysOfWeekDictionary]]:
          branch.openHours[key].hours.toString(),
      });
    } else {
      openHours.push({
        [DaysOfWeekDictionary[key as keyof typeof DaysOfWeekDictionary]]:
          'Выходной',
      });
    }
    
  });

  const objOfOpenHours: any = {};

  openHours.forEach((item: any, index: number) => {
    Object.assign(objOfOpenHours, openHours[index]);
  });


  const days: any = {};

  Object.keys(objOfOpenHours).reduce((prev, cur) => {
    if (objOfOpenHours[prev] === objOfOpenHours[cur]) {
      days[prev].push(cur);
      return prev;
    } else {
      days[cur] = [cur];
      return cur;
    }
  }, "")

  const resultOpenHours: any = {};

  Object.keys(days).forEach((k) => {
    resultOpenHours[
      days[k].length > 1
        ? [days[k][0], days[k][days[k].length - 1]].join("-")
        : days[k][0]
    ] = objOfOpenHours[k];
  })

  return resultOpenHours;
};
