import { SVGWrap } from "../../../../common/util/SVGWrap"

export type Tag = {
  id: number;
  code: string;
  color: string;
  background: string;
  icon: string;
  text: string;
}

export default function Tags({ product }: any) {
  if (! product?.tags?.length) {
      return <></>
  }

  const tags = product.tags.map((tag: Tag) => (
    <div key={ tag.id } className='flex w-max gap-3 items-center cursor-pointer '>
      <SVGWrap width={ "13px" } height={ "13px" } src={ tag.icon } color={ tag.color }/>
      <div className='font-semibold' style={ { color: tag.color } }>{ tag.text }</div>
    </div>
  ));

  return (
    <div className={ 'flex items-center gap-x-4 gap-y-1 md:mt-[5px] flex-wrap' }>{tags}</div>
  )
}
