import React from 'react'
import Slider from 'react-slick';
import { resizeImage } from '../../../libs/helpers/resizeImage';
import { useAppContext } from '../../../contexts/App';

export default function MainPageSlider() {
  const { branch } = useAppContext()

  const settingsSlider = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7500,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 520,
        settings: {
          dots: false
        }
      }
    ]
  };

  const desktopSlides = branch.desktopSlides.map((url: any, index: any) => (
    <div className='w-full outline-none rounded-[29px] overflow-hidden' key={`desktop-slide-${index}`}>
      {/* <img className='w-full rounded-[29px]' src={slide.link} alt="" /> */}
      <picture>
        {/* // load webp in different sizes if browser supports it */}
        <source media="(min-width: 769px)"
                srcSet={resizeImage(url, 0, 'webp')}
                type="image/webp"/>
        <source media="(max-width: 768px)"
                srcSet={resizeImage(url, 768, 'webp')}
                type="image/webp"/>

        {/* // load jpg in different sizes if browser doesn't support webp */}
        <source media="(min-width: 766px)"
                srcSet={resizeImage(url, 0, 'jpg')}
                type="image/jpeg"/>
        <source media="(max-width: 768px)"
                srcSet={resizeImage(url, 768, 'jpg')}
                type="image/jpeg"/>

        {/* // fallback in different sizes, as well as regular src. */}
        <img
          srcSet={`${resizeImage(url, 768, 'jpg')} 768w`}
          sizes="(max-width: 768px) 768px"
          src={resizeImage(url, 0, 'jpg')}
          alt="image description"
          className=""
        />
      </picture>

    </div>
  ));

  const mobileSlides = branch.mobileSlides.map((url: string, index: any) => (
    <div className='w-full outline-none' key={`mobile-slide-${index}`}>
      <img className='w-full rounded-[29px]' src={url} alt="" />
    </div>
  ));

  return (
    <>
      <div className='main-page-slider hidden xs:block'>
        <Slider {...settingsSlider} >
          { desktopSlides }
        </Slider>
      </div>

      <div className={`main-page-slider xs:hidden block ${branch.mobileSlides.length > 0 ? 'mt-5' : ''}`}>
        <Slider {...settingsSlider} >
          { mobileSlides }
        </Slider>
      </div>
    </>
  )
}
