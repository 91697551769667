import React from "react";
import logo from '../../images/logoFooter.svg'
import { BaseButton } from "./button/BaseButton";
import GearIcon from "../icons/gearIcon";


export default function NotLinkedToCrm({vkLaunchParams}: any){
  return <>
    <div style={{background: 'white'}}
         className={"flex flex-col items-center sm:items-start justify-center h-screen w-full px-5 sm:px-10"}>
      <div className={"flex flex-col-reverse sm:flex-row justify-center w-full items-center"}>
        <div className={"flex flex-col gap-y-3 text-dark sm:pr-5"}>
          <p className={"text-2xl font-medium"}>
            Приложение для заказов ВКонтакте
          </p>
          <p className={"text-xl font-medium"}>
            <span className={'text-[#55B27A]'}>Удобное</span> оформление заказа в <span className={'text-[#55B27A]'}>вашем сообществе</span> ВКонтакте
          </p>
        </div>
        <img src={logo} className={"w-[300px] h-[100px]"} alt={""}/>
      </div>
      <div className={"w-full text-dark text-sm sm:text-[15px] mt-6"}>
        <div className={"w-full text-dark text-sm sm:text-[15px]"}>
          <p>Отлично! <br/>
            Для работы приложения нужно привязать его к филиалу вашей компании.</p>
          <br/>
          <div className={"flex flex-col gap-y-2"}>
            {process.env.REACT_APP_CRM_URL
              ? <span>1. Авторизуйтесь в панели управления: <a target={"_blank"} href={process.env.REACT_APP_CRM_URL}> <span className={'font-bold'}>Result.Rest</span></a></span>
              : <p>1. Авторизуйтесь в панели управления.</p>
            }
            <p>2. Добавьте вашу компанию.</p>
            <p>3. Добавьте филиал компании.</p>
            <p>4. Добавьте разделы каталога и товары.</p>
          </div>

        </div>

      </div>
      <div className={"mt-7"} style={{width: '250px'}}>
        {process.env.REACT_APP_CRM_URL &&
          <a href={process.env.REACT_APP_CRM_URL} target={"_blank"} className={""} style={{width: '250px'}}>
            <BaseButton
              className={"w-full bg-[#55B27A] hover:bg-[#D6EDDF] hover:text-dark stroke-white hover:stroke-dark text-white "}>
              <GearIcon className={'mr-2 stroke-inherit'}/>
              Панель управления
            </BaseButton>
          </a>
        }
      </div>

    </div>
    </>
}
