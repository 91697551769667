import React, { useRef, useState, useEffect, useMemo } from 'react'
import Slider from "react-slick";
import productImgStub from "../../../../images/productImgStub.jpg";
import { useAppContext } from '../../../../contexts/App';
import { useProductDetailCardContext } from '../../../../contexts/ProductDetailCardContext';
import ArrowIcon from '../../../icons/ArrowIcon';
import { resizeImages } from '../../../../libs/helpers/resizeImage';
import { formatCurrency } from '../../../../libs/helpers/formatCurrency';
import CrossedOldPriceIcon from '../../../icons/CrossedOldPriceIcon';
import { apiClient } from '../../../../libs/api/apiClient';

export default function Popular() {
  const { branch } = useAppContext()
  const { openDetail } = useProductDetailCardContext()
  const [popularProducts, setPopularProducts] = useState([])
  const [isShowLeftArrow, setIsShowLeftArrow] = useState(false)
  const [isShowRightArrow, setIsShowRightArrow] = useState(false)

  function getPopularProducts() {
    apiClient.catalog.getPopular(branch?.id).then(({data}) => {
      setPopularProducts(data)
      return data
    })
  }

  useEffect(() => {
    if (branch?.id) {
      getPopularProducts()
    }
  }, [branch?.id])

  const settings = {
		className: "slider variable-width",
		slidesToShow: 1,
    slidesToScroll: 1,
		variableWidth: true,
		infinite: false,
		arrows: false,
	};

  const products = useMemo(
    () => {
      if (popularProducts) {
        return popularProducts.map((p: any) => {
          p.priceString = formatCurrency(branch.extended.currency.code, p.price)
          p.oldPriceString = formatCurrency(branch.extended.currency.code, p.oldPrice)
          p.imageResize = resizeImages(p.image, [125, 300, 400], ['webp', 'jpg'])
          return p  
        })
      }
      return []
    }, [popularProducts]
  );


  const slider = useRef<any>(null)

  return (
    <div className={!!products?.length ? 'mt-[30px]' : ''}>
      {!!products?.length && <div className={`text-dark dark:text-light text-4xl font-bold text-[32px]`}>Популярное</div>}
      
      <div className={`relative mt-1`}>

        <Slider {...settings} ref={slider} afterChange={(currentSlide) => {
            if(currentSlide === 0){
              setIsShowLeftArrow(false)
            } else{
              setIsShowLeftArrow(true)
            }
            if (currentSlide === Math.floor(products?.length / 2 + 1)){
              setIsShowRightArrow(false)
            } else if(products?.length <= 3 ){
              setIsShowRightArrow(false)
            } else {
              setIsShowRightArrow(true)
            }
          }}
          >
          {
            products?.map((product: any) => (
              <div className='xs:mr-[30px] mr-[10px]' key={product?.id} onClick={() => openDetail(product?.id)}>
                <div className='w-[280px] xs:w-[331px] mt-5 xs:mt-7 mb-5 xs:mb-[30px] md:mb-[40px] flex gap-4 cursor-pointer bg-white dark:bg-gray-50 rounded-[29px] shadow-lg'>
                  {
                    product.image ?
                    // <img src={product.image} className='h-[106px] lg:h-[125px] rounded-[17px]' alt="" /> 
                    <div className='rounded-[17px] overflow-hidden'>
                      <picture className={'xs:h-[125px] h-[106px]'}>
                      <source className={'xs:h-[125px] h-[106px]'} type="image/webp" srcSet={`${product.imageResize.webp[125]} 1x, ${product.imageResize.webp[300]} 2x, ${product.imageResize.webp[400]}3x`}/>
                        <source className={'xs:h-[125px] h-[106px]'} type="image/jpeg" srcSet={`${product.imageResize.jpg[125]} 1x, ${product.imageResize.jpg[300]} 2x, ${product.imageResize.jpg[400]} 3x`}/>

                        <img
                          className={'h-[106px] w-[106px] xs:h-[125px] xs:w-[125px]'}
                          src={product.imageResize.jpg[125]}
                          srcSet={`${product.imageResize.jpg[125]} 1x, ${product.imageResize.jpg[300]} 2x, ${product.imageResize.jpg[400]} 3x`}
                          alt=""
                        />
                      </picture>
                    </div>:
                    <img
                      className='w-[106px] h-[106px] xs:w-[125px] xs:h-[125px] rounded-[17px]'
                      src={productImgStub} 
                      alt=""
                    />
                  }

                  <div className='w-[calc(280px-106px-30px)] xs:w-[calc(331px-125px-25px)] py-2 flex flex-col py-3 pr-3 lg:py-[15px] lg:pr-[15px] justify-between'>
                    <div className={'text-[15px] sm:text-lg leading-[127.02%] -tracking-[.01em] font-semibold text-dark dark:text-light lines-ellipsis lines-ellipsis--2'}>
                      {product.title}
                    </div>

                    <div className='flex flex-row items-center'>
                      <div className='text-main font-bold text-[16px] xs:text-lg flex items-center gap-1 mr-2'>{product?.optionsExists && 'От'} {product.priceString}</div>
                      {!!product.oldPrice &&
                        <div className={`relative ${String(product.oldPrice).length >= 4? 'xs:w-[31%]' : ' xs:max-w-[21%]'} w-[30%] ml-[2px] text-sm leading-[17px] text-gray-50 dark:text-gray-30`}>
                        <div className='flex items-center opacity-60'>{product.oldPriceString}</div>
                        <div className='absolute w-full h-full left-0 top-0'>
                          <CrossedOldPriceIcon width={'100%'} height={'auto'}/>
                        </div>
                      </div>
                      }
                    </div>

                  </div>
                </div>
              </div>
            ))
          }
        </Slider>

        <button className={`${isShowLeftArrow? 'md:block' : 'md:hidden'} hidden absolute h-full top-0 left-2`} onClick={() => slider.current?.slickPrev()}>
          <div className='rotate-180'>
            <ArrowIcon className='w-[13px] h-[21px]'/>
          </div>
        </button>

        <button className={`${isShowRightArrow ? 'md:block' : 'md:hidden'} hidden absolute h-full top-0 right-2`} onClick={() => slider.current?.slickNext()}>
          <ArrowIcon className='w-[13px] h-[21px]'/>
        </button>
      </div>
    </div>
  )
}
