import React, { useEffect } from 'react';
import './styles/globals.sass'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AppLayout from './AppLayout';
import {
  createBrowserRouter,
  RouterProvider, 
  Outlet,
  createHashRouter,
} from "react-router-dom";
import Home from './pages'
import PrimaryLayout from './components/layouts/primary';
import Main from './pages/[city]';
import Cart from './pages/[city]/cart';
import Order from './pages/[city]/order/[hash]';
import Profile from './pages/[city]/account/profile';
import MyAddress from './pages/[city]/account/address';
import Notifications from './pages/[city]/account/notifications';
import MyHistory from './pages/[city]/account/history';
import OrderDetails from './pages/[city]/account/history/[orderId]';
import Feedback from './pages/[city]/feedback/[id]';
import About from './pages/[city]/about';
import Payment from './pages/[city]/payment';
import Reviews from './pages/[city]/reviews';
import Stock from './pages/[city]/stock';
import Offer from './pages/[city]/offer';
import PersonalDataPolicy from './pages/[city]/personalDataPolicy';
import TermsOfUse from './pages/[city]/termsOfUse';
import ServerError from './pages/500';
import MainLayout from './components/layouts/main';

const router = createHashRouter([
  {
    path: "/",
    element: <AppLayout/>,
    children: [
      {
        path: "/",
        element: <Home/>,
      },
      {
        path: "/500",
        element: <ServerError/>,
      },
      {
        path: "/:city",
        element: <MainLayout><Outlet/></MainLayout>,
        children: [
          {
            path: "/:city/",
            element: <Main/>,
          },
          {
            path: "/:city/cart",
            element: <PrimaryLayout><Cart/></PrimaryLayout>,
          },
          {
            path: "/:city/order/:hash",
            element: <PrimaryLayout><Order/></PrimaryLayout>,
          },
          {
            path: "/:city/account/",
            element: <PrimaryLayout><Outlet/></PrimaryLayout>,
            children: [
              {
                path: "/:city/account/profile",
                element: <Profile/>
              },
              {
                path: "/:city/account/address",
                element: <MyAddress/>
              },
              {
                path: "/:city/account/notifications",
                element: <Notifications/>
              },
              {
                path: "/:city/account/history",
                element: <MyHistory/>
              },
              {
                path: "/:city/account/history/:orderId",
                element: <OrderDetails/>
              },
            ]
          },
          {
            path: "/:city/feedback/:id",
            element: <PrimaryLayout><Feedback/></PrimaryLayout>,
          },
          {
            path: "/:city/about",
            element: <PrimaryLayout><About/></PrimaryLayout>,
          },
          {
            path: "/:city/payment",
            element: <PrimaryLayout><Payment/></PrimaryLayout>,
          },
          {
            path: "/:city/reviews",
            element: <PrimaryLayout><Reviews/></PrimaryLayout>,
          },
          {
            path: "/:city/stock",
            element: <PrimaryLayout><Stock/></PrimaryLayout>,
          },
          {
            path: "/:city/offer",
            element: <PrimaryLayout><Offer/></PrimaryLayout>,
          },
          {
            path: "/:city/personalDataPolicy",
            element: <PrimaryLayout><PersonalDataPolicy/></PrimaryLayout>,
          },
          {
            path: "/:city/termsOfUse",
            element: <PrimaryLayout><TermsOfUse/></PrimaryLayout>,
          },
        ]
      },
    ]
  },
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router}/>
    </div>
  );
}

export default App;
