import StarIcon from "../../icons/StarIcon";
import { useEffect, useState } from "react";

interface StarRatingProps {
  fillRate?: boolean,
  rate?: number,
  fieldName?: any,
  updateField?: any,
  errors?: any,
  errorLabel?: boolean
}

export default function StarRating({fillRate = false, rate, fieldName, updateField, errors = {}, errorLabel = true} : StarRatingProps) {
  const [rating, setRating] = useState<any>(null)
  const [hover, setHover] = useState<any>(null)

  useEffect(() => {
    if(fieldName){
      updateField(fieldName, 0)
    }
  }, [])

  const error = errors[fieldName]; 

  useEffect(() => {
    delete errors[fieldName];
  }, [rating]);

  return (
    <>
    {fillRate?
      <div className="flex gap-x-[10px]">
        {[...Array(5)].map((star, index) => {
          const ratingValue = index + 1;
          return (
            <label key={index} >
              <StarIcon className="w-[18px] h-[18px]" colorClassName={`${rate && ratingValue <= rate ? "fill-main" : "fill-gray-30"}`}/>
            </label>
          );
      })}
      </div>
      :
      <div className="flex gap-x-[20px] h-[50px]">
      {[...Array(5)].map((star, index) => {
        const ratingValue = index + 1;
        return (
          <label key={index} >
            <input 
              className="hidden" 
              type="radio" 
              name="rating" 
              value={ratingValue} 
              onClick={() => {
                setRating(ratingValue)
                updateField(fieldName, ratingValue)
              }} 
            />
            <StarIcon 
              onMouseEnter={() => setHover(ratingValue)}
              onMouseLeave={() => setHover(null)} 
              className="cursor-pointer" 
              colorClassName={`${ratingValue <= (hover || rating) ? "fill-main" : "fill-gray-30"}`}/>
          </label>
        );
      })}
    </div>
    }
    {error && errorLabel && (<span className={'text-yellow text-xs font-medium'}>{error}</span>)}
    </>
  );
}