import { useCartContext } from "../../../../contexts/CartContext";
import { BaseButton } from "../../button/BaseButton";
import PopupActionSheet from "../PopupActionSheet";

interface IDirtyCart {
  repeatOrderId: number,
  close: () => void,
  setIsOpenRedirectModal: (value: boolean) => void
}
export default function DirtyCart({repeatOrderId, close, setIsOpenRedirectModal}: IDirtyCart) {
  const {repeatOrder} = useCartContext()


  return (
    <>
      <PopupActionSheet
        isActive={repeatOrderId !== -1}
        close={close}
        closeIcon={true}
      >
        <div className={"flex flex-col gap-y-6"}>
          <p className={"text-2xl font-medium text-dark dark:text-light"}>Предупреждение</p>
          <p className={'text-dark dark:text-light'}>На данный момент в корзине находятся товары, хотите ли вы очистить корзину, чтобы повторить заказ?</p>
          <div className={"flex flex-col xs:flex-row justify-between items-center gap-2"}>
            <BaseButton onClick={close} className={"w-full xs:w-1/3 bg-orderbtn text-main hover:bg-main hover:text-white"}>Отменить</BaseButton>
            <BaseButton onClick={()=> {
              repeatOrder(repeatOrderId).then((success: any) => {
                close()
                if (success) {
                  setIsOpenRedirectModal(true)
                }
              })
            }} className={"w-full xs:w-1/2 bg-main text-white hover:opacity-80"}>Очистить корзину</BaseButton>
          </div>
        </div>

      </PopupActionSheet>
    </>
  )
}
