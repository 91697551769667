import React, { useEffect } from 'react'
import { useAppContext } from '../../../contexts/App';


export default function PhonesPopup({ className='', show, setShow, wrapper }: any) {
  const { branch } = useAppContext()

  useEffect(() => {
    function handleClick(event: any) {
      if (wrapper.current && !wrapper.current.contains(event.target)) {
        setShow(false);
      }
    }

    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }

  }, [show, setShow, wrapper]);

  return (
    <div className={`flex flex-col gap-4 py-4 px-6 rounded-[15px] bg-gray-10 dark:bg-gray-50 shadow-[4px_4px_30px_rgba(0,0,0,0.2)] ${ !show && 'hidden' } ${className}`}>
      {
        branch.contacts.map((item: {phone: string; description: string;}) => (
          <a key={item.phone} className='flex flex-col gap-2 w-max'>
            <div className='dark:text-gray-10 lg:text-lg leading-[18px] font-bold'>{item.phone}</div>
            <div className='text-xs opacity-25 dark:text-gray-10 dark:opacity-100'>{item.description}</div>
          </a>
        ))
      }
    </div>
  )
}
