interface IPreloaderProps {
  countOfDot?: number;
  size?: string;
  className?: string;
  color?: string;
}

export const Preloader = ({ countOfDot = 3, size = "15px", className='gap-2', color='main' }: IPreloaderProps) => {
  let dots = Array(countOfDot).fill(1);
  return (
    <>
      <div className={`flex justify-center ${className}`}>
        {dots.map((dot: number, index: number) => (
          <div
            key={index}
            style={{width: size, height: size, animationDelay: `${1000 / countOfDot * index}ms`}}
            className={`bg-${color} animate-[pulse_1s_linear_forwards_infinite] rounded-full`}
          ></div>
        ))}
      </div>
    </>
  );
};
