import bridge from "@vkontakte/vk-bridge";
import {apiClient} from "../api/apiClient";
import { useAppContext } from "../../contexts/App";

export default function useAllowMessageVk () {
  const {vkLaunchParams, vkCommunityMessagesAllowed, setVkCommunityMessagesAllowed, branch, company} = useAppContext()

  return async function allowMessageVk() {
    if (process.env.REACT_APP_VK_MINI_APP) {
      if (vkLaunchParams && vkLaunchParams.vk_group_id) {
        try {
          let res = await bridge.send('VKWebAppAllowMessagesFromGroup', {group_id: vkLaunchParams.vk_group_id})
          setVkCommunityMessagesAllowed(res.result)
          return res.result
        } catch (e) {}
        return false
      }
      return false
    }else {
      let vkGroupId = branch.extended.vkCommunities?.length ? branch.extended.vkCommunities[0]?.groupId : '';
      let vkAppId = `${process.env.REACT_APP_VK_APP_ID}`;
      let a = document.createElement('a')
      a.setAttribute('target', '_blank')
      a.href = `https://vk.com/widget_allow_messages_from_community.php?act=allow_box&group_id=${vkGroupId}&app_id=${vkAppId}&method_access=${'_' + Math.random().toString(16).substring(2)}`
      a.click()
      try {
        await apiClient.profileInfo.allowMessagesFromGroup({group_id: parseInt(vkGroupId)})
        return true
      } catch(e) {}
      return false
    }
  }

}
