import React from 'react'

export default function Offer() {
  return (
    <div className='w-full min-h-screen py-8'>
      <div className='container'>
        <div className='my-4 text-2xl'>Публичная оферта о продаже товаров дистанционным способом</div>
        <div>Документ готовится к публикации...</div>
      </div>
    </div>
  )
}
