export function timeoutUntilSuccess(func: () => boolean | Promise<boolean>, {
  limit = 5000,
  interval = 100,
} : {limit?: number, interval?: number} = {}) {
  let stop = false
  let exec = () => {
    if (!stop) {
      Promise.resolve(func()).then(success => {
        limit -= 100;
        if (!success && limit > 0) setTimeout(exec, interval)
      })
    }
  }
  exec();
  return () => { stop = true; }
}
