import React from 'react'

export default function TrashCanIcon({className='w-[14px] h-[20px]', colorClassName='fill-main'}: {
  className?: string;
  colorClassName?: string;
}) {
  return (
    <svg className={`${ className } ${ colorClassName }`} viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.50252 7.96875H4.60152V14.2109H3.50252V7.96875Z"/>
      <path d="M7.349 7.96875H6.25V14.2109H7.349V7.96875Z"/>
      <path d="M8.99747 7.96875H10.0965V14.2109H8.99747V7.96875Z"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M13.6 2.78906V5.30624C13.6 5.59969 13.3537 5.83749 13.0502 5.83749H12.8931V14.9631C12.8931 16.0862 11.9476 17 10.7856 17H1.25641C0.953204 17 0.707228 16.7619 0.707228 16.4687V5.83719H0.549499C0.245975 5.83719 0 5.59938 0 5.30624V2.78906C0 2.49563 0.245975 2.2575 0.549499 2.2575H4.18973V0.531254C4.18973 0.238132 4.43604 0 4.73956 0H8.86077C9.16396 0 9.40994 0.238132 9.40994 0.531254V2.2575H13.0505C13.3537 2.2575 13.6 2.49563 13.6 2.78906ZM8.31094 1.06251H5.28873V2.2575H8.31094V1.06251ZM10.7859 15.9375C11.3419 15.9375 11.7944 15.5006 11.7944 14.9634V5.83812L1.80654 5.83749V15.9375H10.7859ZM1.09866 4.77501H12.5007L12.501 3.32031H1.09866V4.77501Z" />
    </svg>
  )
}
