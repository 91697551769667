import React from 'react'

export default function ArrowSecondaryIcon({colorClassName='fill-dark dark:fill-gray-20', className=''}: {
    colorClassName?: string;
    className?: string;
  }) {
  return (
    <svg className={`${className} ${colorClassName}`} viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M5.70711 0.292893C6.09763 0.683417 6.09763 1.31658 5.70711 1.70711L2.41421 5L5.70711 8.29289C6.09763 8.68342 6.09763 9.31658 5.70711 9.70711C5.31658 10.0976 4.68342 10.0976 4.29289 9.70711L0.292893 5.70711C-0.0976311 5.31658 -0.0976311 4.68342 0.292893 4.29289L4.29289 0.292893C4.68342 -0.0976311 5.31658 -0.0976311 5.70711 0.292893Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0 5C0 4.44772 0.447715 4 1 4H14C14.5523 4 15 4.44772 15 5C15 5.55228 14.5523 6 14 6H1C0.447715 6 0 5.55228 0 5Z" />
    </svg>
  )
}
