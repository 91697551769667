import {useReducer, useState} from "react";

/**
 * @param {object} initialData
 * @return {{data: object, updateData: function, errors: object, submit: function}}
 */
export default function useForm(initialData: any) {
  const [data, updateData] = useReducer((state: any, updates: any) => ({...state, ...updates}), initialData);
  const [changedFields, setChangedFields] = useState({})
  const [errors, updateErrors] = useState<any>({})

  function handleChange(value: any) {
    Object.keys(value).map(key => {
      if ('withoutMiddleName' === key) {
        errors.hasOwnProperty('middleName') && delete errors['middleName'];
      }
      errors.hasOwnProperty(key) && delete errors[key];
    });

    updateData({...value});
  }

  function updateField(path: any, value: any) {
    updateChangedFields(path, value)
    path = path.split('.')

    let tempData = data;
    path.forEach((key: any, index: number) => {
      if (index === path.length - 1) {
        if (tempData instanceof Object) {
          tempData[key] = value
        } else {
          tempData = {[key]: value}
        }
      } else {
        if (!(tempData[key] instanceof Object)) {
          tempData[key] = {}
        }
        if (!(key in tempData)) {
          tempData[key] = {}
        }
        tempData = tempData[key]
      }
    })
    updateData(data)

  }

  function updateChangedFields(path: any, value: any) {
    path = path.split('.')

    let tempData: any = changedFields;
    path.forEach((key: any, index: any) => {
      if (index === path.length - 1) {
        if (tempData instanceof Object) {
          tempData[key] = value
        } else {
          tempData = {[key]: value}
        }
      } else {
        if (!(tempData[key] instanceof Object)) {
          tempData[key] = {}
        }
        if (!(key in tempData)) {
          tempData[key] = {}
        }
        tempData = tempData[key]
      }
    })

    setChangedFields({...changedFields})
  }

  function changedFieldsRefresh() {
    setChangedFields({})
  }

  function getFormData(action = 'create', object: any, formData: any, outerKey: string | null = null) {
    Object.keys(object).reduce((formData, key) => {
      if (null === object[key]) {
        //console.log(`key = ${key} value ${object[key]}`)
        if (key.slice(0, 2) !== '__') {
          if (outerKey) {
            formData.append(`${outerKey}[${key}]`, null)
          } else {
            formData.append(`${key}`, '')
          }
        }
        return formData;
      }
      /*проверка на пустой массив. Протестить чтобы нигде не заговнило отправку данных*/
      if ('object' === typeof object[key] && object[key] instanceof Array && !object[key].length && action === 'update') {
        if (key.slice(0, 2) !== '__') {
          if (outerKey) {
            formData.append(`${outerKey}[${key}]`, [])
          } else {
            formData.append(`${key}`, [])
          }
        }
        return formData
      }

      if ('object' === typeof object[key] && !(object[key] instanceof File) && !(object[key] instanceof Date) && key.slice(0, 2) !== '__') {
        if (outerKey) {
          return getFormData(action, object[key], formData, `${outerKey}[${key}]`)
        }

        return getFormData(action, object[key], formData, key)
      }

      let value = object[key];

      /* Чтобы не перезаписывать родительский объект через ассоциацию */
      if ('boolean' === typeof value) {
        value = +value
      }

      if (key.slice(0, 2) !== '__') {
        if (outerKey) {
          formData.append(`${outerKey}[${key}]`, value)
          return formData;
        }

        formData.append(key, value);
        return formData;
      }
      return formData;
    }, formData);

    return formData;
  }

  /**
   * @param {function} callback
   * @param type
   */
  function submit(callback: any, type = 'full') {
    let formData
    if (type === 'changed') {
      formData = getFormData('update', {...changedFields, _method: "patch"}, new FormData());
    } else {
      formData = getFormData('create', data, new FormData());
    }

    formData.append('lang', 'ru')
    return callback(formData);

  }

  function checkDirty(object: any, arr: any, outerKey: any = null) {
    Object.keys(object).reduce((arr, key) => {
      if (null === object[key]) {
        if (key.slice(0, 2) !== '__') {
          arr.push(key)
        }
        return arr;
      }

      if ('object' === typeof object[key] && object[key] instanceof Array && !object[key].length) {
        if (key.slice(0, 2) !== '__') {
          if (outerKey) {
            return checkDirty(object[key], arr, `${outerKey}[${key}]`)
          }
          arr.push(key)
        }
        return arr
      }

      if ('object' === typeof object[key] && !(object[key] instanceof File) && !(object[key] instanceof Date) && key.slice(0, 2) !== '__') {

        if (outerKey) {
          return checkDirty(object[key], arr, `${outerKey}[${key}]`)
        }

        return checkDirty(object[key], arr, key)
      }

      let value = object[key];

      /* Чтобы не перезаписывать родительский объект через ассоциацию */
      if ('boolean' === typeof value) {
        value = +value
      }

      if (key.slice(0, 2) !== '__') {
        if (outerKey) {
          arr.push(`${outerKey}.${key}`)
          return arr;
        }
        arr.push(key);
        return arr;
      }

      return arr;
    }, arr);
    return arr;
  }

  const isDirty = () => {
    return checkDirty(changedFields, [])
  }

  return {
    data,
    updateData: handleChange,
    updateField,
    changedFields,
    changedFieldsRefresh,
    errors,
    updateErrors,
    submit,
    getFormData,
    isDirty
  }
}
