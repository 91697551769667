import { useState } from "react";
import useForm from "../../../hooks/useForm";
import { apiClient } from "../../../libs/api/apiClient";
import StarRating from "../../common/starRating";
import Textarea from "../../common/form/textarea";
import ImageLoad from "../../common/imageLoad";
import { BaseButton } from "../../common/button/BaseButton";
import { Preloader } from "../../common/preloader";

interface firstStepProps {
  maxLengthTextarea: number,
  inPopup?: boolean,
  nextStep: () => void,
  questionnaireId: any
}

export default function FirstStep({maxLengthTextarea, inPopup = false, nextStep, questionnaireId}: firstStepProps) {
  const {data, updateField, errors, updateErrors, submit} = useForm({})
  const [loading, setLoading] = useState(false)
  const [limitError, setLimitError] = useState('')

  const handleSubmit = () => {
    setLoading(true)
    submit((form: any) => {
      apiClient.profileOrders.addOrderReview(questionnaireId, form)
        .then((res) => {
          setLoading(false)
          if(res.status === 422) {
            updateErrors(res.errors)
          } else {
            nextStep()
          }
        }).catch((err) => {
          setLoading(false)
        })
    })
    
  }
  return (
    <div className={`flex ${inPopup? 'xs:gap-y-[25px] gap-y-[30px] relative' : 'xs:gap-y-9 gap-y-8'}  flex-col`}>
      
      <div>
        <h3 className="text-gray-50 text-lg mb-[15px] dark:text-gray-10">Оцените заказ</h3>
        <StarRating fieldName="estimation" updateField={updateField} errors={errors}/> 
      </div>
      
      <div>
        <h3 className="text-gray-50 text-lg mb-[15px] dark:text-gray-10">Ваш отзыв</h3>
        <Textarea
          placeholder="Комментарий"
          textareaClassName="text-gray-50 h-[150px] dark:text-gray-10"
          name="text"
          value={data.text || ''}
          onChange={(value)=> updateField('text', value)}
          maxLength={maxLengthTextarea}
          errors={errors}
        />
      </div>

      <div>
        <div>
          <ImageLoad
            errors={errors}
            inPopup={inPopup}
            onInput={(value: any) => {
            if (typeof value !== 'undefined') {
              if (value !== null) {
                updateField('images', value)
              }
              }
            }}
            onRemove={() => {
              updateField('images', null)
            }}
            name={'images'}
          />
        </div>
        
        <p className={`${inPopup ? 'mt-[10px]' : 'mt-[15px]'} dark:text-white text-gray-40 xs:text-sm text-xs`}>
          Максимум 4 фотографии
        </p>
      </div>
      <div className={`md:mt-[60px] mt-[50px] ${inPopup? 'xs:relative sticky bottom-0 bg-white rounded-[18px]' : ''}`}>
        <BaseButton
          disabled={loading}
          className="bg-orderbtn w-full text-main font-bold"
          onClick={handleSubmit}
        >
          {loading?
            <Preloader countOfDot={4} size={'10px'} className={'h-[20px] gap-2 items-center'}/>
            : 
            'Оставить отзыв'
          }
        </BaseButton>
          
        </div>
    </div>
  );
}
