import bridge from "@vkontakte/vk-bridge";

let inited = false;
let vkStorage: {[key: string]: any} = {}
let vkGroupId = ''


export const localStorageWrap = {
  init: async (groupId: string) => {
    console.log('init localStorageWrap')
    try {
      vkGroupId = groupId

      return await bridge.send('VKWebAppStorageGet', {keys: keys.map((key) => `${groupId}_${key}`)}).then((data) => {
        inited = true;
        vkStorage =  data.keys.reduce((accumulator: any, currentValue: any) => ({...accumulator, [currentValue.key]: currentValue.value}), {})

        // Очистка хранилища. Включить на одно обновление если нужно почистить
        // data.keys.forEach((currentValue) => {
        //   bridge.send('VKWebAppStorageSet', {
        //     key: currentValue.key,
        //     value: ''
        //   })
        // })
        
        console.log("vk storage parsed", vkStorage)
        return vkStorage
      })

    } catch(e) {
      console.log("error", e)
    }
  },

  getItem: (key: string) : any => {
    if (!inited) throw new Error("localStorageWrap.init() wasn't called")
    return vkStorage[`${vkGroupId}_${key}`] || undefined;
  },

  setItem: (key: string, value: any) : void => {
    if (!inited) throw new Error("localStorageWrap.init() wasn't called")

    vkStorage[`${vkGroupId}_${key}`] = value;
    bridge.send('VKWebAppStorageSet', {
      key: `${vkGroupId}_${key}`,
      value: value
    })
  },

  removeItem: (key: string) => {
    if (!inited) throw new Error("localStorageWrap.init() wasn't called")
    delete vkStorage[`${vkGroupId}_${key}`];
    bridge.send('VKWebAppStorageSet', {
      key: `${vkGroupId}_${key}`,
      value: ''
    })
  },
}
const keys = [
  'clientPhone',
  'isSynchronizedCart',
  'tokenVk',
  'stories',
  'vkUserLoaded',
  'user',
  'cartId',
  'localProducts',
  'deviceId',
  'timeDialog',
  'city',
  'branchId',
  'address'
]
