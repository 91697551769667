export async function getFileFromUrl(url: any, name: any, defaultType = 'image/jpeg'){
  const response = await fetch(url);
  const data = await response.blob();
  return new File([data], name, {
    type: data.type || defaultType,
  });
}

export function loadAndProcessImage(file: File, options: any) {
  return new Promise((resolve: any, reject: any) => {
    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>) => {
      
        const dataUrl = event?.target?.result as string;
        if (options.checkImageValid) {
          let img = new Image();
          img.onload = () => {
            const err = options.checkImageValid(img, dataUrl, file);
            if (err) {
              reject(err);
            } else {
              resolve({url: dataUrl, file: file, status: 'success'});
            }
          }
          img.onerror = () => {
            resolve({url: dataUrl, file: file, status: 'fail', message: 'failed to load image'});
          }
          img.src = dataUrl
      }
    }

    reader.readAsDataURL(file)
  })
}
