import CitiesSelector from "../components/common/popup/citites/CitiesSelector";
import { useAppContext } from "../contexts/App";

export default function Home() {
  const { company } = useAppContext()

  return (
    <div className="relative min-h-screen">
      <img className="max-w-[470px] w-full m-auto pt-10" src={company.template.logo} alt="" />

      <div className="absolute w-screen h-screen left-0 top-0 bg-[#bdbdbd9e] flex items-center justify-center">

        <CitiesSelector
          isActive={true}
          setActive={() => {}}
          movePopup={false}
        />
      </div>
    </div>
  )
}

