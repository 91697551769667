import React, { createContext, useContext, useEffect } from 'react'
import { useAppContext } from './App'
import ym, { YMInitializer } from 'react-yandex-metrika';
import TagManager from 'react-gtm-module'
import MetaTags from 'react-meta-tags2';
import ReactPixel from 'react-facebook-pixel';

interface ContextType {
  metricsEvent: (type: 'order' | 'phone' | 'app-icon' | 'add-product' | 'cart-page' | 'order-page') => void,
}

const MetricsContext = createContext<ContextType>({
  metricsEvent: () => {}
})

export default function MetricsContextWrapper({ children }: any) {
  const { branch } = useAppContext()

  useEffect(() => {
    {/* Google tag */}
    if (branch?.integrations?.keys['googleAnalytics']) {
      TagManager.initialize({gtmId: branch?.integrations?.keys['googleAnalytics']})
    }
    {/* /Google tag */}

    {/* Виджет сообщений ВК */}
    if (branch?.integrations?.keys['vkWidget']) {
      setTimeout(() => {
        // @ts-ignore
        VK.Widgets.CommunityMessages("vk_community_messages", branch?.integrations?.keys['vkWidget']);
      }, 0)
    }
    {/* /Виджет сообщений ВК */}

    {/* Ретаргетинг ВК */}
    if (branch?.integrations?.keys['vkRetarget']) {
      // @ts-ignore
      VK.Retargeting.Init(branch?.integrations?.keys['vkRetarget']);
      // @ts-ignore
      VK.Retargeting.Hit();
    }
    {/* /Ретаргетинг ВК */}

    {/* facebookPixel */}
    if (branch?.integrations?.keys['facebookPixel']) {
      // @ts-ignore
      ReactPixel.init(branch?.integrations?.keys['facebookPixel'], {}, { autoConfig: true, debug: false });
      ReactPixel.pageView();
    }
    {/* /facebookPixel */}
  }, [branch])

  function metricsEvent(type: 'order' | 'phone' | 'app-icon' | 'add-product' | 'cart-page' | 'order-page') {
    branch?.integrations?.keys['ymetrika'] && ym('reachGoal', type)
  }

  return (
    <MetricsContext.Provider value={{ metricsEvent }}>
      <MetaTags>
        {/* Yandex.Webmaster */}
        {branch?.integrations?.keys['yandexWebmaster'] && <meta name="yandex-verification" content={branch?.integrations?.keys['yandexWebmaster']} />}
        {/* /Yandex.Webmaster */}

        {/* Google Search Console (Google вебмастер) */}
        {branch?.integrations?.keys['googleWebmaster'] && <meta name="google-site-verification" content={branch?.integrations?.keys['googleWebmaster']} />}
        {/* /Google Search Console (Google вебмастер) */}
      </MetaTags>

      {/* yandex-metrika */}
      {
        
        <YMInitializer accounts={[branch?.integrations?.keys['ymetrika']]} version="2" options={{
          clickmap:true,
          trackLinks:true,
          accurateTrackBounce:true,
          webvisor:true,
          ecommerce:"dataLayer"
        }}/>
      }
      {/* /yandex-metrika */}

      <div id="vk_community_messages"></div>
      { children }
    </MetricsContext.Provider>
  )
}

export function useMetricsContext() {
  return useContext(MetricsContext)
}