import {MutableRefObject, useEffect, useRef} from "react";
import {Map, Placemark} from "@pbe/react-yandex-maps";
import locationIcon from "../../../../images/LocationIcon.svg";
import timeIcon from "../../../../images/TimeIcon.svg";
import phoneIcon from "../../../../images/PhoneIcon.svg";
import ymaps from "yandex-maps";
import { useAppContext } from "../../../../contexts/App";
import { timeoutUntilSuccess } from "../../../../libs/helpers/timeoutUntilSuccess";
import { formatOpenHours } from "../../../../libs/helpers/formatOpenHours";


export const AboutMap = ({ currentBranch, company, shouldFocusOnCurrentBranch, isOpenModal }: any) => {
  const {company: mainCompany} = useAppContext()
  const placemarkRefs = useRef<{[k: string] : MutableRefObject<any>}>({}).current;
  const mapRef = useRef<ymaps.Map | undefined>();

  const currentPickupPoint = currentBranch.pickupPoints[0]

  const {city} = useAppContext();

  useEffect(() => {
    const currentBranchIndex = company.branches.flatMap((branch: any, index: number) => branch?.id == currentBranch?.id ? [index] : [])[0]
    const currentPickupPointRefKey = `${currentBranchIndex}:0`
    const placemarkRef = placemarkRefs[currentPickupPointRefKey]
    if (isOpenModal && currentPickupPoint) {
        return timeoutUntilSuccess(()=>{
          if (mapRef.current && placemarkRef?.current) {
            mapRef.current.container.fitToViewport()
            const allPickupPoints = city.branches.map((b: any) => b.pickupPoints).flat();
            if (shouldFocusOnCurrentBranch) {
              mapRef.current?.setCenter([currentPickupPoint.lat, currentPickupPoint.lon])
              placemarkRef.current?.balloon.open()
            } else {
              const p = 0.005
              mapRef.current?.setBounds([
                [Math.min(...allPickupPoints.map((p: any) => parseFloat(p.lat))) - p, Math.min(...allPickupPoints.map((p: any) => parseFloat(p.lon))) - p],
                [Math.max(...allPickupPoints.map((p: any) => parseFloat(p.lat))) + p, Math.max(...allPickupPoints.map((p: any) => parseFloat(p.lon))) + p],
              ])
              placemarkRef.current?.balloon?.close()
            }
            return true
          }
          return false;
        })
    } else if (!isOpenModal) {
      mapRef.current?.container.fitToViewport()
      placemarkRef?.current?.balloon?.close()
    }
  }, [shouldFocusOnCurrentBranch, isOpenModal])


  return (
    <div className="rounded-t-[29px] md:rounded-[29px]  object-cover overflow-hidden w-full h-full">
      <Map
        instanceRef={mapRef}
        modules={["geoObject.addon.balloon", "geoObject.addon.hint"]}
        width={"100%"}
        height={'80vh'}
        defaultState={{
          center: currentPickupPoint ? [
            currentPickupPoint.lat,
            currentPickupPoint.lon,
          ] : [city?.lat, city?.lon],
          zoom: 12.3,
          controls: ['geolocationControl', 'zoomControl'],
        }}
        defaultOptions={{
          yandexMapDisablePoiInteractivity: true,
        }}
      >
        {company.branches.map((branch: any, index: number) => {
          const openHours = formatOpenHours(branch);
          let time = "";
          {Object.keys(openHours).map((key) => {
            if(openHours[key]){
              return (
                (time =
                  time +
                  `
                        <p className="flex flex-row text-gray-50 dark:text-gray-10"
                          key={key}
                        >
                          <span class="text-sm  font-bold w-[50px] inline-block">${key}</span>
                          <span class="text-sm">${openHours[key]}</span>
                        </p>
                      `)
              )
            }
            })}

          if (branch.pickupPoints.length) {

            return  branch.pickupPoints.map((points: any, i: number) => {
              const placemarkKey = `${index}:${i}`
              if (!placemarkRefs[placemarkKey]) placemarkRefs[placemarkKey] = {current: null}
              const placemarkRef = placemarkRefs[placemarkKey];

              return <Placemark
                key={i}
                defaultOptions={{
                  hasBalloon: true,
                  openBalloonOnClick: true,
                }}
                instanceRef={placemarkRef}

                onLoad={(api) => {
                  const MyIconContentLayout = api.templateLayoutFactory.createClass(
                    `<div class="text-white ml-[-4px] mt-[-4px] w-[27px] h-[27px] bg-white rounded-full overflow-hidden border-[3px] p-0.5 border-main">
                      <img class="rounded-full" src="${mainCompany.template.logoThumbnail}"/>
                    </div>`
                  );

                  api.ready(() => {
                    placemarkRef.current.options.set({ iconContentLayout: MyIconContentLayout })
                    if(shouldFocusOnCurrentBranch){
                      placemarkRef.current.balloon.open()
                    }
                  })
                }}
                geometry={[points.lat, points.lon]}
                properties={{
                  balloonContent: `
                        <div class="w-[310px] md:w-max md:max-w-[480px] md:min-w-[350px] px-[20px] py-[15px] md:px-[30px] md:py-[20px] rounded-xl">

                           <div class="flex items-center mb-[12px]">
                            <img src="${locationIcon}"/>
                            <p class=" text-main font-semibold text-md leading-6 ml-[15px] md:ml-[20px]">${points.title}</p> 
                          </div>
                          <div class="flex items-center mb-[8px] md:mb-[10px]">
                            <img src="${phoneIcon}"/>
                            <p class="text-gray-50 font-semibold text-md md:text-lg ml-[15px] md:ml-[20px]">${branch.contacts[0].phone}</p>
                          </div>
                          <div class="flex items-center">
                            <img src="${timeIcon}"/>
                            <div class="text-gray-50 flex ml-[15px] md:ml-[20px] items-center">
                              <p class="text-sm md:text-md">Мы работаем:</p> 
                              <div class="flex flex-col ml-[10px] md:ml-[15px]">
                                <p>${time}</p> 
                              </div> 
                              </div>
                          </div>
                        
                        </div>
                      `,
                }}

                options={{
                  balloonCloseButton: false,
                  balloonOffset:[10,-20],
                  hideIconOnBalloonOpen: false,
                  balloonPanelMaxMapArea: 0,
                  iconLayout: 'default#imageWithContent',
                  iconImageHref: mainCompany.template.logoThumbnail,
                  iconImageSize: [20, 18],
                  iconImageOffset: [-10, -9],
                }}
              />});
          }
        })}
      </Map>
    </div>
  );
};
