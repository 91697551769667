import React from "react";

export default function CloseIcon({className = 'w-[10px] h-[10px]', colorClassName = 'fill-white', onClick}: {
  className?: string;
  colorClassName?: string;
  onClick?: ()=>void;
}) {
  return (
    <svg shapeRendering={'geometricPrecision'} onClick={onClick} className={`${className} ${colorClassName}`} viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.46482 1.55185C9.75811 1.25375 9.74794 0.772492 9.44231 0.487057C9.15089 0.21489 8.69666 0.221081 8.41276 0.50109L4.98297 3.88397L1.5864 0.510458C1.30325 0.22923 0.848409 0.222238 0.556749 0.49463C0.251637 0.779585 0.241481 1.26003 0.534278 1.55763L3.92117 5L0.532895 8.44378C0.240855 8.7406 0.250985 9.21981 0.555307 9.50402C0.847333 9.77676 1.3031 9.76853 1.58509 9.48544L4.98297 6.07434L8.41405 9.49481C8.6968 9.77669 9.15197 9.78411 9.44375 9.5116C9.74859 9.2269 9.75874 8.74688 9.4662 8.44956L6.07226 5L9.46482 1.55185Z"/>
    </svg>
  )
}
