import React from 'react'

export default function SearchIcon({className='', color='#ffffff'}: {className?: string; color?: string;}) {
  return (
    <div className={`${className}`}>
      <svg width="15" height="15" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
        <path shapeRendering={'geometricPrecision'} d="M10.0582 10.9666C9.02035 11.8025 7.69955 12.2852 6.36703 12.2981H6.29073C4.04131 12.2764 1.8949 10.8793 0.944604 8.85702C0.210432 7.29516 0.211916 5.41268 0.944604 3.85349C1.90173 1.81693 4.08376 0.412415 6.32903 0.412415C9.28412 0.412415 11.826 2.51073 12.2238 5.60268C12.4174 7.10723 11.9868 8.67122 11.089 9.89004C11.0728 9.92223 11.0237 9.98958 10.9321 10.1015C10.9321 10.1015 13.3083 12.4617 14.4747 13.6628C14.8796 14.1007 14.149 14.9747 13.6526 14.5184C12.5963 13.4936 10.097 10.9467 10.0931 10.9426L10.0582 10.9666ZM1.45968 7.25568C1.88332 9.51965 4.0137 11.2855 6.29696 11.3074H6.36079C7.67743 11.2947 8.95429 10.7383 9.86481 9.80613C10.9027 8.74362 11.4308 7.20106 11.2414 5.72915C11.0202 4.01202 9.82028 2.45343 8.21715 1.7822C6.42521 1.03199 4.2702 1.47523 2.89923 2.79899C1.72479 3.93246 1.16103 5.65968 1.45968 7.25568Z"
        fill={color}/>
      </svg>
    </div>
  )
}
