import {localStorageWrap} from "../helpers/localStorageWrap";

export default async function getAddress(city: any, branch: any) {

  let addressStorage: any = JSON.parse(localStorageWrap.getItem('address') || 'null');

  if (addressStorage && addressStorage.point) {
    if (addressStorage.type === 'pickup') {
      // Адрес самовывоза
      if (addressStorage.point.city == city.slug && 
        +addressStorage.point.branchId === branch.id && 
        city.branches?.filter((b: any) => b.id === branch.id)[0]?.pickupPoints?.filter((p: any) => p.id === addressStorage.point.id)?.length
      ) {
        return addressStorage
      }
      //console.log(`address reset: pickup point branch/city do not match city=${addressStorage.point.city}/${city.slug} branch=${+addressStorage.point.branchId}/${branch.id}`)
    } else if (addressStorage.type === 'delivery') {
      // Адрес доставки
      if (addressStorage.point.city == city.slug) {
        return addressStorage
      }
      //console.log("address reset: delivery city do not match")
    }
  }
  
  return null
}
