import React from 'react'
import { useAppContext } from '../../contexts/App'
import { Link, useLocation } from 'react-router-dom';


export default function Nav() {
  const { city } = useAppContext()
  let location = useLocation();

  return (
    <div className='hidden md:flex gap-[25px] py-5 text-xs font-medium text-gray-60 dark:text-gray-10'>
      {
        items.map(item => (
          <Link className={`${location.pathname === `/${city.slug}${item.url}` ? 'text-main relative after:content-[""] after:absolute after:bottom-[-3px] after:left-0 after:w-full after:h-[2px] after:bg-main after:rounded-full dark:text-main' : 'hover:text-main dark:hover:text-main'}`} to={`/${city.slug}${item.url}`} key={item.url}>{ item.label }</Link>
        ))
      }
    </div>
  )
}


const items = [
  {
    url: '',
    label: 'Каталог'
  },
  {
    url: '/stock',
    label: 'Акции'
  },
  {
    url: '/about',
    label: 'О нас'
  },
  {
    url: '/reviews',
    label: 'Отзывы'
  },
  {
    url: '/payment',
    label: 'Доставка и оплата'
  },
]