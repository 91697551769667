import { useEffect, useState } from "react";
import { IQuestionnaire } from "../account/history";
import { useAppContext } from "../../../contexts/App";
import { useParams } from "react-router-dom";
import { apiClient } from "../../../libs/api/apiClient";
import { Preloader } from "../../../components/common/preloader";
import FirstStep from "../../../components/pages/feedbacksPage/firstStep";
import SecondStep from "../../../components/pages/feedbacksPage/secondStep";
import ThirdStep from "../../../components/pages/feedbacksPage/thirdStep";


export default function Feedback() {
  const [questionnaire, setQuestionnaire] = useState<IQuestionnaire | null>(null)
  const { company } = useAppContext();
  const [step, setStep] = useState(1);
  let maxLengthTextarea = 1000;
  const { id } = useParams();
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchQuestionnaire = async () => {
      await apiClient.profileOrders.getQuestionnaire(id)
        .then(({data}) => {
          setLoading(false)
          setQuestionnaire(data)
        })
    }
    fetchQuestionnaire()
  }, [])

  useEffect(() => {
    // orderReviewCreatedAt заполнен первый шаг
    // goodReviewCreatedAt заполнен второй шаг
    // questionAnswerCreatedAt заполнен третий шаг

    if (questionnaire?.orderReviewCreatedAt) {
      setStep(2)
    }
    if (questionnaire?.goodReviewCreatedAt || !questionnaire?.goods?.length) {
      setStep(3)
    }

    if(questionnaire?.questionAnswerCreatedAt){
      setStep(4)
    }
  }, [questionnaire])

  const nextStep = () => {
    if (step === 1) {
      if (!questionnaire?.goods?.length) {
        if (!questionnaire?.questions?.length) {
          setStep(4)
          return
        }
        setStep(3)
        return
      } else {
        setStep(2)
        return
      }
    }

    if (step === 2) {
      if (!questionnaire?.questions?.length) {
        setStep(4)
        return
      }
      setStep(3)
      return
    }

    setStep(4)
  }

  return (
    <>
      <div className="container ">
        <div className="mt-[15px] pb-[15px] border-b ">
          <h2 className="xs:text-[28px] text-2xl font-bold md:w-[640px] m-auto">
            Отзыв о заказе # {questionnaire?.orderId}
          </h2>
        </div>
        {loading?
          <Preloader countOfDot={4} size={'10px'} className={'h-9 gap-2 mt-[20px]'}/>
          :
          <div>
            {step < 4 && (
              <div className="xs:mt-[40px] xs:mb-[40px] mt-[20px] mb-[20px] md:w-[630px] m-auto">
                <h3 className="xs:text-[28px] text-2xl font-bold text-dark dark:text-light">
                  {step < 2? 'Оставьте отзыв' : step === 2 && 'Оцените блюдо из вашего заказа'}
                </h3>
              </div>
            )}

            <div className="md:w-[630px] m-auto">
              {step === 1 && (
                <FirstStep maxLengthTextarea={maxLengthTextarea} nextStep={nextStep} questionnaireId={id}/>
              )}

              {step === 2 &&
                <SecondStep goods={questionnaire?.goods} nextStep={nextStep} questionnaireId={id}/>
              }

              {step === 3 &&
                <ThirdStep maxLengthTextarea={maxLengthTextarea} questions={questionnaire?.questions} nextStep={nextStep} questionnaireId={id}/>
              }

              {step === 4 &&
                <div className="mt-[20px] mb-[20px] md:w-[630px] m-auto">
                  <h3 className="xs:text-[28px] text-2xl font-bold mb-[15px]">Спасибо за отзыв и обратную связь!</h3>
                  <h3 className="xs:text-[28px] text-2xl font-bold text-main">Это очень важно для нас!</h3>
                </div>
              }

              {step < 4 && (
                <p className="text-gray-50 text-sm font-medium opacity-70 mt-[15px] leading-4 xs:text-start text-center  lg:mb-[100px] mb-[55px]">
                  Размещая отзыв, вы даете согласие на использование{" "}
                  <a href="" className=" text-main">
                    данных отзыва на сторонних ресурсах
                  </a>
                </p>
              )}
            </div>
          </div>
        }

      </div>
    </>
  );
}