import {useEffect, useRef} from "react";

export function useUpdatePage(
  f: () => void,
  {
    interval = -1,
    onOpened = true,
    isOpen = true,
    deps = []
  }
    : {
    interval?: number,
    isOpen?: boolean,
    onOpened?: boolean,
    deps?: any[]
  } = {}) {
  const funcRef = useRef(f)
  funcRef.current = f

  useEffect(() => {
    if (isOpen) {
      if (onOpened) funcRef.current()
      if (interval > 0) {
        const handle = setInterval(() => funcRef.current(), interval)
        return () => clearInterval(handle)
      }
    }
  }, [isOpen, ...deps])
}
