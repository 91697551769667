export const getRGBAColor = (hex: string, type: string) => {
  let color = hex.replace(/#/g, "")
  // rgb values
  let r = parseInt(color.substr(0, 2), 16)
  let g = parseInt(color.substr(2, 2), 16)
  let b = parseInt(color.substr(4, 2), 16)

  if (color.length > 6) {
    let a = parseInt(color.substr(6, 2), 16) / 255
    return `--color-${type}: ${r}, ${g}, ${b}, ${a};`
  }

  return `--color-${type}: ${r}, ${g}, ${b}, 1;`
}