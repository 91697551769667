import React from 'react'

export default function VkIcon({colorClassName='fill-white dark:fill-gray-50', className=''}: {
  colorClassName?: string;
  className?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 26 15" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M14.0555 14.2761C14.0555 14.2761 14.5175 14.2257 14.7541 13.9762C14.9707 13.7476 14.9632 13.3162 14.9632 13.3162C14.9632 13.3162 14.9344 11.3018 15.8873 11.0043C16.8264 10.7118 18.0321 12.9524 19.3118 13.814C20.2784 14.4654 21.0121 14.3228 21.0121 14.3228L24.4316 14.2761C24.4316 14.2761 26.2196 14.1679 25.3719 12.7877C25.3018 12.6746 24.8773 11.7664 22.8302 9.90057C20.6853 7.94767 20.9733 8.26347 23.5551 4.88487C25.1278 2.82737 25.7563 1.57127 25.5597 1.03427C25.3732 0.52047 24.2162 0.656871 24.2162 0.656871L20.3673 0.68027C20.3673 0.68027 20.0818 0.642171 19.8702 0.766271C19.6636 0.887971 19.5296 1.17187 19.5296 1.17187C19.5296 1.17187 18.9211 2.76347 18.1085 4.11787C16.3944 6.97427 15.7095 7.12537 15.429 6.94847C14.7767 6.53427 14.9394 5.28677 14.9394 4.40057C14.9394 1.63157 15.3677 0.477469 14.1068 0.178769C13.6886 0.0792691 13.3806 0.0140699 12.31 0.00306988C10.9365 -0.0104301 9.7745 0.00797066 9.1159 0.323871C8.6777 0.533971 8.3396 1.00347 8.5462 1.03057C8.8004 1.06367 9.3764 1.18297 9.6819 1.59097C10.0763 2.11827 10.0625 3.30057 10.0625 3.30057C10.0625 3.30057 10.2892 6.56007 9.5329 6.96437C9.0145 7.24217 8.3033 6.67557 6.7745 4.08347C5.992 2.75617 5.401 1.28867 5.401 1.28867C5.401 1.28867 5.287 1.01457 5.0829 0.86707C4.8363 0.68887 4.4919 0.633571 4.4919 0.633571L0.834598 0.656871C0.834598 0.656871 0.284898 0.671669 0.0832982 0.906369C-0.0957018 1.11407 0.0694975 1.54547 0.0694975 1.54547C0.0694975 1.54547 2.9331 8.12217 6.176 11.437C9.1497 14.4752 12.5254 14.2761 12.5254 14.2761H14.0555Z" 
      className={colorClassName}/>
    </svg>
  )
}
