import { useEffect, useState } from "react";
import mainStock from "../../images/mainStock.jpg";
import time from "../../images/time.svg";
import { useCartContext } from "../../contexts/CartContext";
import { useAuthContext } from "../../contexts/AuthContext";
import { useAppContext } from "../../contexts/App";
import { sendEvent, useVisitorContext } from "../../contexts/VisitorContext";
import { useUpdatePage } from "../../hooks/useUpdatePage";
import { apiClient } from "../../libs/api/apiClient";
import DetailStock from "../../components/pages/stocksPage/detailStock";
import { findDiffDate } from "../../libs/helpers/findDiffDate";
import { Preloader } from "../../components/common/preloader";
import { BaseButton } from "../../components/common/button/BaseButton";
import { NotStockImage } from "../../components/icons/notStockImage";
import { useSearchParams } from "react-router-dom";
import { animateScroll } from "react-scroll";


interface IButtonsStock{
  url: string,
  title: string
}

interface IStock {
  buttons: IButtonsStock[] | null,
  description: string,
  endAt: string,
  id: number,
  image: string | null,
  promoCode: string
  shortDescription: string
  title: string,
}

export default function Stock() {
  const { cart, loading, updateCart } = useCartContext()
  const { user } = useAuthContext()
  const { company, branch } = useAppContext();

  const [stocks, setStocks] = useState<IStock[]>([]);
  const [additionalInfo, setAdditionalInfo]= useState<{description: string} | null>(null)
  const [fetching, setFetching] = useState(true);
  const [choosedStockId, setChoosedStockId] = useState<any>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { visitorId } = useVisitorContext()


  useEffect(() => {
    if (visitorId) {
      sendEvent('section-visited', {target: 'Акции'})
    }
  }, [visitorId])

  useEffect(() => {
    animateScroll.scrollToTop()
  }, [])

  useUpdatePage(
    () => {
      apiClient.stocks.get(branch.id).then((response) => {
        setAdditionalInfo(response.additional)
        setStocks(response.data)
        setFetching(false)
      })
    }, {interval: 1000 * 60 * 60, deps: [branch.id]}
  )

  useEffect(() => {
//    setCurrentPage(1);
    setFetching(true);
    setStocks([])
  }, [user?.phone_confirmed, user?.token])

  const handlerOpenModal = (stock: IStock) => {
    setSearchParams(params => {
      params.set("stock", stock.id + '');
      return params;
    });

    setIsActive(true)
  };

  const handlerCloseModal = () => {
    setSearchParams(params => {
      params.delete("stock");
      return params;
    });
    setIsActive(false)
  };

  useEffect(() => {
    let stockInQuery = searchParams.get('stock')
    if (stockInQuery) {
      setChoosedStockId(stockInQuery)
    } else {
      setChoosedStockId(null)
    }

  }, [searchParams])

  const [isActive, setIsActive] = useState(false)

  return (
    <>
      <div className="container">
        {choosedStockId && (
          <DetailStock
            isActive={isActive}
            close={handlerCloseModal}
            stockId={choosedStockId}
            stocks={stocks}
          />
        )}

        <div className="md:mt-[20px] mb-[25px] md:mt-0 mt-[15px]">
          <h2 className="md:text-4xl font-bold text-2xl text-dark dark:text-light">Акции</h2>
        </div>

        <div className="sm:mb-[40px] mb-[30px]">
          {!!additionalInfo?.description && <p className="break-words text-gray-50 dark:text-white md:text-base text-sm">
            {additionalInfo?.description}
          </p>}
        </div>
        <div className="mb-[50px] flex w-[100%] sm:gap-[2%]  sm:flex-row flex-col flex-wrap">
          {fetching
            ? <div className={'w-full pt-[20vh] flex justify-center'}> <Preloader countOfDot={4} className='gap-2 mb-[100px]'/> </div>
            : stocks.length > 0
                ? stocks.map((stock: IStock, index: number) => {
                  const {days, hours, mins}= findDiffDate(stock.endAt);
                  const bgColor = (days || hours > 2) ? "additional" : "main";
                  return (
                    <div
                      key={index}
                      className="md+:w-[32%] flex flex-col justify-between sm:w-[49%] w-[100%] p-[15px] bg-white dark:bg-gray-50 rounded-[29px] overflow-hidden shadow-md mb-[25px] md+:mb-[20px]"
                    >
                      <div>
                        <div className="relative">
                          {stock.endAt &&
                              <div
                                  className={`absolute bg-${bgColor} w-max rounded-2xl text-xs px-[13px] top-[15px] left-[15px] text-white py-[6px]`}
                              >
                    <span className="flex items-center">
                      До конца действия:
                      <img className="mx-[6px]" src={time}/>
                      <span>
                        {days?
                          `${days} д ${hours} ч`
                          :
                          null
                        }
                        {(!days && hours) ?
                          `${hours} ч`
                          :
                          null
                        }
                        {(!days && !hours && mins)?
                          `${mins} мин`
                          :
                          null
                        }
                        {(!days && !hours && !mins)?
                          `Меньше минуты`
                          :
                          null
                        }
                      </span>
                    </span>
                              </div>
                          }
                          {stock.image ? (
                            <img src={stock.image} onClick={() => handlerOpenModal(stock)} className="rounded-2xl cursor-pointer"/>
                          ) : (
                            <img
                              onClick={() => handlerOpenModal(stock)}
                              className="w-full rounded-2xl cursor-pointer"
                              src={mainStock}
                              alt=""
                            />
                          )}
                        </div>

                        <div>
                          <h3 className="text-gray-60 text-xl font-bold mt-[25px] cursor-pointer dark:text-white" onClick={() => handlerOpenModal(stock)}>
                            {stock.title}
                          </h3>
                          <p className="text-gray-50 mt-[15px] dark:text-white">
                            {stock.shortDescription}
                          </p>
                        </div>
                      </div>

                      <div className="justify-end">
                        <div className="mt-[30px] flex justify-between  sm:flex-row flex-col ">
                          <BaseButton
                            onClick={() => handlerOpenModal(stock)}
                            className="border-solid sm:mb-[0] mb-[20px] hover:bg-main hover:text-white border border-main font-bold text-base text-main sm:w-[45%] w-[100%]"
                          >
                            Подробнее
                          </BaseButton>

                          {stock.promoCode && branch.order.fields.promoCode?.isUsed &&
                              <>
                                {
                                  stock.promoCode.toLowerCase() !== cart.cartPromoCode.promoCode?.code?.toLowerCase() ?
                                    <BaseButton onClick={() => !loading && updateCart({promoCode: stock.promoCode, raw: cart.raw})} className="bg-main hover:opacity-80 font-bold text-base text-white sm:w-[45%] w-[100%]">
                                      Промокод
                                    </BaseButton> :
                                    <BaseButton disabled={true} className="bg-main font-bold text-base text-white sm:w-[45%] w-[100%]">
                                      Применён
                                    </BaseButton>
                                }
                              </>
                          }

                        </div>
                      </div>
                    </div>
                  );
                })
                : <div className={'w-full h-full min-h-[50vh] flex flex-col justify-center items-center'}>
                  {/*хз че засунуть но без этого грустно*/}
                  <div className={'mb-[5vh]'}><NotStockImage/></div>
                  <p className={'text-2xl font-bold text-gray-60 dark:text-white'}>Совсем скоро</p>
                  <p className={'text-2xl font-bold text-main'}>здесь появятся самые</p>
                  <p className={'text-2xl font-bold text-main'}>вкусные акции</p>
                </div>
          }
        </div>
      </div>
    </>
  );
}