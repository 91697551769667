import { useState } from "react"


export const CustomBoolSlider = ({inPopup, answers, id}: {inPopup: boolean, answers: any, id: number}) => {
  const [isChangeSlider, setChangeSlider] = useState(false)

  const onChangeSlider = () => {
    if(answers) {
      const currentAnswer = answers.find((answer: any) => answer.npsQuestionId === id)
      if(currentAnswer){
        currentAnswer.answer = isChangeSlider? 'yes' : 'no'
      }
    }
  }
  return (
    <div className={`h-[40px] ${inPopup ? 'w-full' : 'xs:w-[60%]'} dark:bg-gray-40  w-full rounded-[10px] bg-gray-20 p-[3px]`}>
      <div className="relative h-full w-full flex ">
        <div
          className={`absolute h-full w-1/2 rounded-[10px] bg-gray-30 duration-500 ${isChangeSlider ? "translate-x-full" : "translate-x-0"}`}
        />
        
        <div
          onClick={() => {
            setChangeSlider(false)
            onChangeSlider()
          }}
          className={`w-1/2 flex items-center justify-center cursor-pointer z-[10] text-sm font-bold duration-500 ${isChangeSlider ? "font-normal" : "font-bold"}`}
        >
          Да
        </div>
          
        <div
          onClick={() => {
            setChangeSlider(true)
            onChangeSlider()
          }}
          className={`w-1/2 flex items-center justify-center cursor-pointer z-[10] text-sm font-bold duration-500 ${!isChangeSlider ? "font-normal" : "font-bold"}`}
        >
          Нет
        </div>
        
      </div>
    </div>
  )
}