import React, { useEffect, useState } from 'react'
import { useAppContext } from '../../contexts/App';
import { apiClient } from '../../libs/api/apiClient';
import { useMetricsContext } from '../../contexts/MetricsContext';

export default function PersonalDataPolicy({host}: any) {
  const { company } = useAppContext()
  const { metricsEvent } = useMetricsContext()
  const [companyInfo, setCompanyInfo] = useState<any>(null)

  useEffect(() => {
    apiClient.about.get().then(({data}) => {
      setCompanyInfo(data)
    })
  }, [])

  return (
    <div className='w-full min-h-screen py-8'>
      <div className='container text-dark dark:text-light'>
        <div className='my-4 text-2xl'>Политика обработки персональных данных</div>

        <div className='my-4 text-2xl'>1. Общие положения</div>
        
        <div className='my-3'>
          Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федерального закона от 27.07.2006. №152-ФЗ «О персональных данных» и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных {company.companyNamePlural ? company.companyNamePlural : 'Администрации сайта'} (далее – Оператор).
        </div>

        <div className='my-3'>
          Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.
        </div>

        <div className='my-3'>
          Настоящая политика Оператора в отношении обработки персональных данных (далее – Политика) применяется ко всей информации, которую Оператор может получить о посетителях веб-сайта, на котором размещен данный текст (далее – Веб-сайт).
        </div>

        <div className='my-4 text-2xl'>2. Основные понятия и термины, используемые в Политике</div>

        <div className='my-3'>
          Автоматизированная обработка персональных данных – обработка персональных данных с помощью средств вычислительной техники;
        </div>
        
        <div className='my-3'>
          Блокирование персональных данных – временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных);
        </div>
        
        <div className='my-3'>
          Веб-сайт – совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет;
        </div>

        <div className='my-3'>
          Информационная система персональных данных — совокупность содержащихся в базах данных персональных данных, и обеспечивающих их обработку информационных технологий и технических средств;
        </div>

        <div className='my-3'>
          Обезличивание персональных данных — действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных;
        </div>

        <div className='my-3'>
          Обработка персональных данных – любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных;
        </div>

        <div className='my-3'>
          Оператор – юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными;
        </div>

        <div className='my-3'>
          Персональные данные – любая информация, относящаяся прямо или косвенно к определенному или определяемому Пользователю Веб-сайта;
        </div>

        <div className='my-3'>
          Пользователь – любой посетитель Веб-сайта;
        </div>

        <div className='my-3'>
          Предоставление персональных данных – действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц;
        </div>

        <div className='my-3'>
          Распространение персональных данных – любые действия, направленные на раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом;
        </div>

        <div className='my-3'>
          Трансграничная передача персональных данных – передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу;
        </div>

        <div className='my-3'>
          Уничтожение персональных данных – любые действия, в результате которых персональные данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной системе персональных данных и (или) результате которых уничтожаются материальные носители персональных данных.
        </div>

        <div className='my-4 text-2xl'>3. Оператор может обрабатывать следующие персональные данные Пользователя:</div>

        <div className='my-3'>
          - Фамилия, имя, отчество;<br/>
          - Электронный адрес;<br/>
          - Номера телефонов;<br/>
          - Адреса доставки;<br/>
          - Год, месяц, дата рождения;<br/>
          - Фотографии;
        </div>

        <div className='my-3'>
          Согласно п.1 ст. 3 Федерального закона от 27.07.2006 N 152-ФЗ «О персональных данных» персональные данные - любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных).
        </div>

        <div className='my-3'>
          Оператор также может использовать сервисы интернет-статистики (Яндекс Метрика, Google Аналитика, Facebook Pixel, VK Pixel и другие) для сбора сведений об использовании Сайта, таких как:
        </div>

        <div className='my-3'>
          - HTTP-заголовки;<br/>
          - IP-адрес;<br/>
          - Файлы Cookie;<br/>
          - Данные об идентификаторе браузера;<br/>
          - Информация об аппаратном и программном обеспечении;<br/>
          - Дата и время осуществления доступа к сервисам;<br/>
          - Информация о геолокации;
        </div>

        <div className='my-3'>
          Вышеперечисленные данные далее по тексту Политики объединены общим понятием Персональные данные.
        </div>

        <div className='my-4 text-2xl'>4. Цели обработки персональных данных</div>

        <div className='my-3'>
          Цель обработки персональных данных Пользователя — информирование Пользователя посредством отправки электронных писем (e-mail), СМС-сообщений, сообщений в мессенджеры (Telegram, Viber, WhatsApp) и сообщений в социальные сети (Вконтакте); заключение, исполнение и прекращение гражданско-правовых договоров; предоставление доступа Пользователю к сервисам, информации и/или материалам, содержащимся на Веб-сайте; уточнение деталей заказа.
        </div>

        <div className='my-3'>
          Также Оператор имеет право направлять Пользователю уведомления о новых продуктах и услугах, специальных предложениях и различных событиях. Пользователь всегда может отказаться от получения информационных сообщений, направив Оператору письмо на адрес электронной почты {company.email ? company.email : ''} с пометкой «Отказ от уведомлений о новых продуктах и услугах и специальных предложениях».
        </div>

        <div className='my-3'>
          Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики, служат для сбора информации о действиях Пользователей на сайте, улучшения качества сайта и его содержания.
        </div>

        <div className='my-4 text-2xl'>4.1. Принципы обработки персональных данных</div>

        <div className='my-3'>
          Обработка персональных данных должна ограничиваться достижением конкретных, заранее определенных и законных целей. Не допускается обработка персональных данных, несовместимая с целями сбора персональных данных.
        </div>

        <div className='my-3'>
          Обработке подлежат только персональные данные, которые отвечают целям их обработки.
        </div>

        <div className='my-3'>
          При обработке персональных данных должны быть обеспечены точность персональных данных, их достаточность, а в необходимых случаях и актуальность по отношению к целям обработки персональных данных. Оператор должен принимать необходимые меры либо обеспечивать их принятие по удалению или уточнению неполных или неточных данных.
        </div>

        <div className='my-3'>
          Все вышеуказанные принципы обработки персональных данных полностью соответствуют положениям ст. 5 Федерального закона от 27.07.2006 N 152-ФЗ «О персональных данных».
        </div>

        <div className='my-4 text-2xl'>5. Правовые основания обработки персональных данных</div>

        <div className='my-3'>
          Оператор обрабатывает персональные данные Пользователя только в случае их заполнения и/или отправки Пользователем самостоятельно через специальные формы, расположенные на Веб-сайте. Заполняя соответствующие формы и/или отправляя свои персональные данные Оператору, Пользователь выражает свое согласие с данной Политикой.
        </div>

        <div className='my-3'>
          Оператор обрабатывает обезличенные данные о Пользователе в случае, если это разрешено в настройках браузера Пользователя (включено сохранение файлов «cookie» и использование технологии JavaScript).
        </div>

        <div className='my-3'>
          Правовые основания обработки персональных данных в том числе основаны на принципах, регламентированных Федеральным законом «Об информации, информационных технологиях и о защите информации» от 27.07.2006 N 149-ФЗ
        </div>

        <div className='my-4 text-2xl'>6. Порядок сбора, хранения, передачи и других видов обработки персональных данных</div>

        <div className='my-3'>
          Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем реализации правовых, организационных и технических мер, необходимых для выполнения в полном объеме требований действующего законодательства в области защиты персональных данных.
        </div>

        <div className='my-3'>
          Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц.
        </div>

        <div className='my-3'>
          Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам, за исключением случаев, связанных с исполнением действующего законодательства.
        </div>

        <div className='my-3'>
          В случае выявления неточностей в персональных данных, Пользователь может актуализировать их самостоятельно, путем направления Оператору уведомление на адрес электронной почты Оператора {company.email ? company.email : ''} с пометкой «Актуализация персональных данных».
        </div>

        <div className='my-3'>
          Срок обработки персональных данных является неограниченным. Пользователь может в любой момент отозвать свое согласие на обработку персональных данных, направив Оператору уведомление посредством электронной почты на электронный адрес Оператора {company.email ? company.email : ''} с пометкой «Отзыв согласия на обработку персональных данных».
        </div>

        <div className='my-4 text-2xl'>7. Трансграничная передача персональных данных</div>

        <div className='my-3'>
          Оператор до начала осуществления трансграничной передачи персональных данных обязан убедиться в том, что иностранным государством, на территорию которого предполагается осуществлять передачу персональных данных, обеспечивается надежная защита прав субъектов персональных данных.
        </div>

        <div className='my-3'>
          Трансграничная передача персональных данных на территории иностранных государств, не отвечающих вышеуказанным требованиям, может осуществляться только в случае наличия согласия в письменной форме субъекта персональных данных на трансграничную передачу его персональных данных и/или исполнения договора, стороной которого является субъект персональных данных.
        </div>

        <div className='my-4 text-2xl'>8. Заключительные положения</div>

        <div className='my-3'>
          Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его персональных данных, обратившись к Оператору с помощью электронной почты {company.email ? company.email : ''}.
        </div>

        <div className='my-3'>
          В данном документе будут отражены любые изменения политики обработки персональных данных Оператором. Политика действует бессрочно до замены ее новой версией.
        </div>

        {
          !!companyInfo &&
          <>
            <div className='my-4 text-2xl'>Реквизиты</div>

            <div className="text-gray-50 dark:text-gray-10 text-sm md:text-base">
              {companyInfo.company_name &&
                <p>Компания: {companyInfo.company_name}</p>
              }
              {companyInfo.inn &&
                <p>ИНН: {companyInfo.inn}</p>
              }
              {companyInfo.ogrn &&
                <p>ОГРН: {companyInfo.ogrn}</p>
              }
              {companyInfo.address &&
                <p>Юридический адрес: {companyInfo.address}</p>
              }
              {companyInfo.email &&
                <p>Почта: {companyInfo.email}</p>
              }
              {companyInfo.phone &&
                <p>Телефон: {companyInfo.phone}</p>
              }
            </div>
          </>
        }
        
      </div>
    </div>
  )
}