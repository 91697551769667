import {useEffect, useRef, useState} from "react";

export function useWindowResize<T>({threshold = 10, cvt = (x: number) => x as T}: {threshold?: number, cvt?: (x: number) => T} = {}) {
  const [windowWidth, setWindowWidth] = useState(cvt(0))
  const params = useRef({cvt, threshold}).current
  params.cvt = cvt
  params.threshold = threshold

  //needed for hydration
  useEffect(() => {
    setWindowWidth(cvt(window.innerWidth))
  }, [])

  useEffect(() => {
    let lastWidth = window.innerWidth
    function handler() {
      if (Math.abs(lastWidth - window.innerWidth) > params.threshold) {
        setWindowWidth(params.cvt(window.innerWidth))
      }
    }
    window.addEventListener('resize', handler)
    return () => window.removeEventListener('resize', handler)
  }, [params])

  return windowWidth;
}
