import React, { useState, useEffect } from 'react'
import moment from 'moment';
import { useAppContext } from '../../../../contexts/App';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useAddressContext } from '../../../../contexts/AddressContext';
import { useCartContext } from '../../../../contexts/CartContext';
import { formatAddress } from '../../../../libs/helpers/formatAddress';
import { localStorageWrap } from '../../../../libs/helpers/localStorageWrap';
import { TextInput } from '../../../common/form/input/TextInput';
import { Preloader } from '../../../common/preloader';
import PickupPointsSelector from '../../../common/form/PickupPointsSelector';
import { InputSelectorAddressSearchWithSaved } from '../../../common/form/input/InputSelectorDadata';
import DateInput from '../../../common/form/DateInput';
import InputSelector from '../../../common/form/input/InputSelector';
import Textarea from '../../../common/form/textarea';
import pluralize from '../../../../libs/helpers/pluralize';
import { ChangeBranchPopup } from '../../../common/popup/citites/Modals';
import Popup from '../../../common/popup';
import { BaseButton } from '../../../common/button/BaseButton';
import { useNavigate } from 'react-router-dom';
import { apiClient } from '../../../../libs/api/apiClient';
import AddressForm from './AddressForm';


export default function Form({ data, updateField, errors, updateErrors, updateData }: any) {
  const { branch, city, company, address, openTimeInfo } = useAppContext()
  const { user } = useAuthContext()
  const {trySaveDeliveryPoint, trySavePickupPoint, allUserAddresses, addressRef, addressError, setAddressError} = useAddressContext()
  const { cart } = useCartContext()
  const navigate = useNavigate();

  const [pickupPoint, setPickupPoint] = useState<any>(null)
  const [deliveryAddress, setDeliveryAddress] = useState<any>(address?.type === 'delivery' ? {name: formatAddress(address.point, []), addr: address.point} :{name: ''})//query address
  const [deliveryMinTime, setDeliveryMinTime] = useState<any>(null)


  const [showChangePhonePopup, setShowChangePhonePopup] = useState(false)

  const [isLoadingButton, setLoadingButton] = useState('none')

  useEffect(() => {
    if (address?.type === 'pickup') {
      const point = address.point
      console.log("updating pickup point", point)
      if (point)
      {
        updateField('deliveryType', 'pickup')
        updateField('pickupPointId', point.id)
        setPickupPoint({...point, title: formatAddress(point, []) || ''})
        setAddressError(null)
      }
    } else if (address?.type === 'delivery') {
      const point = address.point
      console.log("updating delivery address", point)
      if (point) {
        updateField('entrance', point.entrance || '')
        updateField('floor', point.floor || '')
        updateField('flat', point.flat || '')
        updateField('deliveryAddress', {...point, city: city.title})
        updateField('branchId', point.branchId)
      } else {
        updateField('deliveryAddress', null)
        updateField('branchId', null)
      }
      updateField('deliveryType', 'courier')
      setAddressError(null)
      setDeliveryAddress({name: formatAddress(address.point, []) || '', addr: address.point})
    }
  }, [address])

  useEffect(() => {
    updateField('deliveryCity', city.guid)
  }, [city?.slug])


  useEffect(() => {
    updateField('clientName', (user?.name) || '')
    updateField('clientEmail', (user?.email) || '')
  }, [user])


  const [variantsTimePreorder, setVariantsTimePreorder] = useState([])
  const [minDate, setMinDate] = useState(new Date())

  useEffect(() => {
    if (address?.point?.deliveryZoneId) {
      apiClient.delivery.getById(branch.id, address.point.deliveryZoneId).then(({data}) => {
        console.log(data.preorderOnDeliveryMinTime)
        setDeliveryMinTime(data.preorderOnDeliveryMinTime ? +data.preorderOnDeliveryMinTime : 0)
      })
    } else {
      setDeliveryMinTime(0)
    }
  }, [])

  function getVariantsTimePreorder(date: any) {
    if (!branch.order.preorderAllowedTimes.length) 
      return []

    return branch.order.preorderAllowedTimes
      .filter(({time}: any) =>  moment(date).set({'hour': time.split(':')[0], 'minute': time.split(':')[1]}) > moment().add(deliveryMinTime, 'm'))
      .map(({time, title}: any) => ({name: title, id: time}))
  }

  useEffect(() => {
    if (typeof deliveryMinTime === 'number') {
      let now = new Date()
      let day = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      let variants = getVariantsTimePreorder(day)
      updateField('deliveryDate', new Date(day))
  
      while (!variants.length && branch.order.preorderAllowedTimes.length) {
        day.setDate(day.getDate() + 1);
        updateField('deliveryDate', new Date(day))
        variants = getVariantsTimePreorder(day)
      }
      
      updateField('deliveryTime', variants[0]?.id || '00:00')
      setMinDate(day)
    }
  }, [deliveryMinTime])

  useEffect(() => {
    if (data.deliveryDate && typeof deliveryMinTime === 'number') {
      let variants = getVariantsTimePreorder(data.deliveryDate)
      setVariantsTimePreorder(variants)

      if (moment(data.deliveryTime, 'hh:mm') < moment()) {
        updateField('deliveryTime', variants[0]?.id || '00:00')
      }

      let time
      if (data?.deliveryTime) {
        time = data.deliveryTime
      } else {
        time = variants[0]?.id
      }

      updateField('preorderAt', `${data?.deliveryDate.getFullYear()}-${data?.deliveryDate.getMonth() + 1}-${data?.deliveryDate.getDate()}  ${time}`)
    }
  }, [data?.deliveryDate, branch?.id, deliveryMinTime])

  useEffect(() => {
    if (data?.deliveryTime) {
      updateField('preorderAt', `${data?.deliveryDate.getFullYear()}-${data?.deliveryDate.getMonth() + 1}-${data?.deliveryDate.getDate()} ${data.deliveryTime}`)
    }
  }, [data?.deliveryTime])

  useEffect(() => {
    if (cart.paymentType !== data.paymentType) {
      updateData({'paymentType': cart.paymentType})
    }
  }, [cart.options.paymentTypeObjects, cart.paymentType])

  return (
    <div className='flex flex-col gap-5 my-5'>
      <div>
        <TextInput
          name={'clientName'}
          value={data.clientName || ''}
          onChange={(value) => updateField('clientName', value)}
          placeholderInput={"Имя"}
          errors={errors}
        />
      </div>


      <div>

        <TextInput
          name={'clientPhone'}
          value={(user?.phone?.length) ? user?.phone || '' : localStorageWrap.getItem('clientPhone') || ''}
          onChange={(value) => {}}
          errors={errors}
          disabled={!!user?.phone || !!localStorageWrap.getItem('clientPhone')}
          getAuxElement={() => {
            if (!!user?.phone || !!localStorageWrap.getItem('clientPhone')) {
              return <span onClick={() => {
                setShowChangePhonePopup(true);
              }}>{'Изменить'}</span>
            }
            return <></>
          }}
        />
      </div>
      
      {
        branch.order.fields?.clientEmail?.isUsed && 
        <TextInput
          name={'clientEmail'}
          value={data.clientEmail || ''}
          onChange={(value) => {updateField('clientEmail', value)}}
          placeholderInput={`Email${branch.order.fields?.clientEmail.isRequired ? '*' : ''}`}
          errors={errors}
        />
      }

      <AddressForm
        data={data}
        updateField={updateField}
        errors={errors}
        deliveryAddress={deliveryAddress}
        pickupPoint={pickupPoint}
      />

      {
        !!branch.order.fields.deliveryDate?.isUsed && 
        <>
          <div className='h-[40px] w-full rounded-[10px] bg-orderbtn p-[3px]'>
            <div className='relative h-full w-full flex '>
              <div className={`absolute h-full w-1/2 rounded-[10px] bg-main duration-500 ${data.preorder ? 'translate-x-full' : 'translate-x-0'}`} />
              <div 
                onClick={() => {
                  if (openTimeInfo?.isOpen) {
                    updateField('preorder', false);
                    updateErrors('')
                  }
                }} className={`w-1/2 flex items-center justify-center cursor-pointer z-[5] text-sm font-bold duration-500 ${!openTimeInfo.isOpen && 'opacity-50 !cursor-default'} ${ data.preorder ? 'text-main' : 'text-white' }`}>Ближайшее время</div>
              <div onClick={() => {
                updateField('preorder', true)
                updateErrors('')
              }} className={`w-1/2 flex items-center justify-center cursor-pointer z-[5] text-sm font-bold duration-500 ${ !data.preorder ? 'text-main' : 'text-white' }`}>Ко времени</div>
            </div>
          </div>

          {
            data.preorder &&
            <div className='flex gap-[30px]'>
              <div className={'w-full'}>
                <DateInput
                  value={data.deliveryDate}
                  onChange={(date: any) => updateField('deliveryDate', date)}
                  minDate={minDate}
                  maxDate={moment().add(1, 'months').toDate()}
                  className={''}
                />
              </div>
              
              <InputSelector
                variants={variantsTimePreorder}
                value={data.deliveryTime}
                onChange={(date: any) => updateField('deliveryTime', date)}      
              />
            </div>
          }

          {
            errors?.preorderAt && 
            <div className='text-yellow text-xs'>{ errors?.preorderAt[0] }</div>
          }
        </>
      }

      {
        branch.order.fields?.comment?.isUsed &&
        <Textarea
          maxLength={1000}
          name='comment'
          value={data.comment}
          onChange={(value) => updateField('comment', value)}
          placeholder={`Комментарии к заказу${branch.order.fields?.comment.isRequired ? '*' : ''}`}
          errors={errors}
          textareaClassName={'placeholder-gray-30'}
        />
      }
      
      {
        branch.order.fields?.personsCount?.isUsed &&
        <div className='flex gap-4'>
            <InputSelector
              className='w-full'
              placeholder={`Кол-во персон (приборы)${branch.order.fields?.personsCount.isRequired ? '*' : ''}`}
              // name='personsCount'
              variants={Array.from({length: 20}, (_, index) => index + 1).map((value: number) => ({name: `${value} ${pluralize(value, ['персона', 'персоны', 'персон'])}`, id: value}))}
              value={data.personsCount}
              onChange={(value) => {updateField('personsCount', value)}}  
              noFilter={true}
            />
        </div> 
      }
      
      {
        cart.options.paymentTypeObjects.length > 1 ?
        <div className=''>
          <div className='mb-3 text-[22px] font-bold -tracking-[.01em] text-dark dark:text-light'>Способы оплаты</div>

          <InputSelector
            variants={cart.options.paymentTypeObjects.map(o => ({id: o.value, name: o.label}))}
            value={data.paymentType}
            onChange={(value: any) => {updateField('paymentType', value)}}      
          />
        </div> : 
        <div>
          <div className='text-[22px] font-bold -tracking-[.01em] text-dark dark:text-light'>Способ оплаты <span className='text-main'> { cart.options.paymentTypeObjects.at(0)?.label } </span></div>
        </div>
      }

      {
        branch.order.fields?.changeFromBill?.isUsed && data.paymentType === 'cash' &&
        <TextInput  
          name='changeFromBill'
          value={data.changeFromBill}
          onChange={(value) => updateField('changeFromBill', value)}
          placeholderInput={`Сдачи с купюры${branch.order.fields?.changeFromBill.isRequired ? '*' : ''}`}
          type='number'
          className='w-full'
          errors={errors}
          min={0}
        />
      }

      <ChangeBranchPopup/>

      <Popup
        isActive={showChangePhonePopup}
        close={() => setShowChangePhonePopup(false)}
        closeIcon
        classNamePopup="m-0 self-center" 
        width = "475px"
      >
        <div className='text-center'>Вы точно хотите изменить ваш телефон? Согласившись, процесс оформления заказа придется начать заново.</div>
        <div className='flex w-full gap-6 mt-6'>
          <BaseButton onClick={() => setShowChangePhonePopup(false)} className={'bg-orderbtn text-main w-full !font-bold'}>Нет</BaseButton>
          <BaseButton 
            onClick={() => {
              if (!user) {
                setShowChangePhonePopup(false)
                localStorageWrap.removeItem('clientPhone')
                navigate(`/${city.slug}`)
                return
              }

              if (user?.phone_confirmed) {
                setShowChangePhonePopup(false)
                navigate(`/${city.slug}/account/profile`)
              } else {
                setShowChangePhonePopup(false)
                localStorageWrap.removeItem('clientPhone')
                navigate(`/${city.slug}/account/profile`)
              }
            }} 
            className={'bg-main text-white w-full !font-bold'}
          >
            Да
          </BaseButton>
        </div>
      </Popup>
    </div>
  )
}
