import React, { useEffect, useState } from "react";
import { useDetectorBrowser } from "../../../hooks/useDetectorBrowser";
import { useVkScroll } from "../../../hooks/useVkScroll";
import { useWindowResize } from "../../../hooks/useWindowResize";
import CloseIcon from "../../icons/CloseIcon";


let globalActivePopupCount = 0

interface Props {
  isActive: boolean;
  close: () => void;
  children: React.ReactNode;
  width?: string;
  classNamePopup?: string;
  closeIcon?: boolean;
  isMap?: boolean;
  isImagesPopup?: boolean
  isShowArrows?: boolean,
  movePopup?: boolean
}

export default function PopupActionSheet({
  isActive,
  close,
  children,
  closeIcon,
  classNamePopup,
  width = "630px",
  isMap = false,
  isImagesPopup = false,
  movePopup = false,
}: Props) {

  useEffect(() => {
    //console.log(isActive, globalActivePopupCount)
    if (isActive) {
      globalActivePopupCount++
      document.body.classList.add("overflow-y-hidden");
      return () => {
        if (--globalActivePopupCount === 0) {
          document.body.classList.remove("overflow-y-hidden")
        }
      };
    }
  }, [isActive]);

  const isSafari = useDetectorBrowser().includes('safari')

  const {vkScrollTop, vkScrollEnabled, isDesktopVk} = useVkScroll({isActive, padTop: 50, addTop: 50});

  const [closeAnimate, setCloseAnimate] = useState(false)

  // re-render on window resize
  const isMobileScreen = useWindowResize({cvt: (w: any) => w < 768})

  function handelClose() {
    if (isMobileScreen) {
      setCloseAnimate(true)
      setTimeout(() => {
        close()
        setCloseAnimate(false)
      }, 300)
    } else {
      close()
    }
  }

  return (
    <div
      className={
        `bg-fixed fixed z-[500] overflow-hidden w-full max-w-screen h-screen top-0 left-0 ` +
        (!isActive && "hidden")
      }
    >
      <div className={`relative w-full min-h-screen flex justify-center ${isDesktopVk && movePopup ? '' : 'items-end md:items-center'} md:px-4`}>
        <div
          onClick={handelClose}
          className={`${closeAnimate ? 'animate-opacityOut' : 'animate-opacityIn'} absolute w-full h-full min-h-screen top-0 left-0 bg-[#bdbdbd9e] dark:bg-[#0c0c0cc7]`}
        ></div>
        
        <div
          style={movePopup? { maxWidth: width, ...(vkScrollEnabled ? {top: vkScrollTop} : {}) }: {maxWidth: width}}
          className={`hidden md:block relative w-full h-min ${(isMap && isSafari)? 'xs:top-0 top-[-40px] ': ''}  ${isMap || isImagesPopup? '' : 'px-5 md:px-[30px] py-[25px] md:py-8'} rounded-[29px] bg-white dark:bg-gray-50 shadow-[4px_4px_20px_rgba(0,0,0,0.07)] ${classNamePopup}`}
        >
          {closeIcon && (
            <div onClick={handelClose} className={"absolute cursor-pointer z-[200]  top-[0px] flex justify-center items-center  right-[0px] rounded-full  w-[20px] h-[20px] bg-main"}>
              <CloseIcon
                colorClassName={"fill-white"}
                className={"w-[10px] h-[10px]"}
              />
            </div>
          )}
          {!isMobileScreen && children}
        </div>

        <div className={`${closeAnimate ? 'animate-closeActionSheet' : 'animate-openActionSheet'} md:hidden block fixed bottom-0 left-0 w-full`}>
          {closeIcon && (
            <div onClick={handelClose} className={"absolute cursor-pointer z-[200] top-[0px] flex justify-center items-center  right-[0px] rounded-full  w-[20px] h-[20px] bg-main"}>
              <CloseIcon
                colorClassName={"fill-white"}
                className={"w-[10px] h-[10px]"}
              />
            </div>
          )}
          <div className={`w-full ${(isMobileScreen && isMap) ? 'max-h-[90vh]': 'max-h-[80vh]'} no-scroll-bar overflow-y-auto relative rounded-t-[29px] bg-white dark:bg-gray-50 p-4 shadow-[4px_4px_20px_rgba(0,0,0,0.07)] ${classNamePopup}`}>
            {isMobileScreen && children}
          </div>
        </div>
      </div>
    </div>
  );
}
