import React, {useEffect} from "react";
import {YMaps} from "@pbe/react-yandex-maps";
import { useAppContext } from "../../contexts/App";
import VisitorContextWrapper, { NotificationBlock } from "../../contexts/VisitorContext";
import { AddressContextWrapper } from "../../contexts/AddressContext";
import { AnimationCartProductsContextWrapper } from "../../contexts/AnimationCartContext";
import NotFound from "../../pages/404";
import { AdditionalFieldsFunnelsContextWrapper } from "../../contexts/AdditionalFieldsFunnelsContext";
import { LoaderContextWrapper } from "../../contexts/LoaderContext";
import { ProductDetailCardContextWrapper } from "../../contexts/ProductDetailCardContext";

type Props = {
  children: React.ReactNode,
}

export default function MainLayout({children}: Props) {
  const {notFound} = useAppContext();

  return (
    <VisitorContextWrapper>
      <AddressContextWrapper>
        <link rel="preconnect" href="https://api-maps.yandex.ru"/>
        <link rel="preconnect" href="https://vk.com"/>
        <YMaps
          preload={false}
          query={{ apikey: process.env.REACT_APP_YMAPS_API_KEY, load: "package.full" }}>
          <AnimationCartProductsContextWrapper>
            {
              notFound ?
              <NotFound/> :
              <AdditionalFieldsFunnelsContextWrapper>
                <LoaderContextWrapper>
                  <ProductDetailCardContextWrapper>
                    {children}
                  </ProductDetailCardContextWrapper>
                </LoaderContextWrapper>
              </AdditionalFieldsFunnelsContextWrapper>
            }
          </AnimationCartProductsContextWrapper>
        </YMaps>
      </AddressContextWrapper>
    </VisitorContextWrapper>
  );
}
