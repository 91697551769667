import { useNavigate } from "react-router-dom";
import Popup from "..";
import { useAppContext } from "../../../../contexts/App";
import { BaseButton } from "../../button/BaseButton";


interface IOpenHoursPopup {
  isActive: boolean,
  close: () => void,
  openHours?: any,
}

export function OpenHoursText({openHours, prefix='Приём заказов откроется ', highlightClassName='text-main'}: any) {
  function fmtDay(t: number, todayStr: string = 'сегодня', tomorrowStr: string = 'завтра') {
    let date = new Date(t * 1000);
    let day = date.getDay();
    let today = new Date().getDay();
    if (day !== today) {
      if ((today + 1) % 7 == day) {
        return tomorrowStr;
      } else {
        return [
          "в воскресенье",
          "в понедельник",
          "во вторник",
          "в среду",
          "в четверг",
          "в пятницу",
          "в субботу",
        ][day] + ' '
      }
    } else {
      return todayStr
    }

  }

  function fmtDate(t: number, checkDate: boolean) {
    // t += 24 * 3600 * 2
    let date = new Date(t * 1000);
    let str = date.toLocaleTimeString('ru-ru', {hour: '2-digit', minute: '2-digit'})
    if (checkDate) {
      str = fmtDay(t) + ' с ' + str;
    }
    return str;
  }

  //console.log(new Date(openHours.nextOpenTimestamp * 1000), new Date(openHours.nextCloseTimestamp * 1000))
  return <>{prefix}<span className={highlightClassName}> {
    openHours.nextOpenTimestamp + 24 * 3600 <= openHours.nextCloseTimestamp ? fmtDay(openHours.nextOpenTimestamp) + ' весь день' :
    <>{fmtDate(openHours.nextOpenTimestamp, true)}</>}</span></>
}

export function OpenHoursPopup({isActive, close, openHours}: IOpenHoursPopup) {
  const {city, branch} = useAppContext()
  const navigate = useNavigate();

  return (
    <Popup
      isActive={isActive}
      close={close}
      closeIcon={true}
      width={'410px'}
      closeOnLayout={false}
      movePopup={true}
    >
      <div className={"flex flex-col gap-y-6"}>
        <p className={"text-[18px] font-bold text-dark dark:text-light"}>Здравствуйте! Сейчас мы закрыты.</p>
        <p className={"text-sm font-bold text-dark dark:text-light"}>
          <OpenHoursText prefix={<>Приём заказов откроется </>} openHours={openHours}/>
          {
            !!branch.extended.allowOrderAfterHours &&
            <div className="text-dark dark:text-light">Сейчас вы можете оформить предварительный заказ ко времени.</div>
          }
        </p>
        <div className={"flex flex-col gap-y-4"}>
          <BaseButton onClick={() => {
            navigate(`/${city.slug}/about`)
            close()
          }}  className={"bg-orderbtn text-main !font-bold h-10 hover:bg-main hover:text-white"}>График работы</BaseButton>    
          {
            !!branch.extended.allowOrderAfterHours &&
            <BaseButton onClick={close} className={"bg-orderbtn text-main !font-bold h-10 hover:bg-main hover:text-white"}>Оформить заказ ко времени</BaseButton>
          }
        </div>
      </div>
    </Popup>
  )
}
