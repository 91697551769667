import {useEffect, useRef, useState} from "react";

export default function TextEllipsis({children, style={}, _debug=false, ...props}: any) {
  const ref = useRef<HTMLDivElement | null>(null);
  const windowWidthRef = useRef(0)

  const [width, setWidth] = useState(-1);

  useEffect(() => {
    if (width >= 0) return;
    windowWidthRef.current = window.innerWidth
    const handle = setInterval(() => {
      if (ref.current) {
        const parent = ref.current?.parentElement;
        if (!parent || parent.clientWidth < 1) return;
        const style = window.getComputedStyle(ref.current)
        const width = parent.clientWidth - parseFloat(style.marginLeft) - parseFloat(style.marginRight)
        if (width != null && width > 0) {
          clearInterval(handle);
          setWidth(width);
          if (_debug) {
            //console.log("recalc ellipsis", width)
          }
        }
      } else {
      }
    }, 100)
    return () => clearInterval(handle)
  }, [width]);

  if (width >= 0) {
    style = {...style, width};
  }

  const forceRecalc = () => {
    setWidth(-Math.random());
    if (ref.current) ref.current.style.width = '0';
  }

  const onResize = () => {
    if (Math.abs(windowWidthRef.current - window.innerWidth) > 5) {
      windowWidthRef.current = window.innerWidth;
      forceRecalc()
    }
  }

  useEffect(() => {
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])

  return <div className={'w-full'}><div ref={ref} style={style} {...props}>{width >= 0 && children}</div></div>
}
