import React, { useEffect, useState, useRef } from "react";
import ArrowIcon from "../../../icons/ArrowIcon";
import mainStock from "../../../../images/mainStock.jpg";
import time from "../../../../images/time.svg";
import { BaseButton } from "../../../common/button/BaseButton";
import ProductCard from "../../mainPage/Catalog/ProductCard";
import { useAppContext } from "../../../../contexts/App";
import { useCartContext } from "../../../../contexts/CartContext";
import { apiClient } from "../../../../libs/api/apiClient";
import { findDiffDate } from "../../../../libs/helpers/findDiffDate";
import CloseIcon from "../../../icons/CloseIcon";
import { Preloader } from "../../../common/preloader";

interface Props {
  close: () => void;
  stockId: any;
  stocks: any[];
  isActive?: boolean;
}
interface Button {
  url: string;
  title: string;
}

export default function DetailStock({ stockId, stocks, close, isActive }: Props) {
  const { branch, address } = useAppContext();
  const [stock, setStock] = useState<any>(null);
  const [timeToEnd, setTimeToEnd] = useState<any>(null);
  const [bgColor, setBgColor] = useState("main");
  const refDescriptionDesc = useRef<any>(null)
  const refDescriptionMob = useRef<any>(null)
  const { cart, loading, updateCart } = useCartContext()
  const { allProducts } = useAppContext()

  useEffect(() => {
    console.log(address)
  }, [address])
  
  useEffect(() => {
    setStock(null);
    let candidatStock = stocks.filter((s) => s.id == stockId)[0];
    if (candidatStock) {
      setStock(candidatStock);
    } else {
      apiClient.stocks
        .getStock(branch.id, stockId)
        .then((data) => {
          if (data.status !== 200) {
            close();
            return
          }

          setStock(data);
        });
    }
  }, [stockId]);

  useEffect(() => {
    if (stock) {
      if (refDescriptionDesc.current) refDescriptionDesc.current.innerHTML = stock.description
      if (refDescriptionMob.current) refDescriptionMob.current.innerHTML = stock.description
      setTimeToEnd(findDiffDate(stock.endAt));
      setBgColor(
        findDiffDate(stock.endAt).days || findDiffDate(stock.endAt).hours > 2
          ? "additional"
          : "main"
      );
    } else {
      setTimeToEnd("");
      setBgColor("main");
    }
  }, [stock]);

  useEffect(() => {
    if (isActive) {
      document.body.classList.add("overflow-y-hidden");
    } else {
      document.body.classList.remove("overflow-y-hidden");
    }
  }, [isActive]);

  return (
    <>
      {/* Декстоп */}
      <div
        className={
          "fixed z-[110] overflow-y-auto w-full max-w-screen h-screen top-0 left-0 hidden sm:block " +
          (!stockId && "hidden")
        }
      >
        <div className={`relative h-full w-full flex justify-center items-center p-4 `}>
          <div
            onClick={close}
            className="h-full absolute w-full min-h-screen top-0 left-0 bg-[#bdbdbd9e] dark:bg-[#0c0c0cc7]"
          ></div>
          <div className={"relative"}>
            <div onClick={close} className={"absolute z-10 cursor-pointer top-[0px] flex justify-center items-center  right-[0px] rounded-full  w-[20px] h-[20px] bg-main"}>
              <CloseIcon
                colorClassName={"fill-white"}
                className={
                  "w-[10px] h-[10px] "
                }
              />
            </div>
            <div
              className={`relative z-0 md+:w-[765px] overflow-x-hidden xl:w-[765px] w-[650px] md+p-[30px] p-[20px] rounded-[29px] bg-white dark:bg-gray-50 shadow-[4px_4px_20px_rgba(0,0,0,0.07)]`}>
              {stock ? (
                <div className='grow pretty-scroll mr-[-18px] pr-5 overflow-y-auto overflow-y-scroll xl:h-[min(90vh,650px)] h-[min(90vh,550px)]'>
                  <div className="flex gap-[20px] md+:gap-[45px]">
                    <div className="grow flex flex-col">
                      <div className="mb-[30px] relative">
                        {stock.endAt &&
                          <div
                          className={`absolute bg-${bgColor} w-max rounded-2xl text-xs px-[13px] top-[15px] left-[15px] text-white py-[6px]`}
                        >
                        <span className="flex items-center">
                          До конца действия:
                          <img className="mx-[6px]" src={time}/>
                          <span>
                            {timeToEnd.days
                              ? `${timeToEnd.days} д ${timeToEnd.hours} ч`
                              : null}
                            {!timeToEnd.days && timeToEnd.hours
                              ? `${timeToEnd.hours} ч`
                              : null}
                            {!timeToEnd.days &&
                            !timeToEnd.hours &&
                            timeToEnd.mins
                              ? `${timeToEnd.mins} мин`
                              : null}
                            {!timeToEnd.days &&
                            !timeToEnd.hours &&
                            !timeToEnd.mins &&
                            timeToEnd
                              ? `Меньше минуты`
                              : null}
                          </span>
                        </span>
                        </div>
                        }
                        
                        {stock.image ? (
                          <img className="w-full rounded-2xl" src={stock.image}/>
                        ) : (
                          <img
                            className="w-full rounded-2xl"
                            src={mainStock}
                            alt=""
                          />
                        )}
                      </div>

                      <div>
                        <div className="items-center text-gray-50 dark:text-gray-10 ">
                          <div className="font-bold text-[22px] mb-[20px] text-gray-60 dark:text-white ">
                            {stock.title}
                          </div>
                          <div className="whitespace-pre-wrap [&_a]:text-main [&_ul]:list-disc [&_li]:ml-5 [&_ol]:list-decimal" ref={refDescriptionDesc}></div>
                          <div className={`mt-[30px] ${!stock.goods.length && 'mb-[60px]'} `}>
                            {stock.promoCode && branch.order.fields.promoCode?.isUsed &&
                              <div className="absolute bottom-0 z-[20] bg-white dark:bg-gray-50 w-full flex justify-center pr-[8%] py-3">
                                {
                                  stock.promoCode.toLowerCase() !== cart.cartPromoCode.promoCode?.code?.toLowerCase() ?
                                  <BaseButton onClick={() => !loading && updateCart({promoCode: stock.promoCode, raw: cart.raw})} className="border-solid hover:bg-main hover:text-white hover:opacity-100 border border-main font-bold text-base text-main ">
                                    {loading?
                                    <Preloader countOfDot={4} size={'8px'} color={'white'} className='gap-2 h-[20px] flex flex-row items-center'/>
                                    :
                                    <>
                                      <span>Промокод:</span>

                                      <div className={'ml-[3px] lines-ellipsis'}>  
                                        {stock.promoCode}
                                      </div>
                                    </>
                                    }
                                   
                                  </BaseButton> :
                                  <BaseButton className="border-solid border opacity-50  border-main font-bold text-base text-main pointer-events-none">
                                    <span>Промокод применён:</span>

                                    <div className={'ml-[3px] lines-ellipsis'}>  
                                      {stock.promoCode}
                                    </div>
                                  </BaseButton>
                                }   
                              </div>
                            }

                            <div className="flex gap-[30px] flex-wrap ">
                              {stock.buttons.map(
                                (button: Button, index: number) => (
                                  <BaseButton
                                    key={index}
                                    className="bg-orderbtn  font-bold text-base text-main hover:bg-main hover:text-white w-[220px] "
                                  >
                                    <a href={button.url} onClick={close}>{button.title}</a>
                                  </BaseButton>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {stock.goods.length?
                    <div className="mt-4 mb-[70px]">
                      <p className="font-bold dark:text-white">Товары, участвующие в акции</p>
                      <div className={`grid grid-cols-1 xs:grid-cols-2  mt-2 gap-0 xs:gap-5 md:gap-[15px] px-2`}>
                        {stock.goods.map((good: any) => 
                         <ProductCard key={good} productId={good} emptyImageClassName={'md+:h-[322.16px] xs:h-[265.17px]'}/>
                        )}
                      </div>
                    </div>
                    :
                    <span></span>
                  }
                  
                </div>
              ) : (
                <div className='flex items-center justify-center h-full bg-white dark:bg-dark'><Preloader size='10px'/></div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Мобилка */}
      <div className={`fixed z-[110] w-screen h-screen top-0 left-0 sm:hidden block ${stock?.promoCode && 'pb-[74px]'} ${!stockId && 'hidden'}`}>
        <div
          className={"w-full h-full bg-gray-10 dark:bg-gray-50 flex flex-col"}
        >
          {stock ? (
            <>
              <div className="bg-white dark:bg-gray-60 flex items-center text-gray-50 dark:text-gray-10 p-[14px] shadow-[0px_4px_40px_rgba(0,0,0,0.04)]">
                <div onClick={close} className="cursor-pointer">
                  <div className="rotate-180">
                    <ArrowIcon className="w-[8px] h-[14px]" />
                  </div>
                </div>

                <div className="grow ml-[18px]">
                  <div className="font-bold text-[18px]">{stock.title}</div>
                </div>
                <div className={`w-[120px]`}></div>
              </div>

              <div className="p-[18px] overflow-y-auto">
                <div className="mb-[25px] relative">
                  {stock.endAt?
                    <div
                      className={`absolute bg-${bgColor} w-max rounded-2xl text-xs px-[13px] top-[15px] left-[15px] text-white py-[6px]`}
                    >
                    <span className="flex items-center">
                      До конца действия:
                      <img className="mx-[6px]" src={time} />
                      <span>
                        {timeToEnd.days
                          ? `${timeToEnd.days} д ${timeToEnd.hours} ч`
                          : null}
                        {!timeToEnd.days && timeToEnd.hours
                          ? `${timeToEnd.hours} ч`
                          : null}
                        {!timeToEnd.days && !timeToEnd.hours && timeToEnd.mins
                          ? `${timeToEnd.mins} мин`
                          : null}
                        {!timeToEnd.days &&
                        !timeToEnd.hours &&
                        !timeToEnd.mins &&
                        timeToEnd
                          ? `Меньше минуты`
                          : null}
                        </span>
                      </span>
                    </div>
                    :
                    null
                  }
                  
                  {stock.image ? (
                    <img className="w-full rounded-2xl" src={stock.image} />
                  ) : (
                    <img
                      className="w-full rounded-2xl"
                      src={mainStock}
                      alt=""
                    />
                  )}
                </div>
                <div className="items-center text-gray-50 dark:text-gray-10 ">
                  <div className="font-bold text-[22px] mb-[20px] text-gray-60 dark:text-white">
                    {stock.title}
                  </div>
                  <div className="whitespace-pre-wrap [&_a]:text-main [&_ul]:list-disc [&_li]:ml-5 [&_ol]:list-decimal" ref={refDescriptionMob}></div>
                  <div className="mt-[30px] ">

                    {stock.promoCode && 
                      <div className="fixed left-0 bottom-0 z-[20] bg-white dark:bg-dark flex justify-center w-full p-3 pb-6">
                        {
                          stock.promoCode.toLowerCase() !== cart.cartPromoCode.promoCode?.code?.toLowerCase() ?
                          <BaseButton onClick={() => !loading && updateCart({promoCode: stock.promoCode, raw: cart.raw})} className="border-solid border active:bg-main active:text-white border-main font-bold text-base text-main w-[100%]">
                            {loading?
                              <Preloader countOfDot={4} size={'8px'} className='gap-2 h-[20px] flex flex-row items-center'/>
                              :
                              <>
                                <span>Промокод:</span>

                                <div className={'ml-[3px] lines-ellipsis'}>  
                                  {stock.promoCode}
                                </div>
                              </>
                            }
                          </BaseButton> :
                          <BaseButton className="border-solid border opacity-50  border-main font-bold text-base text-main w-[100%] pointer-events-none">
                            <span>Промокод применён:</span>

                            <div className={'ml-[3px] lines-ellipsis'}>  
                              {stock.promoCode}
                            </div>
                          </BaseButton>
                        }   
                      </div>
                    }


                    <div className="flex flex-col gap-[20px] flex-wrap ">
                      {stock.buttons.map((button: Button, index: number) => (
                        <BaseButton
                          key={index}
                          className="bg-orderbtn font-bold text-base text-main w-[100%] "
                        >
                          <a href={button.url} onClick={close}>{button.title}</a>
                        </BaseButton>
                      ))}
                    </div>
                  </div>
                </div>
                {stock.goods.length?
                  <div className="mt-5 mb-[70px]">
                    <p className="font-bold dark:text-white">Товары, участвующие в акции</p>
                    {
                      (address?.point === null || !address) &&
                      <p className="text-[14px] font-bold text-yellow">Укажите адрес для добавления товаров в корзину</p>
                    }
                    <div className={`grid grid-cols-1  mt-3`}>
                      {stock.goods.map((good: any, index: number) =>
                        <ProductCard key={good} productId={good} isLastProduct={stock.goods.length - 1 == index}/>
                      )}
                    </div>
                  </div>
                  :
                  <span></span>
                }
              </div>
            </>
          ) : (
            <div className='flex items-center justify-center h-full bg-white dark:bg-dark'><Preloader size='10px'/></div>
          )}
        </div>
      </div>
    </>
  );
}
