import {className} from "postcss-selector-parser";
import { useEffect, useRef } from "react";

interface IGlobalMessage {
  children: any,
  className?: string,
  style?: any;
  setHeight: (value: number) => void;
}

export default function GlobalMessage({children, className, style, setHeight}:IGlobalMessage) {
  const wrapper = useRef<any>(null)

  useEffect(() => {
    if (wrapper?.current?.clientHeight && setHeight) {
      setHeight(wrapper.current.clientHeight)
    }
  }, [wrapper?.current?.clientHeight])

  return (
    <div ref={wrapper} id="global-message" style={style} className={`min-h-[60px] xs:min-h-[50px] xs:w-full bg-main flex flex-row shadow-[0px_4px_22px_-6px_rgba(6,5,50,0.15)] gap-x-6 justify-between items-center px-[18px] xs:justify-center ${className}`}>
      {children}
    </div>
  )
}
