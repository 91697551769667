import { useLoaderStateContext } from "../../contexts/LoaderContext";
import { Preloader } from "../common/preloader";

export default function Loader() {
  const loader = useLoaderStateContext();
  return (
    <>
      {loader && (
        <div className={"fixed z-[100000] w-full top-0 flex h-screen justify-center items-center bg-white dark:bg-dark"}>
          <Preloader size='10px'/>
        </div>
      )}
    </>
  );
}