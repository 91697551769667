import React, { useEffect, useState } from 'react'
import CloseIcon from './../../../icons/CloseIcon';
import { useCartContext } from '../../../../contexts/CartContext';

export default function Promo({ className='' }) {
  const { cart, updateCart } = useCartContext()
  const [showTooltip, setShowTooltip] = useState(false)
  const [showNotFoundTooltip, setShowNotFoundTooltip] = useState(false)

  const [promoCodeInput, setPromoCodeInput] = useState(cart.cartPromoCode?.promoCode?.code || '')

  useEffect(() => {
    setPromoCodeInput(cart.cartPromoCode.promoCode?.code || '')
  }, [cart.cartPromoCode?.promoCode?.code])

  useEffect(() => {
    if (promoCodeInput.length > 0) {
      setShowTooltip(true)
    }
  },[cart.cartPromoCode?.activated, promoCodeInput.length > 0])

  function checkPromo() {
    if (promoCodeInput) {
      updateCart({promoCode: promoCodeInput})
      setShowTooltip(true)
    }
  }

  useEffect(() => {
    if (cart.cartPromoCode?.reason && !cart.cartPromoCode.activated && !cart.cartPromoCode.promoCode) {
      setShowNotFoundTooltip(true)
    }
  }, [cart.cartPromoCode])

  function clearPromoCode() {
    updateCart({promoCode: ''})
  }


  return (
    <div className={`relative ${className}`}>
      <div className={`font-bold text-xl mb-[10px] text-dark dark:text-light`}>Промокод</div>
      <div 
        onClick={() => {!!cart.cartPromoCode?.promoCode && setShowTooltip(!showTooltip)}}
        className={`flex h-[35px] w-full rounded-[10px] bg-gray-20 dark:bg-gray-40 ${cart.options.isPromoCodesDisabled && 'opacity-50 pointer-events-none'}`}
      >
        <input 
          type="text" 
          className={`min-w-0 h-full grow bg-transparent outline-none px-[26px] text-sm ${cart.cartPromoCode?.promoCode ? (cart.cartPromoCode?.activated ? 'text-main ' : 'text-yellow') : 'text-gray-40 dark:text-gray-10'}`}
          placeholder='Промокод'
          onChange={(e) => {setPromoCodeInput(e.target.value); setShowTooltip(false); setShowNotFoundTooltip(false)}}
          value={promoCodeInput}
          disabled={!!cart.cartPromoCode?.promoCode}
          onBlur={checkPromo}
        />

        <div onClick={(e) => {e.stopPropagation(); cart.cartPromoCode?.promoCode && clearPromoCode()}} className={`${!promoCodeInput && 'opacity-50 pointer-events-none'} flex items-center justify-center bg-orderbtn hover:bg-main hover:text-white w-[138px] h-full rounded-[10px] text-sm md:text-base text-main text-sm font-bold cursor-pointer`}>{!!cart.cartPromoCode?.promoCode ? 'Сбросить' : 'Применить'}</div>
      </div>
      
      {cart.options.isPromoCodesDisabled && cart.options.disabledPromoCodesComment && <div className='text-xs text-yellow mt-5'>{cart.options.disabledPromoCodesComment}</div>}
      
      {
        (cart.cartPromoCode?.promoCode && showTooltip) &&
        <div className={`absolute z-[20] w-full px-5 pb-5 pt-3 rounded-[20px] mt-[15px] shadow-2xl text-white bg-gray-50`}>
          <div className={`absolute border-[20px] border-transparent top-[-32px] border-b-gray-50`}/>
          <div className='flex justify-between pb-3 border-b-[1px] border-white/20 mb-2'>
            <div className={`text-xs ${cart.cartPromoCode.activated? 'text-green-400 font-bold' : 'text-orange-400 font-bold'}`}>{cart.cartPromoCode.activated ? 'Промокод активирован' : "Промокод неактивирован"}</div>
            <div><CloseIcon onClick={() => setShowTooltip(false)} className='w-[10px] h-[10px] cursor-pointer' /></div>
          </div>

          {
            cart.cartPromoCode.promoCode &&
            <div className={`mb-3 ${!cart.cartPromoCode.activated && ' pb-3 border-b-[1px] border-white/20'}`}>
              <div className={`font-bold text-lg`}>{ cart.cartPromoCode.promoCode?.title }</div>
              {cart.cartPromoCode.promoCode?.shortDescription && <div className={`text-xs ${!cart.cartPromoCode.activated && 'text-white'}`}>{ cart.cartPromoCode.promoCode?.shortDescription }</div>}
            </div>
          }

          {
            cart.cartPromoCode?.reason && !cart.cartPromoCode.activated &&
            <div className='text-xs'>{ cart.cartPromoCode?.reason }</div>
          }
        </div>
      }

      {
       showNotFoundTooltip &&
       <div className={`absolute z-[20] w-full px-5 pb-3 pt-3 rounded-[20px] mt-[15px] text-white bg-gray-50`}>
         <div className={`absolute border-[20px] border-transparent top-[-32px] border-b-gray-50`}/>
         <div className='text-sm font-bold text-gray-30 dark:text-gray-10'>Промокод не найден</div>
       </div>
      }
    </div>
  )
}
