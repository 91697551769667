import React from "react";

export default function GearIcon({className = 'stroke-white', width = 18, height = 18}: {
  className?: string;
  width?: number;
  height?: number;
}) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" className={className}>
      <path fillRule="evenodd" clipRule="evenodd" d="M21.3531 6.59995L12 1.19995L2.64697 6.59995V17.4L12 22.7999L21.3531 17.4V6.59995ZM12 15.6C13.9883 15.6 15.6 13.9882 15.6 12C15.6 10.0117 13.9883 8.39995 12 8.39995C10.0118 8.39995 8.40005 10.0117 8.40005 12C8.40005 13.9882 10.0118 15.6 12 15.6Z" stroke="inherit" strokeWidth="2" strokeLinejoin="round"/>
    </svg>
  );
}
