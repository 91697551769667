import { ISaveDeliveryAddressRequest } from "../../types/address";
import { apiRequest } from "./apiRequest"

const encodeURIParams = (p: object) => {
  return Object.entries(p).map(([k, v]) => {
    if (Array.isArray(v)) {
      return v.map((el, i) => {
        return `${encodeURIComponent(k)}[${i}]=${encodeURIComponent(el)}`
      }).join('&')
    } else {
      return [k, v].map(encodeURIComponent).join('=')
    }
  }).join("&");
}

let vkMiniAppDomain = ""

export function setVkMiniAppDomain(vkAppId: number | undefined, vkGroupId: number | undefined) {
  vkMiniAppDomain = `vk-${vkAppId || 0}-${vkGroupId || 0}`;
  console.log('set vk mini app domain', vkMiniAppDomain)
}

export function getCurrentDomain(domain?: string) {
  if (process.env.REACT_APP_DEBUG_FORCE_DOMAIN)
    return process.env.REACT_APP_DEBUG_FORCE_DOMAIN
  
  if (vkMiniAppDomain === "") {
    if (!domain) throw new Error("domain was not passed somewhere before setVkMiniAppDomain")
    return domain as string;
  } else {
    return vkMiniAppDomain;
  }
  
}

export const apiClient = {
  auth: {
    login: (data: any) => apiRequest('/api/auth/login', {method: "POST", ...data}),
    loginPhone: (branchId: string | number, data: {phone:string}) => apiRequest(`/api/auth/branch/${branchId}/login/phone`, {method: "POST", body: data, headers: {domain: getCurrentDomain()}}),
    loginTelegram: (branchId: string | number, data: {phone:string}) => apiRequest(`/api/auth/branch/${branchId}/login/tg`, {method: "POST", body: data, headers: {domain: getCurrentDomain()}}),
    loginVk: (branchId: string | number, referer: string) => apiRequest(`/api/auth/branch/${branchId}/login/vk?referer=${encodeURIComponent(referer)}`, {method: "GET", headers: {domain: getCurrentDomain()}}),
    confirmPhoneViaTelegram: (branchId: string | number, data: { phone: string }) => apiRequest(`/api/auth/branch/${branchId}/phone/confirm/tg`, { method: "POST", body: data, headers: { domain: getCurrentDomain() } }), 
    loginVkMiniApp: (data: any) => apiRequest(`/api/auth/vk-mini-app`, {method: "POST",body: data, headers: {domain: getCurrentDomain()}, anonymousRequest: true}),
    authVkCheck: (tokenVk: any) => apiRequest(`/api/auth/vk-auth?state=${tokenVk}`),
    codeCheck: (data: {code:string, hash?:string}) => apiRequest('/api/auth/code-check', {method: "POST", body: data, headers: {domain: getCurrentDomain()}}),
    phoneCheck: (data: {phone:string}) => apiRequest('/api/auth/phone-check', {method: "POST", body: data, headers: {domain: getCurrentDomain()}}),
    retryAfter: (branchId: string | number) => apiRequest(`/api/auth/branch/${branchId}/login/next-retry-time`,  {headers: {domain: getCurrentDomain()}}),
    logout: () => apiRequest('/api/auth/logout', {method: "POST",headers: {domain: getCurrentDomain()}}),
  },
  profileAddress: {
    addresses: (branchId: number) => apiRequest(`/api/me/branch/${branchId}/addresses`, {headers: {domain: getCurrentDomain()}}),
    addAddress: (data: any) => apiRequest('/api/me/addresses', {method: "POST", body: data, headers: {domain: getCurrentDomain()}}),
    editAddress: (data: any, addressId: string) => apiRequest(`/api/me/addresses/${addressId}`, {method: "PATCH", body: data, headers: {domain: getCurrentDomain()}}),
    removeAddress: (customerAddressId: number | string) => apiRequest(`/api/me/addresses/${customerAddressId}`, {method: 'DELETE', headers: {domain: getCurrentDomain()}}),
  },
  profileInfo: {
    infoProfile: (data: any) => apiRequest('/api/me', {method: "PATCH", body:data, headers: {domain: getCurrentDomain()}}),
    user: () => apiRequest('/api/auth/user', {headers: {domain: getCurrentDomain()}}),
    unlinkSocial: (data: any) => apiRequest('/api/me/social-unlink', {method: "POST", body: data, headers: {domain: getCurrentDomain()}}),
    allowMessagesFromGroup: (data: {group_id: number}) => apiRequest('/api/me/vk/allowMessagesFromGroup', {method: "POST", body: data, headers: {domain: getCurrentDomain()}}),
    isMessagesFromGroupAllowed: (data: {group_id: number, user_id: number}) => apiRequest('/api/me/vk/isMessagesFromGroupAllowed', {method: "POST", body: data, headers: {domain: getCurrentDomain()}}),
    getBonusStatus: () => apiRequest('/api/me/bonus-program/status', {headers: {domain: getCurrentDomain()}}),
  },
  profileOrders: {
    getOrders: (limit: number, page: number) => apiRequest(`/api/me/orders?order[createdAt]=desc&limit=${limit}&page=${page}`, {headers: {domain: getCurrentDomain()}}),
    getOrder: (orderId: number) => apiRequest(`/api/me/orders/${orderId}`, {headers: {domain: getCurrentDomain()}}),
    getQuestionnaire: (orderQuestionnaireId: any) => apiRequest(`/api/questionnaire/${orderQuestionnaireId}`, {headers: {domain: getCurrentDomain()}}),
    addOrderReview: (orderQuestionnaireId: any, data: any) => apiRequest(`/api/questionnaire/${orderQuestionnaireId}/order-review`, {method: "POST",  body: data, headers: {domain: getCurrentDomain()}}),
    addGoodsReview: (orderQuestionnaireId: any, data: any) => apiRequest(`/api/questionnaire/${orderQuestionnaireId}/goods-review`, {method: "POST",  body: data, headers: {domain: getCurrentDomain()}}),
    addAnswers: (orderQuestionnaireId: any, data: any) => apiRequest(`/api/questionnaire/${orderQuestionnaireId}/answers`, {method: "POST",  body: data, headers: {domain: getCurrentDomain()}}),
  },
  profileNotifications: {
    getNotifications: (limit: number, page: number) => apiRequest(`/api/me/notifications?limit=${limit}&page=${page}`, {headers: {domain: getCurrentDomain()}}),
    readNotifications: (data: any) => apiRequest(`/api/me/notifications/bulk/read`, {method: "POST",  body: data, headers: {domain: getCurrentDomain()}}),
    getLatestNotification: () => apiRequest('/api/me/notifications/latest', {headers: {domain: getCurrentDomain()}}),
  },
  pushNotifications: {
    addFCM: (data: {token: string, userAgent: string}) => apiRequest(`/api/me/fcm`, {method: "POST", body: data, headers: {domain: getCurrentDomain()}}),
    deleteFMC: (data: {userAgent: string}) => apiRequest(`/api/me/fcm`, {method: "DELETE", body: data, headers: {domain: getCurrentDomain()}})
  },
  stories: {
    getStories: (branchId: string, domain?: string) => apiRequest(`/api/branch/${branchId}/histories`, {headers: {domain: getCurrentDomain(domain)}}),
  },
  company: {
    get: (domain?: string) => apiRequest('/api/company-info', {headers: {domain: getCurrentDomain(domain)}}),
    base: (query: {slug?: string, branch?: string}) => apiRequest(`/api/companies/${getCurrentDomain()}/base`, {query}),
  },
  branch: {
    get: (branchId: any, domain?: string) => apiRequest(`/api/branch/${branchId}`, {headers: {domain: getCurrentDomain(domain)}}),
    getTime: (branchId: number | string) => apiRequest(`/api/branch/${branchId}/open-now-info`, {headers: {domain: getCurrentDomain()}})
  },
  catalog: {
    get: (branchId: any, query: any = {}) => apiRequest(`/api/v2/branch/${branchId}/catalog?` + encodeURIParams(query) , {headers: {domain: getCurrentDomain()}}),
    getAllProducts: (branchId: any, domain?: string) => apiRequest(`/api/branch/${branchId}/products`, {headers: {domain: getCurrentDomain(domain)}}),
    getProduct: (branchId: any, productId: any) => apiRequest(`/api/v2/branch/${branchId}/products/${productId}`, {headers: {domain: getCurrentDomain()}}),
    getPopular: (branchId: any) => apiRequest(`/api/branch/${branchId}/catalog/popular`, {headers: {domain: getCurrentDomain()}})
  },
  about: {
    get: (domain?: string) => apiRequest('/api/about-info', {headers: {domain: getCurrentDomain(domain)}})
  },
  stocks: {
    get: (branchId: number | string, domain?: string) => apiRequest(`/api/branch/${branchId}/stocks`, {headers: {domain: getCurrentDomain(domain)}}),
    getStock: (branchId: number, id: string|number) => apiRequest(`/api/branch/${branchId}/stocks/${id}`, {headers: {domain: getCurrentDomain()}})
  },
  order: {
    //get: (domain?: string, orderId: string) => apiRequest(`/api/me/orders/${orderId}`, {headers: {domain: getCurrentDomain(domain)}}),
    getByHash: (branchId: number, hash: string) => apiRequest(`/api/branch/${branchId}/orders/${hash}`, {headers: {domain: getCurrentDomain()}}),
    getCart: (orderId: number) => apiRequest(`/api/me/orders/${orderId}/cart`, {headers: {domain: getCurrentDomain()}}),
    // validatePromo: (host: string, branchId: number, data: {body: any}) =>  apiRequest(`/api/branch/${branchId}/promo-codes/validate`, {method: "POST", headers: {domain: host}, ...data}),
    // validateCart: (host: string, branchId: number, data: {body: any}) =>  apiRequest(`/api/branch/${branchId}/cart/validate`, {method: "POST", headers: {domain: host}, ...data}),
    create: (branchId: number, data: {body: any}) => apiRequest(`/api/branch/${branchId}/orders`, {method: "POST", headers: {domain: getCurrentDomain()}, ...data})
  },
  cart: {
    get: (branchId: number, cartId: any) => apiRequest(`/api/branch/${branchId}/cart/${cartId}`, {headers: {domain: getCurrentDomain()}}),
    add: (branchId: number, data: any) => apiRequest(`/api/branch/${branchId}/cart/add/product`, {method: "POST",  body: data, headers: {domain: getCurrentDomain()}}),
    updateProduct: (branchId: number, productUid: string, data: any) => apiRequest(`/api/branch/${branchId}/cart/product/${productUid}`, {method: "PATCH",  body: data, headers: {domain: getCurrentDomain()}}),
    update: (branchId: number, data: any) => apiRequest(`/api/branch/${branchId}/cart`, {method: "PATCH",  body: data, headers: {domain: getCurrentDomain()}}),
    willBeDeleted: (branchId: number, cartId: any) => apiRequest(`/api/branch/${branchId}/cart/${cartId}/will-be-deleted`, {method: "GET", headers: {domain: getCurrentDomain()}}),
    clear: (branchId: number, cartId: any) => apiRequest(`/api/branch/${branchId}/cart/${cartId}/clear`, {headers: {domain: getCurrentDomain()}}),
  },
  delivery: {
    get: (branchId: any, domain?: string) => apiRequest(`/api/v2/branch/${branchId}/delivery-info`, {headers: {domain: getCurrentDomain(domain)}}),
    getDeliveryZoneByBranchAndPosition: (branchId: any, lat:number, lon: number) => apiRequest(`/api/branch/${branchId}/delivery-zones/${lat}:${lon}`, {headers: {domain: getCurrentDomain()}}),
    getDeliveryZonesByPosition: (lat:number, lon: number) => apiRequest(`/api/delivery-zones/${lat}:${lon}`, {method: "GET", headers: {domain: getCurrentDomain()}}),
    getById: (branchId: any, deliveryZoneId: number) => apiRequest(`/api/branch/${branchId}/delivery-zones/${deliveryZoneId}`, {headers: {domain: getCurrentDomain()}}),
    getCities: (companyId: any) => apiRequest(`/api/companies/${companyId}/cities`, {headers: {domain: getCurrentDomain()}}),

    saveDeliveryAddress: (branchId: any, data: ISaveDeliveryAddressRequest) => apiRequest(`/api/branch/${branchId}/cart/deliveryAddress`, {method: "PATCH", headers: {domain: getCurrentDomain()}, body: data})
  },
  suggestions: {
    getCityByName: (query: string, options: any = {}) => apiRequest('/api/suggestions/addresses/city?query=' + encodeURI(query), {...options, headers: {domain: getCurrentDomain()}}),
    getAddressSuggestions: (query: string, guids: string[], lat: any, lon: any, options: any = {}) => {
      let coords = ''
      if (lat && lon) {
        coords = encodeURI(`lat=${lat}&lon=${lon}`)
      }
      // if (guids?.length === 1)
      //   return apiRequest('/api/suggestions/addresses/street-to-house?query=' + [encodeURI(query), `fiasId=${encodeURI(guids[0])}`].join('&'), options).then(({data}) => data)
      return apiRequest('/api/suggestions/addresses/city-to-house?query=' + [encodeURI(query), ...guids.map((s, i) => `guids[${i}]=${encodeURI(s)}`), coords].join('&'), {...options, headers: {domain: getCurrentDomain()}})
    },
    getAddressByCoords: (lat: number, lon: number, radius: number) => apiRequest(`/api/suggestions/addresses/address-by-coordinates?lat=${lat}&lon=${lon}&radius=${radius}`, {headers: {domain: getCurrentDomain()}}),
    getCoordsByAddress: (query: string) => apiRequest(`/api/suggestions/addresses/coordinates-by-address?query=${encodeURI(query)}`, { headers: { domain: getCurrentDomain() } })
  },
  reviews: {
    getReviews: (branchId: any, limit: any, page: any, domain?: string) => apiRequest(`/api/branch/${branchId}/reviews?limit=${limit}&page=${page}&order[id]=desc`, {headers: {domain: getCurrentDomain(domain)}})
  },
  visitor: {
    event: (body: any) => apiRequest(`/api/webhook/event`, {method: "POST", headers: {domain: getCurrentDomain()}, body: body}),
    getActions: () => apiRequest(`/api/visitor/actions`, {headers: {domain: getCurrentDomain()}})
  }
}
