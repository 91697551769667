import React, { useState, useRef, useEffect } from 'react'
import IIcon from '../../../../icons/i';


export default function Tooltip({ product }: any) {
  const [show, setShow] = useState(false)
  const wrapper: any = useRef(null);

  useEffect(() => {
    function handleClick(event: any) {
      if (wrapper.current && !wrapper.current.contains(event.target)) {
        setShow(false);
      } else {
        setShow(true)
      }
    }

    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }

  }, [show, setShow, wrapper]); 

  return (
    <>
      <div className={'hidden md:block'}>
        {
          Object.values(product.foodValuePerHundredGram).some(elem => !!elem) && 
          <div className='relative group'>
            <IIcon className={"w-[19px] h-[19px] cursor-pointer"} color={'text-gray-40 dark:text-gray-30'}/>
            <Modal product={product}/>
          </div>
        }
      </div>
      <div className='md:hidden block ' ref={wrapper}>
        {
          Object.values(product.foodValuePerHundredGram).some(elem => !!elem) && 
          <div className={'relative'}>
            <IIcon className={"w-[19px] h-[19px] cursor-pointer"} color={'text-gray-40 dark:text-gray-30'}/>
            <Modal product={product} show={show} setShow={setShow}/>
          </div>
        }
      </div>
    </>
  )
}

function Modal({ product, show }: any) {
  return (
    <div className={`absolute z-[200] top-6 -right-2 md:right-[-25px] lg:right-[-50px] scale-0 md:group-hover:scale-100 duration-200 p-4 w-[230px] bg-gray-20 dark:bg-gray-40 rounded-[18px] text-dark dark:text-gray-10 text-xs font-semibold shadow-md ${show && 'scale-100'}`}>
      <div className='opacity-50 mb-2'>Пищевая ценность на 100 г</div>
      <div className='flex justify-between mb-[6px]'>
        <div>Энерг. ценность</div>
        <div>{ product.foodValuePerHundredGram.energyValue ? `${product.foodValuePerHundredGram.energyValue} кКал` : '-' }</div>
      </div>

      <div className='flex justify-between mb-[6px]'>
        <div>Белки</div>
        <div>{ product.foodValuePerHundredGram.protein ? `${product.foodValuePerHundredGram.protein} г` : '-' }</div>
      </div>

      <div className='flex justify-between mb-[6px]'>
        <div>Жиры</div>
        <div>{ product.foodValuePerHundredGram.fat ? `${product.foodValuePerHundredGram.fat} г` : '-' }</div>
      </div>

      <div className='flex justify-between'>
        <div>Углеводы</div>
        <div>{ product.foodValuePerHundredGram.carbohydrate ? `${product.foodValuePerHundredGram.carbohydrate} г` : '-' }</div>
      </div>
    </div>
  )
}
