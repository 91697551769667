
import { useEffect, useRef, useState } from "react";
import { Map, Polygon } from "@pbe/react-yandex-maps";
import { useAppContext } from "../../contexts/App";
import { apiClient } from "../../libs/api/apiClient";
import { sendEvent, useVisitorContext } from "../../contexts/VisitorContext";
import { Preloader } from "../../components/common/preloader";
import DeliveryMapIcon from "../../components/icons/DeliveryMapIcon";
import ArrowIcon from "../../components/icons/ArrowIcon";
import { formatCurrency } from "../../libs/helpers/formatCurrency";
import { animateScroll } from "react-scroll";

interface IDeliveryZones {
  id: number;
  title: string;
  color: string;
  description: string | null;
  deliveryFromTotal: string | null;
  freeDeliveryFromTotal: string | null;
  deliveryPrice: string | null;
  deliveryMaxTime: string | null;
  deliveryMinTime: string | null;
  deliveryArticle: string | null;
  preorderOnDeliveryMinTime: string | null;
  points: any;
}

interface ICompanyRequisites {
  id: number;
  companyName: string;
  companyNamePlural: string | null;
  inn: string | null;
  ogrn: string | null;
  address: string | null;
  bank: string | null;
  kpp: string | null;
  bik: string | null;
  phone: string | null;
  email: string | null;
  hideDetails: boolean;
}

interface IDeliveryInfo {
  companyRequisites: ICompanyRequisites;
  deliveryDescription: string;
  deliveryZones: IDeliveryZones[];
  paymentDescription: string;
  refundInfoShow: boolean;
}

export default function Payment() {
  const { company, branch, city } = useAppContext();
  const { visitorId } = useVisitorContext()
  const [isOpenMap, setIsOpenMap] = useState(false);

  const [delivery, setDelivery] = useState<any>(null);
  const [deliveryZoneList, setDeliveryZoneList] = useState<any[]>([]);

  const [loader, setLoader] = useState(true);

  const mapRef = useRef<any>(null);

  useEffect(() => {
    animateScroll.scrollToTop()
  }, [])


  useEffect(() => {
    setLoader(true);    
    apiClient.delivery.get(branch.id).then(({data})=>{
      setDelivery(data.deliveryInfo);
      setDeliveryZoneList(data.deliveryInfo.deliveryZones.reverse() || []);
      setLoader(false);
    })
  }, [city, branch?.id])

  useEffect(() => {
    if (visitorId) {
      sendEvent('section-visited', {target: 'Доставка и оплата'})
    }
  }, [visitorId])

  if (loader) return <div className="container">
      <div className="md:mt-[45px] mt-[20px] md+:mb-[35px] mb-[25px]">
        <h2 className="md:text-4xl font-bold text-2xl">Доставка и оплата</h2>
      </div>
      <div className={'py-[20vh]'}>
        <Preloader countOfDot={4}/>
      </div>
    </div>


  return (
    <div className="container">
      <div className="md:mt-[20px] mb-[25px]  md:mt-0 mt-[15px]">
        <h2 className="sm:text-4xl font-bold text-2xl text-dark dark:text-light">Доставка и оплата</h2>
      </div>

      {
        !!delivery?.deliveryDescription &&
        <div className="md+:mb-[30px] mb-[20px]">
          <h3 className="text-2xl font-bold md+:mb-[27px] mb-[10px]text-dark dark:text-light">
            Условия доставки
          </h3>
          <div className="text-gray-50 dark:text-white md:text-base text-sm  [&_a]:text-main [&_ul]:list-disc [&_li]:ml-5 [&_ol]:list-decimal" dangerouslySetInnerHTML={{__html: delivery?.deliveryDescription}}/>
        </div>
      }

      <div className="md+:mb-[45px] mb-[30px]">
        <h3 className="text-2xl font-bold sm:mb-[27px] mb-[15px] text-dark dark:text-light">
          Зоны доставки
        </h3>
        <div className="md+:px-[30px] sm:px-[20px] px-[15px] py-[17px] rounded-[10px] w-[100%] bg-white dark:bg-gray-50">
          <div
            className="flex items-center justify-between text-additional cursor-pointer"
            onClick={() => setIsOpenMap(!isOpenMap)}
          >
            <div className="flex items-center">
              <DeliveryMapIcon className="md:w-[23px] h-[23px] w-[15px]" />
              <h4 className="font-bold sm:text-xl text-base ml-[15px]">
                Зоны доставки на карте
              </h4>
            </div>
            <div
              className={`${
                isOpenMap ? "rotate-[270deg]" : "rotate-90"
              } flex items-center `}
            >
              <ArrowIcon
                colorClassName="fill-additional"
                className="w-[10px] h-[10px]"
              />
            </div>
          </div>

          {isOpenMap && (
            <div className="rounded-[10px] overflow-hidden md+:mt-[20px] mt-[15px] mb-[13px] payment">
              <Map
                instanceRef={mapRef}
                modules={["geoObject.addon.balloon", "geoObject.addon.hint"]}
                width={"100%"}
                height={"600px"}
                defaultState={{
                  center: [80.5966, 30.6601],
                  zoom: 9.5,
                }}
                defaultOptions={{
                  yandexMapDisablePoiInteractivity: true,
                }}
              >
                {deliveryZoneList.map((zone) => {
                  return (
                    <Polygon
                      onLoad={(api) => {
                        api.ready(() => {
                          const bounds =
                            mapRef.current.geoObjects.getBounds();
                          if (bounds) {
                            mapRef.current.setBounds(
                              mapRef.current.geoObjects.getBounds()
                            );
                          }
                        });
                      }}
                      key={zone.id}
                      defaultOptions={{
                        hasBalloon: true,
                        openBalloonOnClick: true,
                      }}
                      geometry={[zone.points]}
                      properties={{
                        balloonContent: `
                        <div class="w-[240px] md:w-max mr-[0] px-[15px] md:px-[20px] py-[15px] md:pb-[20px]">
        
                          <div class="flex flex-col items-start">
                            <div class="w-[96%] flex flex-row text-[15px] md:text-xl font-bold mb-[5px] border-b pb-[10px]">
                              ${ zone.title }
                            </div>
                            
                            ${(zone.deliveryMinTime || zone.freeDeliveryFromTotal || zone.deliveryFromTotal || zone.deliveryPrice)
                            ? `<div class="grid grid-cols-2 grid-flow-row md:grid-flow-col w-full gap-2 md:gap-3">
                                
                                ${zone.deliveryFromTotal
                                  ? `<div class="">
                                      <p class="text-gray-40 sm:text-base text-sm sm:w-auto">Минимальный заказ</p>
                                      <p class="text-gray-60 font-bold sm:text-xl text-base sm:mb-0 ">${formatCurrency(branch.extended.currency.code, zone.deliveryFromTotal)}</p>
                                    </div>` : ''}
  
                                ${zone.deliveryPrice
                                  ? `<div class="md:ml-1.5">
                                            <p class="text-gray-40 sm:text-base text-sm sm:w-auto">Стомость доставки</p>
                                            <p class="text-gray-60 font-bold sm:text-xl text-base sm:mb-0 ">${formatCurrency(branch.extended.currency.code, zone.deliveryPrice)}</p>
                                    </div>` : ''}
  
                                ${zone.freeDeliveryFromTotal
                                ? `<div class="">
                                    <p class="text-gray-40 sm:text-base text-sm sm:w-auto">Бесплатная доставка от</p>
                                    <p class="text-gray-60 font-bold sm:text-xl text-base">${formatCurrency(branch.extended.currency.code, zone.freeDeliveryFromTotal)}</p>  
                                  </div>`
                                : ``}
                                ${zone.deliveryMinTime
                                ? `<div class="">
                                    <p class="text-gray-40 sm:text-base text-sm sm:w-auto">Время доставки</p>
                                    <p class="text-gray-60 font-bold sm:text-xl text-base">от ${zone.deliveryMinTime} мин.</p>  
                                  </div>`
                                : ``}
                              </div>`
                          : `<div class="w-[96%]">
                              <p class="text-gray-60 font-bold text-[14px] md:text-[19px]">Нет информации о данной зоне
                                доставки</p>
                            </div>`
                        }
                          </div>
                          
                        </div>
                      `,
                      }}
                      options={{
                        // balloonCloseButton: false,
                        fillColor: zone.color,
                        fillOpacity: 0.4,
                        strokeWidth: 4,
                        strokeColor: zone.color,
                        strokeOpacity: 0,
                        // @ts-ignore
                        balloonPanelMaxMapArea: 0,
                      }}
                    />
                  );
                })}
              </Map>
            </div>
          )}
        </div>
      </div>

      {
        !!branch.extended.paymentDescription &&
        <div className="mb-[35px]">
          <h3 className="text-2xl font-bold sm:mb-[15px] mb-[10px] text-dark dark:text-light">
            Условия оплаты
          </h3>
          <p className="text-gray-50 dark:text-white md:text-base text-sm [&_a]:text-main [&_ul]:list-disc [&_li]:ml-5 [&_ol]:list-decimal" dangerouslySetInnerHTML={{__html: branch.extended.paymentDescription}}></p>
        </div>
      }

      {
        !delivery.companyRequisites.hideDetails &&
        <div className="mb-[35px]">
          <h3 className="text-2xl font-bold sm:mb-[15px] mb-[10px] text-dark dark:text-light">
            Реквизиты
          </h3>
          <div className="text-gray-50 dark:text-gray-10 text-sm md:text-base">
            {delivery.companyRequisites.companyName && (
              <p>Компания: {delivery.companyRequisites.companyName}</p>
            )}
            {delivery.companyRequisites.inn && (
              <p>ИНН: {delivery.companyRequisites.inn}</p>
            )}
            {delivery.companyRequisites.kpp && (
              <p>КПП: {delivery.companyRequisites.kpp}</p>
            )}
            {delivery.companyRequisites.ogrn && (
              <p>ОГРН: {delivery.companyRequisites.ogrn}</p>
            )}
            {delivery.companyRequisites.bik && (
              <p>БИК: {delivery.companyRequisites.bik}</p>
            )}
            {delivery.companyRequisites.address && (
              <p>Юридический адрес: {delivery.companyRequisites.address}</p>
            )}
            {delivery.companyRequisites.bank && (
              <p>Банк: {delivery.companyRequisites.bank}</p>
            )}
            {delivery.companyRequisites.correspondentAccount && (
              <p>Корр. счет: {delivery.companyRequisites.correspondentAccount}</p>
            )}
            {delivery.companyRequisites.paymentAccount && (
              <p>Расчетный счет: {delivery.companyRequisites.paymentAccount}</p>
            )}  
            {delivery.companyRequisites.phone && (
              <p>Телефон: {delivery.companyRequisites.phone}</p>
            )}
            {delivery.companyRequisites.email && (
              <p>E-mail: {delivery.companyRequisites.email}</p>
            )}
          </div>
        </div>
      }

      {delivery.refundInfoShow && (
        <div className="md+:mb-[100px] mb-[60px] flex flex-col gap-y-[30px]">
          <div>
            <h3 className="text-2xl font-bold sm:mb-[15px] mb-[10px] text-dark dark:text-light">
              Оплата банковской картой on-line.
            </h3>
            <p className="text-gray-50 dark:text-white md:text-base text-sm">
              Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены
              на платёжный шлюз ПАО СБЕРБАНК. Соединение с платёжным шлюзом и
              передача информации осуществляется в защищённом режиме с
              использованием протокола шифрования SSL. В случае если Ваш банк
              поддерживает технологию безопасного проведения интернет-платежей
              Verified By Visa, MasterCard SecureCode, MIR Accept, J-Secure для
              проведения платежа также может потребоваться ввод специального
              пароля. Настоящий сайт поддерживает 256-битное шифрование.
              Конфиденциальность сообщаемой персональной информации
              обеспечивается ПАО СБЕРБАНК. Введённая информация не будет
              предоставлена третьим лицам за исключением случаев,
              предусмотренных законодательством РФ. Проведение платежей по
              банковским картам осуществляется в строгом соответствии с
              требованиями платёжных систем МИР, Visa Int., MasterCard Europe
              Sprl, JCB. Оплата происходит через ПАО СБЕРБАНК с использованием
              банковских карт следующих платёжных систем:
            </p>
            <div className={'mt-[10px]'}>
              <ul className="list-disc font-bold text-gray-50 dark:text-white ml-[40px] ">
                <li>МИР</li>
                <li>VISA International</li>
                <li>Mastercard Worldwide</li>
                <li>JCB</li>
              </ul>
            </div>

          </div>

          <div>
            <h3 className="text-2xl font-bold sm:mb-[15px] mb-[10px] text-dark dark:text-light">
              Правила возврата товара
            </h3>
            <p className="text-gray-50 dark:text-white md:text-base text-sm">
              В соответствии с законодательством РФ возврат продовольственной
              Продукции надлежащего качества не допускается, возврат денежных
              средств за такую Продукцию не производится.
            </p>
          </div>

          <div>
            <h3 className="text-2xl font-bold sm:mb-[15px] mb-[10px] text-dark dark:text-light">
              Возврат продукции ненадлежащего качества
            </h3>
            <p className="text-gray-50 dark:text-white md:text-base text-sm">
              В случае если Клиент обнаружит, что ему передана Продукция
              ненадлежащего качества, он вправе до истечения срока годности
              возвратить Продукцию ненадлежащего качества Исполнителю и
              потребовать возврата уплаченной денежной суммы, либо потребовать
              замены Продукции ненадлежащего качества. При этом стоимость
              Продукции возвращается Клиенту в течение 10 дней с момента
              получения Исполнителем заявления Клиента. Возврат переведённых
              средств, производится на ваш банковский счёт в течение 5 — 30
              рабочих дней (срок зависит от банка, который выдал вашу банковскую
              карту).
            </p>
          </div>

        </div>
      )}
    </div>
  );
}