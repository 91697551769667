import { ChangeEvent, useEffect, useState } from "react";

interface IGood {
  goodId: number,
  estimation: number
}

interface IRangeProps {
  min: number, 
  max: number, 
  goodId?: number, 
  goodsReviews?: IGood[] | any, 
  answers?: any,
  errors?: any
}

export default function Range({ min, max, goodsReviews, goodId, answers, errors }: IRangeProps) {
  const [mealRate, setMealRate] = useState<number>(0);
  const [error, setError] = useState(null)

  useEffect(() => {
    if(errors){
      if(goodsReviews) setError(goodsReviews.map((answer: any, index: number) => {
          if(errors[`goodsReviews.${index}.estimation`] && answer.goodId === goodId){
            return errors[`goodsReviews.${index}.estimation`]
          }
        }).filter((answer: any) => answer))
        if(answers) setError(answers.map((answer: any, index: number) => {
          if(errors[`answers.${index}.answer`] && answer.npsQuestionId === goodId){
            return errors[`answers.${index}.answer`]
          }
        }).filter((answer: any) => answer))
    }
  }, [errors])

  const onChangeMealRate = (e: ChangeEvent<HTMLInputElement>) => {
    setMealRate(Number(e.target.value))

    if(goodsReviews){
      const currentReview = goodsReviews.find((good: IGood) => good.goodId === goodId)
      if(currentReview){
        currentReview.estimation = Number(e.target.value)
      }
    }
 
    if(answers){
      const currentAnswer = answers.find((answer: any) => answer.npsQuestionId === goodId)
      if(currentAnswer){
        currentAnswer.answer = Number(e.target.value)
      }
    }
    
  }

  const values = [];
 
  for (let i = min; i <= max; i++) {
    values.push(i);
  }

  let bgSize = mealRate? (mealRate - min) * 100 / (max - min) + '% 100%' : '0';
  
  return (
    <div className="flex flex-col p-2">
      <div className="flex justify-between font-semibold text-center text-sm mb-[15px]">
        {values.map((val) => (
          <div key={val} className="flex flex-col items-center relative">
            <span className={val <= mealRate ?  "w-[9px] text-main": 'w-[9px] text-orderbtn'} key={val}>{val}</span>
            <div className={`w-[3px] h-[10px] ${mealRate === 0 && val === 1?'bg-transparent' : val < mealRate? 'bg-main' : val === mealRate? 'bg-transparent' :'bg-orderbtn'} cursor-pointer absolute bottom-[-110%] z-[10]`}>
            </div>
          </div>
        ))}
     
      </div>
      <input
        style= {{backgroundSize: `${bgSize}`}}
        min={min}
        max={max}
        value={mealRate}
        onChange={(e) => {
          console.log('im work')
          setError(null)
          onChangeMealRate(e)
        }}
        type="range"
        className={`
        z-[20]
        rounded-3xl
        bg-orderbtn
        bg-no-repeat
        bg-gradient-to-r from-main to-main
        appearance-none 
        cursor-pointer
        [&::-webkit-slider-runnable-track]:h-[5px]
        [&::-webkit-slider-runnable-track]:appearance-none 
        [&::-webkit-slider-runnable-track]:bg-transparent 
        [&::-webkit-slider-runnable-track]:rounded-full 
        [&::-webkit-slider-runnable-track]:bg-black/25 
        [&::-webkit-slider-thumb]:mt-[-2px] 
        [&::-webkit-slider-thumb]:outline-5 
        [&::-webkit-slider-thumb]:outline 
        [&::-webkit-slider-thumb]:outline-main
        [&::-webkit-slider-thumb]:appearance-none 
        [&::-webkit-slider-thumb]:h-[9px] 
        [&::-webkit-slider-thumb]:w-[9px] 
        [&::-webkit-slider-thumb]:rounded-full 
        [&::-webkit-slider-thumb]:bg-white
        [&::-webkit-slider-thumb]:z-[1000] 
      `}
      />
      {error && 
        <p className={'mt-[10px] text-yellow text-center text-xs font-medium'}>{error}</p>
      }
    </div>
  );
}
