import React, { useEffect, useState } from 'react'
import { useAppContext } from '../../../contexts/App'
import { useCartContext } from '../../../contexts/CartContext'
import BanImg from '../../../images/ban.svg'
import { formatCurrency } from '../../../libs/helpers/formatCurrency'
import IIcon from '../../icons/i'
import { localStorageWrap } from '../../../libs/helpers/localStorageWrap'


export default function DeliveryInfo() {
  const { address, branch } = useAppContext()
  const { cart } = useCartContext()

  const [total, setTotal] = useState(0)

  useEffect(() => {
    if (JSON.parse(localStorageWrap.getItem('isSynchronizedCart') || 'false')) {
      setTotal(cart.amount)
    } else {
      setTotal(cart.raw.reduce((total, product) => total + product.total, 0))
    }
  }, [cart])

  if (address?.type === 'delivery' && cart?.deliveryZoneId) {
    // Если есть минимальная цена для доставки, и пользователь её ещё не набрал
    if (cart.options.deliveryFromTotal && total < cart.options.deliveryFromTotal) {
      return (
        <div className='relative group'>
          <img className={'mr-[22px]'} src={BanImg} alt="" />
          <div className={`absolute z-[2] top-[38px] right-4 right-[-10px] scale-0 md:group-hover:scale-100 duration-200 p-5 w-max bg-gray-50 dark:bg-gray-40 rounded-[18px] text-dark dark:text-gray-10 text-xs font-semibold shadow-md`}>
            <div className={`absolute border-[20px] border-transparent right-5 top-[-30px] border-b-gray-50 dark:border-b-gray-40`}/>
            <div className={`flex items-center gap-1 justify-center text-xs text-white`}>
              Минимальная сумма заказа для доставки {formatCurrency(branch.extended.currency.code, cart.options.deliveryFromTotal)}
            </div>

            {cart.options.deliveryAmount?
              <div className={`flex items-center gap-1 text-xs text-white mt-2`}>
                Стоимость доставки - {formatCurrency(branch.extended.currency.code, cart.options.deliveryAmount)}
              </div>
              :
              <span></span>
            }

            {cart.options.freeDeliveryFromTotal && !cart.options.isFreeDeliveryDisabled ?
              <div className={`flex items-center gap-1 text-xs text-white mt-2`}>
                Бесплатная доставка при заказе от {formatCurrency(branch.extended.currency.code, cart.options.freeDeliveryFromTotal)}
              </div>
              :
              <span></span>
            }
          </div>
        </div>
        // Минимальная сумма заказа для доставки {cart.options.deliveryFromTotal} <RoubleIcon colorClassName='fill-yellow' width={12} height={15}/>
      )
    } else if (cart.options.freeDeliveryFromTotal && total < cart.options.freeDeliveryFromTotal && (cart.options.deliveryFromTotal && total >= cart.options.deliveryFromTotal || !cart.options.deliveryFromTotal) && !cart.options.isFreeDeliveryDisabled) { // Если есть цена после, которой доставка бесплатная, то выводим сколько пользователю ещё осталось добрать, но не выводим если не набран минимум для доставки, если он есть конечно
      return (
        <div className='relative group'>
          <IIcon color='text-[#fff]' isCartTooltipIcon className='mr-[22px] w-[15px] h-[15px]'/>
          {/* <div className='mr-[22px] w-[14px] h-[14px] flex items-center justify-center rounded-full bg-white text-[12px] font-bold text-main'>i</div> */}
          <div className={`absolute z-[2] top-[38px] right-4 right-[-10px] scale-0 md:group-hover:scale-100 duration-200 p-5 w-max bg-gray-50 dark:bg-gray-40 rounded-[18px] text-dark dark:text-gray-10 text-xs font-semibold shadow-md`}>
            <div className={`absolute border-[20px] border-transparent right-5 top-[-30px] border-b-gray-50 dark:border-b-gray-40`}/>
            <div className={`flex items-center gap-1 justify-center text-xs text-white`}>
              {/* До бесплатной доставки ещё {cart.options.freeDeliveryFromTotal - total} <RoubleIcon colorClassName='fill-white' width={9} height={11}/> */}
              До бесплатной доставки ещё {formatCurrency(branch.extended.currency.code, cart.options.freeDeliveryFromTotal - total)}
            </div>
            {cart.options.deliveryAmount?
              <div className={`flex items-center gap-1 text-xs text-white mt-2`}>
                Стоимость доставки - {formatCurrency(branch.extended.currency.code, cart.options.deliveryAmount)}
              </div>
              :
              <span></span>
            }
          </div>
        </div>
      )
    } else if (!cart.options.freeDeliveryFromTotal || cart.options.isFreeDeliveryDisabled) { // Порога бесплатной доставки нет, то просто выводим цены или бесплатная доставка отключена воронкой
      return (
        <div className='relative group'>
          <IIcon color='text-[#fff]' isCartTooltipIcon className='mr-[22px] w-[15px] h-[15px]'/>
          {/* <div className='mr-[22px] w-[14px] h-[14px] flex items-center justify-center rounded-full bg-white text-[12px] font-bold text-main'>i</div> */}
          <div className={`absolute z-[2] top-[38px] right-4 right-[-10px] scale-0 md:group-hover:scale-100 duration-200 p-5 w-max bg-gray-50 dark:bg-gray-40 rounded-[18px] text-dark dark:text-gray-10 text-xs font-semibold shadow-md`}>
            <div className={`absolute border-[20px] border-transparent right-5 top-[-30px] border-b-gray-50 dark:border-b-gray-40`}/>
            <div className={`flex items-center gap-1 justify-center text-xs text-white`}>
              {/* Стоимость доставки {cart.options.amount} <RoubleIcon colorClassName='fill-white' width={9} height={11}/> */}
              Стоимость доставки {formatCurrency(branch.extended.currency.code, cart.options.deliveryAmount)}
            </div>
          </div>
        </div>
      )
    } else { // Бесплатная доставка
      return (
        <div className='relative group'>
          <IIcon color='text-[#fff]' isCartTooltipIcon className='mr-[22px] w-[15px] h-[15px]'/>
          {/* <div className='mr-[22px] w-[14px] h-[14px] flex items-center justify-center rounded-full bg-white text-[12px] font-bold text-main'>i</div> */}
          <div className={`absolute z-[2] top-[38px] right-4 right-[-10px] scale-0 md:group-hover:scale-100 duration-200 p-5 w-max  bg-gray-50 dark:bg-gray-40 rounded-[18px] text-dark dark:text-gray-10 text-xs font-semibold shadow-md`}>
            <div className={`absolute border-[20px] border-transparent right-5 top-[-30px] border-b-gray-50 dark:border-b-gray-40 `}/>
            <div className={`flex items-center gap-1 justify-center text-xs text-white`}>
              Доставка бесплатная
            </div>
          </div>
        </div>
      )
    }
  }

  return <></>
}
