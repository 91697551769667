import React, { createContext, useContext, useState, useEffect } from "react";
import { useAppContext } from "./App";

interface ContextType {
  animationCartProducts: any; // Вся корзина 
  addProductToAnimation: (productId: number) => void; // Добавить товар в корзину 
  removeProductFromAnimation: (productId?: any) => void; // Убрать товар из корзины
}

const AnimationProductsCartContext = createContext<ContextType>({
  animationCartProducts: [],
  addProductToAnimation: () => {},
  removeProductFromAnimation: () => {},
})

export function AnimationCartProductsContextWrapper({children}: any) {
  const { allProducts } = useAppContext()
  const [animationCartProducts, setAnimationCartProducts] = useState<any>([])
    
  function addProductToAnimation(productId: number) {
    setAnimationCartProducts((prev: any)=>[...prev, {...allProducts[productId], dynamicKey: new Date().getTime()}])
  }

  function removeProductFromAnimation(productId: any) {
    if(productId){
      setAnimationCartProducts((prev: any)=>prev.filter((item: any, index: number) => item.dynamicKey !== productId))
      return
    }
  }    

  return (
    <AnimationProductsCartContext.Provider 
      value={{ 
        animationCartProducts, 
        addProductToAnimation, 
        removeProductFromAnimation, 
      }}
    >
      {children}
    </AnimationProductsCartContext.Provider>
  )
}

export function useAnimationProductsCartContext() {
  return useContext(AnimationProductsCartContext)
}
