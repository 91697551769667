import {useRef} from "react";

export function useGuardedCallback(f: (...o: any[]) => Promise<void> | void) : (...o: any[]) => Promise<void> {
  const guardRef = useRef<boolean>(false)
  return (...o: any[]): Promise<void> => {
    if (guardRef.current)
      return Promise.resolve()
    guardRef.current = true
    let r = f(...o)
    if (r) {
      return r.then(() => { guardRef.current = false }).finally(() => { guardRef.current = false; });
    }
    else {
      guardRef.current = false
      return Promise.resolve()
    }
  }
}
