import { useState } from "react";
import { IGoods } from "../../../pages/[city]/account/history";
import useForm from "../../../hooks/useForm";
import { apiClient } from "../../../libs/api/apiClient";
import { BaseButton } from "../../common/button/BaseButton";
import Range from "../../common/range";
import { Preloader } from "../../common/preloader";

interface secondStepProps {
  inPopup?: boolean,
  goods: IGoods[] | null | undefined,
  nextStep: () => void
  questionnaireId: any
}

export default function SecondStep({inPopup = false, goods, nextStep, questionnaireId}: secondStepProps) {
  const {data} = useForm({ goodsReviews: goods?.map(good => { return {goodId: good.id, estimation: 1}}) })
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState(null)

  const handleSubmit = async () => {
    setLoading(true)
    await apiClient.profileOrders.addGoodsReview(questionnaireId, data )
      .then(res => {
        setLoading(false)
        if(res.status === 422) {
          setErrors(res.errors)
        } else {
          nextStep()
        }
    })
  }

  return (
    <div className={`flex xs:gap-y-8 gap-y-8 flex-col ${inPopup? '' : ''}`}>
      <>
        {goods?.map(good => 
          <div key={good.id} className={`bg-white ${inPopup? 'dark:bg-transparent' : 'xs:p-[15px] p-[10px] dark:bg-gray-40 rounded-[29px] shadow-md'}`}>
          <div className=" flex">
            <div className={`min-w-[120px] max-w-[120px] h-[120px] bg-gray-50 ${inPopup? 'dark:bg-gray-40' : ''}  rounded-[18px] overflow-hidden`}>
              <img src={good.image} alt="" className="w-full h-full object-cover"/>
            </div>
            <div className={`${inPopup? 'ml-[20px]' : 'xs:ml-[30px] ml-[10px]'} flex flex-col`}>
              <div className="xs:w-[240px]  ">
                <h4 className="text-lg font-bold leading-[26px] mb-[20px] word-break text-dark dark:text-light">
                  {good.title}
                </h4>
              </div>
            </div>
          </div>
          <div className={`${inPopup ? 'xs:ml-[140px]' : 'xs:ml-[150px] '} mb-[10px] xs:mt-[-60px]`}>
            <Range min={1} max={5} goodsReviews={data.goodsReviews} goodId={good.id} errors={errors}/>
          </div>
        </div>  
        )}
      </>
      
      <div className={`md:mt-[60px] mt-[50px] ${inPopup? 'sticky bottom-0 left-0 rounded-[18px] z-[20] bg-white ' : ''}`}>
        <BaseButton
          disabled={loading}
          className="bg-orderbtn w-full text-main font-bold"
          onClick={handleSubmit}
        >
          {loading?
            <Preloader countOfDot={4} size={'10px'} className={'h-[20px] gap-2 items-center'}/>
            : 
            'Оставить отзыв'
          }
        </BaseButton>
      </div>
    </div>
  );
}
