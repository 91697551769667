import React from "react";

export default function TgIcon({className = 'w-[16px] h-[14px]', colorClassName = 'fill-main'}: {
  className?: string;
  colorClassName?: string;
}) {
  return (
    <svg className={`${className} ${colorClassName}`} viewBox="0 0 16 14" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.65039 12.7815L5.88373 9.07094L12.2837 3.00076C12.5671 2.72883 12.2254 2.59725 11.8504 2.83409L3.95039 8.08848L0.533726 6.94813C-0.199607 6.72883 -0.207941 6.19374 0.700393 5.80778L14.0087 0.404268C14.6171 0.114795 15.2004 0.562163 14.9671 1.54462L12.7004 12.7815C12.5421 13.5797 12.0837 13.7727 11.4504 13.4043L8.00039 10.7201L6.34206 12.413C6.15039 12.6148 5.99206 12.7815 5.65039 12.7815Z"/>
    </svg>
  );
}
