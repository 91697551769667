import React, { useEffect, useRef, useState } from 'react'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'

export default function Description({ product, mainProduct, showDescription, setShowDescription }: any) {

  const allDescription = mainProduct?.optionsGroups.length === 0 && mainProduct?.allSkuGroupsWithValues.length === 0

  useEffect(() => {
    setShowDescription(allDescription)
  }, [allDescription])

  return (
    <>
      {
        product.description ?
        <div className='md:mt-[5px] text-sm'>
          {
            showDescription ?
            <>
              <div className='text-gray-50 dark:text-white [&_a]:text-main [&_ul]:list-disc [&_li]:ml-5 [&_ol]:list-decimal'
                   dangerouslySetInnerHTML={product?.description && showDescription ? {__html: product?.description} : undefined}></div>
              
              {
                !allDescription && 
                <div className={'text-main underline cursor-pointer'} onClick={() => setShowDescription(false)}>Скрыть</div>
              }
            </> :
            <>      
              <HTMLEllipsis
                className='text-gray-50 dark:text-white whitespace-pre-wrap [&_a]:text-main [&_ul]:list-disc [&_li]:ml-5 [&_ol]:list-decimal'
                unsafeHTML={product.description}
                maxLine={2}
              />

              {
                !allDescription && 
                <div className={'text-main underline cursor-pointer'} onClick={() => setShowDescription(true)}>Показать еще</div>
              }
              
            </>
          }
        </div> :
        <div className='md:mt-[5px] text-sm'>
          <div className='text-gray-50 dark:text-gray-10'>
            {product.shortDescription}
          </div>
        </div>
      }
    </>
  )
}
