import React, {
  ClipboardEventHandler,
  KeyboardEventHandler,
  MutableRefObject,
  ReactElement,
  useEffect,
  useRef,
  useState
} from "react";
import { Preloader } from "../../preloader";

export interface ITextInput {
  type?: string,
  value: any,
  disabled?: boolean,
  autoFocus?: boolean,
  getAuxElement?: (auxState: any, setAuxState: (s: any) => void, setDisabled: (s: boolean) => void) => ReactElement
  onEnterPressed?: (e: any) => void | boolean,
  onBlur?: (e: any) => any,
  onClick?: (e: any) => any,
  onFocus?: (e: any) => any,
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>,
  onPaste?: ClipboardEventHandler<HTMLInputElement>,
  onChange: (value: string) => void,
  onFormat?: (value: string) => string | null,
  placeholderInput?: string,
  children?: any,
  className?: string,
  classNameInput?: string,
  maxLength?: number,
  key?: any,
  inputRef?: MutableRefObject<HTMLInputElement | null>
  name?: string,
  required?: boolean
  errors?: any,
  max?:any,
  min?: any;
  errorLabel?: boolean,
  allowEdit?: boolean,
  inputmode?: string,
}

export function TextInput({
  type = 'text',
  disabled = false,
  autoFocus = false,
  className = '',
  classNameInput = '',
  placeholderInput = '',
  value,
  children,
  getAuxElement,
  onChange,
  onKeyDown,
  onEnterPressed,
  maxLength,
  onFormat,
  inputRef,
  name = '',
  errors = {},
  errorLabel = true,
  allowEdit = true,
  ...props
}: ITextInput) {
  const [auxState, setAuxState] = useState<any>(undefined)
  const [isDisabled, setDisabled] = useState<boolean>(disabled)
  
  useEffect(() => {
    setDisabled(disabled)
  }, [disabled])

  const error = errors[name]; 

  useEffect(() => {
    delete errors[name];
  }, [value]);

  return (
    <>
      <div className={`flex flex-row items-center justify-between text-sm px-[22px] py-2 rounded-[10px] bg-gray-20 dark:bg-gray-40 text-dark dark:text-light ${error && 'border-[1px] border-yellow'} ${className}`}>
        <input
          autoFocus={autoFocus}
          name={name}
          ref={inputRef}
          type={type}
          placeholder={placeholderInput}
          value={value}
          maxLength={maxLength}
          onKeyDown={e => {
            if (e.key === 'Enter' && onEnterPressed && onEnterPressed(e)) return;
            return onKeyDown && onKeyDown(e);
          }}
          onChange={e => {
            let val: string | null = e.target.value || '';
            if (onFormat) val = onFormat(val);
            onChange(val != null ? val : value);
          }}
          className={`w-full h-5 bg-transparent placeholder-gray-30 dark:placeholder-gray-20 outline-none disabled:text-gray-40 dark:disabled:text-gray-20 ${value && 'has-value'} ${classNameInput}`} {...props}
          disabled={isDisabled}
          {...props}
        />
        {getAuxElement && allowEdit && <div
            className={`flex justify-end text-main cursor-pointer ml-4`}>{getAuxElement(auxState, setAuxState, setDisabled)}</div>}
            </div>
        {error && errorLabel && (<span className={'text-yellow text-xs font-medium'}>{error}</span>)}
    </>
  )
}

export function TextInputWithSave({type, onBlur, onClick, onEnterPressed, disabled, onSave, inputRef, value, allowEdit = true, classNameInput = '', ...props}: ITextInput & { onSave: () => boolean | void | Promise<boolean>, allowEdit?: boolean }) {
  const [disabledInternal, setDisabledInternal] = useState(true);
  const [edit, setEdit] = useState(false);

  const [showPreloader, setShowPreloader] = useState(false);
  const [lastValue, setLastValue] = useState(null)

  const saveAndExit = (e : any) => {
    if (lastValue === value) {
      ref.current?.blur();
      setDisabledInternal(true);
      setEdit(false);
      setShowPreloader(true);
      setTimeout(() => setShowPreloader(false), 200)
      return
    }
    const result = onSave();
    setShowPreloader(true);
    Promise.resolve(result).then(success => {
      setShowPreloader(false);
      if (success) {
        ref.current?.blur();
        setDisabledInternal(true);
        setEdit(false);
      }
      else {
        setDisabledInternal(false);
        setEdit(true);
      }
    })
  }

  const fallbackRef = useRef<any>(null);
  const ref = inputRef || fallbackRef;
  return <TextInput
    {...props}
    value={value}
    type={type}
    inputRef={ref}
    onClick={(e) => {
      if (onClick && onClick(e))
        return true;
      if (!edit && allowEdit) {
        setDisabledInternal(false);
        setEdit(true);
        setLastValue(value)
        setTimeout(() => ref.current?.focus(), 150);
      }
      if (type !== 'date') e.preventDefault();
    }}
    onBlur={e => {
      if (onBlur && onBlur(e))
        return true;
      return saveAndExit(e);
    }}
    onEnterPressed={e => {
      if (onEnterPressed && onEnterPressed(e))
        return true;
      return saveAndExit(e);
    }}
    getAuxElement={() => {
      if (!allowEdit)
        return <></>
      if (showPreloader)
        return <Preloader countOfDot={4} size={'10px'}/>;
      return <span onClick={e => {
        if (disabled) return;
        let success = true;
        if (edit) {
          saveAndExit(e);
        } else {
          setEdit(true);
          setLastValue(value);
          setDisabledInternal(false);
          setShowPreloader(false);
          setTimeout(() => ref.current?.focus(), 150);
        }
      }}>{edit ? 'Сохранить' : 'Изменить'}</span>
    }}
    disabled={!allowEdit}
    classNameInput={`${disabledInternal || disabled ? `text-gray-40 dark:text-gray-20 ${!allowEdit ? 'cursor-default' : 'cursor-pointer'}` : ''} ${classNameInput}`}
  />
}


interface ITextInputCode {
  codeSize?: number,
  charRegex?: RegExp,
  statusCode: string,
  failText?: string
}

export function TextInputCode({
  value,
  onChange,
  statusCode,
  failText = 'Неверный код'
}: ITextInputCode & ITextInput) {
  const refs = useRef<any>([]);

  const handleInputChange = (index: number, v: string) => {
    const newValues = value.split('');
    newValues[index] = v;
    onChange(newValues.join(''));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace') {
      e.preventDefault();
      if (!value[index] && index > 0) {
        refs.current[index - 1]?.focus();
        handleInputChange(index - 1, '');
      } else {
        handleInputChange(index, '');
      }
    } else if (/^\d$/.test(e.key)) {
      e.preventDefault();
      handleInputChange(index, e.key);
      refs.current[index + 1]?.focus();
    }
  };

  return (
    <>
      <div className='w-full flex gap-x-[20px] items-center justify-center'>
        {Array.from(Array(4).keys()).map((index) => (
          <input
            autoFocus={index === 0}
            className={`w-[47px] h-[56px] bg-transparent placeholder-gray-30 dark:placeholder-gray-20 outline-none disabled:text-gray-40 dark:disabled:text-gray-20 text-center bg-gray-20 rounded-xl ${statusCode === 'wait' || statusCode === 'none' ? 'text-black dark:text-gray-20' : statusCode === 'success' ? 'text-success' : 'text-main'} ${statusCode === 'wait' || statusCode === 'none' || statusCode === 'ban' ? 'border-[2px] border-gray-20' : statusCode === 'success' ? 'border-[2px] border-success bg-transparent' : 'border-[2px] border-main bg-transparent'}`}
            type='text'
            key={index}
            ref={(el) => refs.current[index] = el}
            value={value[index] || ''}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (/^\d$/.test(inputValue)) {
                handleInputChange(index, inputValue);
                refs.current[index + 1]?.focus();
              } else if (inputValue === '') {
                handleInputChange(index, '');
              }
            }}
            onKeyDown={(e) => handleKeyDown(e, index)}
            onPaste={(e) => {
              let clipboardData = e.clipboardData?.getData('Text');
              if (/^\d{4}$/.test(clipboardData) && statusCode !== 'wait') {
                onChange(clipboardData);
                e.preventDefault();
              }
            }}
            onFocus={() => refs.current[index]?.select()}
          />
        ))}
      </div>
      {statusCode === 'wait' && <Preloader countOfDot={4} className={'gap-x-2 pt-5'} size={'10px'} />}
      <p className={`${statusCode === 'fail' ? 'opacity-1' : 'opacity-0'} text-main text-center max-w-[580px] break-words`}>
        {failText}
      </p>
    </>
  );
}