import React from "react";

export default function ArrowIcon({className='w-[6px] h-[9px]', colorClassName='fill-main'}: {
  className?: string;
  colorClassName?: string;
}) {
  return (
    <svg className={` ${ className } ${ colorClassName }`}  viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.257164 1.62311C-0.0857213 1.28022 -0.0857213 0.724295 0.257164 0.38141L0.38141 0.257164C0.724295 -0.0857215 1.28022 -0.0857211 1.62311 0.257164L5.78306 4.41712V4.58288L1.62311 8.74284C1.28022 9.08572 0.724295 9.08572 0.38141 8.74284L0.257164 8.61859C-0.0857213 8.27571 -0.0857213 7.71978 0.257164 7.37689L3.13406 4.5L0.257164 1.62311Z"/>
    </svg>
  );
}
