import React, { useEffect, useState } from 'react'
import Option from './Option'
import { OptionProductType } from '../../../../../types/cart' 

export default function OptionsGroup({ optionsGroup, addOption, removeOption, options }: any) {
  const [count, setCount] = useState(0)

  useEffect(() => {
    setCount(options.filter((option: OptionProductType) => option.groupId === optionsGroup.id)?.reduce((count: number, option: OptionProductType) => count + option.count, 0))
  }, [options])

  return (
    <div className='mb-[5px] md:mb-[15px]'>
      <div className='flex items-center justify-between mb-[5px] md:mb-[13px] md:mb-[7px] font-bold'>
        <div className='text-dark dark:text-gray-10'>{ optionsGroup.title }</div>
        {
          !!optionsGroup.minSelect && !!optionsGroup.maxSelect && optionsGroup.minSelect !== optionsGroup.maxSelect &&
          <div className='text-main'> {`От ${optionsGroup.minSelect} до ${optionsGroup.maxSelect}`} </div>
        }
        {
          !!optionsGroup.minSelect && (!optionsGroup.maxSelect || optionsGroup.minSelect === optionsGroup.maxSelect) && count < optionsGroup.minSelect &&
          <div className='text-main'> {`${count} из ${optionsGroup.minSelect}`} </div>
        }
        {
          !optionsGroup.minSelect && !!optionsGroup.maxSelect &&
          <div className='text-main'> {`До ${optionsGroup.maxSelect}`} </div>
        }
        
      </div>

      <div className='grid grid-cols-2 3xs:grid-cols-3 md:grid-cols-2 md+:grid-cols-3 gap-[10px] xxs:gap-[20px]'>
        {
          optionsGroup.options.map((option: any) => (
            <Option 
              isMaxLimit={count > 0 && count === optionsGroup.maxSelect} 
              canMany={optionsGroup.can_select_several_identical_options} 
              count={options.filter((item: OptionProductType) => (item.id === option.id && item.groupId === optionsGroup.id))[0]?.count || 0} 
              addOption={(id: number|string, price: number, title: string) => addOption(id, optionsGroup.id, price, title)} 
              removeOption={(id: number|string, price: number) => removeOption(id, optionsGroup.id)} key={option.id} option={option}
            />
          ))
        }
      </div>
    </div>
  )
}
