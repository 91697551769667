import moment from "moment"

export function getVariantsTimePreorder(preorderAllowedTimes: any[], date: any) {
  if (!preorderAllowedTimes) {
    return []
  }

  let now = new Date()

  if (date.getTime() == new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime()) {
    if (!preorderAllowedTimes)
      return []

    return preorderAllowedTimes
      ?.filter(({time}: any) => moment(time, 'hh:mm') > moment())
      .map(({time, title}: any) => ({name: title, id: time}))
  }
  
  return preorderAllowedTimes ? preorderAllowedTimes?.map(({time, title}: any) => ({name: title, id: time})) : []
}

export function getPreorderAt(preorderAllowedTimes: any[]) {
  let data: any = {}
  let now = new Date()
  let day = new Date(now.getFullYear(), now.getMonth(), now.getDate())
  let variants = getVariantsTimePreorder(preorderAllowedTimes, day)

  if (!variants?.length) {
    day.setDate(day.getDate() + 1);
    variants = getVariantsTimePreorder(preorderAllowedTimes, day)
  }
  
  data['deliveryTime'] = variants[0]?.id || '00:00'
  data['deliveryDate'] = new Date(day)

  let time = moment(variants[0]?.id || '00:00', 'hh:mm') 
  let preorderAt = new Date(day)
  
  preorderAt.setHours(preorderAt.getHours() + time.hour());
  preorderAt.setMinutes(preorderAt.getMinutes() + time.minute());

  data['preorderAt'] = preorderAt.toISOString()

  return data
}