import React, { useEffect, useState } from "react";
import { useDetectorBrowser } from "../../../hooks/useDetectorBrowser";
import { useVkScroll } from "../../../hooks/useVkScroll";
import CloseIcon from "../../icons/CloseIcon";
import ArrowIcon from "../../icons/ArrowIcon";


interface Props {
  isActive: boolean;
  close: () => void;
  children: React.ReactNode;
  width?: string;
  classNamePopup?: string;
  closeIcon?: boolean;
  isMap?: boolean;
  isImagesPopup?: boolean
  handlePrev?: () => void,
  handleNext?: () => void,
  isShowArrows?: boolean,
  movePopup?: boolean,
  closeOnLayout?: boolean,
}

export default function Popup({
  isActive,
  close,
  children,
  closeIcon,
  classNamePopup,
  width = "630px",
  isMap = false,
  isImagesPopup = false,
  handlePrev,
  handleNext,
  isShowArrows = false,
  movePopup = false,
  closeOnLayout = true,
}: Props) {
  useEffect(() => {
    if (isActive) {
      document.body.classList.add("overflow-y-hidden");
    } else {
      document.body.classList.remove("overflow-y-hidden");
    }
  }, [isActive]);

  const isSafari = useDetectorBrowser().includes('safari')

  const {vkScrollTop, vkScrollEnabled, isDesktopVk} = useVkScroll({isActive, padTop: 50, addTop: 50});

  return (
    <div
      className={
        `bg-fixed fixed z-[500] overflow-y-auto w-full max-w-screen h-screen top-0 left-0 ` +
        (!isActive && "hidden")
      }
    >
      {/*{typeof window !== 'undefined' && window.innerHeight}*/}
      <div className={`relative w-full min-h-screen flex justify-center ${isDesktopVk && movePopup ? '' : 'items-center'} px-4`}>
        <div
          onClick={() => {
            if(closeOnLayout){
              close()
            }
          }}
          className={`absolute w-full h-full min-h-screen top-0 left-0 bg-[#bdbdbd9e] dark:bg-[#0c0c0cc7]`}
        ></div>
        {isShowArrows &&
          <div className="rotate-180 cursor-pointer mr-2" onClick={handlePrev}>
            <ArrowIcon
              className="xs:w-[25px] xs:h-[25px] w-[15px] h-[15px]"
              colorClassName="fill-white"
            />
          </div>
        }
       
        <div
          style={movePopup? { maxWidth: width, ...(vkScrollEnabled ? {top: vkScrollTop} : {}) }: {maxWidth: width}}
          className={`relative w-full h-min ${(isMap && isSafari)? 'xs:top-0 top-[-40px] ': ''}  ${isMap || isImagesPopup? '' : 'px-5 md:px-[30px] py-[25px] md:py-8'} rounded-[29px] bg-white dark:bg-gray-50 shadow-[4px_4px_20px_rgba(0,0,0,0.07)] ${classNamePopup}`}
        >
          {closeIcon && (
            <div onClick={close} className={"absolute cursor-pointer z-[200]  top-[0px] flex justify-center items-center  right-[0px] rounded-full  w-[20px] h-[20px] bg-main"}>
              <CloseIcon
                colorClassName={"fill-white"}
                className={"w-[10px] h-[10px]"}
              />
            </div>
          )}
          {children}
        </div>

        {isShowArrows &&
          <div className="rotate-0 cursor-pointer ml-2"  onClick={handleNext}>
            <ArrowIcon
              className="xs:w-[25px] xs:h-[25px] w-[15px] h-[15px]"
              colorClassName="fill-white"
            />
          </div>
        }
      </div>
    </div>
  );
}
