import React from 'react'

export default function CrossedOldPriceIcon({colorClassName='stroke-main', width=40, height=13}: {
  colorClassName?: string;
  width?: number|string;
  height?: number|string;
}) {
  return (
    <svg width={width} height={height} viewBox="0 0 40 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.6" d="M0.584881 11.5936C4.44407 9.24268 11.6921 5.86678 20.4078 3.99566C29.1236 2.12455 36.404 1.98552 39.8011 1.95147" className={`${colorClassName}`} strokeWidth="2"/>
    </svg>
  )
}
