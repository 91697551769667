import SearchIcon from '../../../icons/SearchIcon'
import closeImg from '../../../../images/close.svg'

interface Props {
  className?: string;
  searchData: {
    searchQuery: string;
    setSearchQuery: (value: string) => void; 
    activeSearch: boolean
    setActiveSearch: (value: boolean) => void;
  };
  setCatalog: (value: any) => void;
  initialCatalog: any;
}

export default function Search({className='', searchData, setCatalog, initialCatalog}: Props) {
  const {searchQuery, setSearchQuery, activeSearch, setActiveSearch} = searchData

  return (
    <div 
      onClick={() => !activeSearch && setActiveSearch(true)}
      className={`flex ${activeSearch ? 'w-[calc(100%)] bg-gray-20 dark:bg-gray-50' : 'w-[37px] cursor-pointer bg-gray-30 dark:bg-gray-40'} duration-500 rounded-full overflow-hidden ${className}`}
    >
      <div className='min-w-[37px] h-[36px] flex justify-center items-center'>
        <SearchIcon className='mx-3' color={activeSearch ? '#BDBDBD' : '#ffffff'}/>
      </div>

      <input
        className={'w-full bg-transparent placeholder-gray-30 dark:placeholder-gray-20 outline-none text-dark dark:text-light disabled:text-gray-40 dark:disabled:text-gray-20'}
        value={searchQuery}
        onChange={e => {
          setSearchQuery(e.target.value)
        }}
        placeholder={'Поиск...'}
      />
      
      <div onClick={() => {
        setSearchQuery('')
        setActiveSearch(false)
        setCatalog(initialCatalog)
      }} className='min-w-[37px] h-[36px] flex justify-center cursor-pointer'>
        <img className='w-4' src={closeImg} alt="" />
      </div>
    </div>
  )
}
