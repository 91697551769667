import useForm from "../../../hooks/useForm";
import { apiClient } from "../../../libs/api/apiClient";
import { IQuestions } from "../../../pages/[city]/account/history";
import { BaseButton } from "../../common/button/BaseButton";
import { CustomBoolSlider } from "../../common/customBoolSlider";
import { Preloader } from "../../common/preloader";
import Range from "../../common/range";
import { Input } from "./inputsThirdStep/input";
import { TextareaForThirdStepReviews } from "./inputsThirdStep/textarea";
import { useState } from 'react'

interface thirdStepProps {
  maxLengthTextarea: number,
  inPopup?: boolean
  questions: IQuestions[] | null | undefined,
  nextStep: () => void,
  questionnaireId: any
}

export default function ThirdStep({maxLengthTextarea, inPopup = false, questions, nextStep, questionnaireId}: thirdStepProps) {
  const [errors, setErrors] = useState(null)
  const [loading, setLoading] = useState(false)
  const defaultData = questions?.map(question => {
    if(question.answerType === 'ten-point-scale' || question.answerType === 'five-point-scale'){
      return { npsQuestionId: question.id, answer: 1 }
    }
    if(question.answerType === 'bool'){
      return { npsQuestionId: question.id, answer: 'yes'}
    }
    if( question.isRequired ){
      return { npsQuestionId: question.id, answer: null }
    }
  }).filter(question => question)

  const {data} = useForm({ answers: defaultData })

  const handleSubmit = async () => {
    setLoading(true)
    await apiClient.profileOrders.addAnswers(questionnaireId, data)
      .then(res => {
        setLoading(false)
        if(res.status === 422) {
          setErrors(res.errors)
        } else {
          nextStep()
        }
    })
  }

  return (
    <>
      <div className={`mt-[25px] flex flex-col ${inPopup? 'gap-y-10' : 'xs:gap-y-10 gap-y-[25px]'} `}>
        {questions?.map(question => 
          <div key={question.id}>
            <h3 className={`font-bold text-dark dark:text-light ${inPopup? 'mb-[20px] xs:text-xl text-lg' : 'mb-[25px] xs:text-2xl text-[22px]'}`}>
              {question.text}
              <span className="ml-1 text-red-700">{question.isRequired && '*'}</span>
            </h3>
            {question.answerType === 'single-line-text' &&
              <Input id={question.id} answers={data.answers} errors={errors}/>
            }

            {question.answerType === 'multiline-text' &&
              <TextareaForThirdStepReviews
                id={question.id}
                answers={data.answers}
                maxLength={maxLengthTextarea}
                errors={errors}
              />
            }

            {question.answerType === 'ten-point-scale' &&
              <Range min={1} max={10} answers={data.answers} goodId={question.id} errors={errors}/>
            }

            {question.answerType === 'five-point-scale' &&
              <Range min={1} max={5} answers={data.answers} goodId={question.id} errors={errors}/>
            }

            {question.answerType === 'bool' &&
              <CustomBoolSlider inPopup={inPopup} answers={data.answers} id={question.id}/>
            }    
          </div>
        )}
        
        <div className={`md:mt-[60px] mt-[50px] ${inPopup? 'xs:relative sticky bottom-0 bg-white rounded-[18px]' : ''}`}>
          <BaseButton
            disabled={loading}
            className={`bg-orderbtn w-full text-main font-bold`}
            onClick={handleSubmit}
          >
            {loading?
              <Preloader countOfDot={4} size={'10px'} className={'h-[20px] gap-2 items-center'}/>
              : 
              'Оставить отзыв'
            }
          </BaseButton>
        </div>
      </div>
    </>
  );
}
