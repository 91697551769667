import React, { useEffect, useRef, useState } from "react";
import IIcon from "../../icons/i";


interface ICustomTooltip {
  children: any,
  symbol?: string,
  color?: string,
  positionClassName: string
}

export default function CustomTooltip({
children,
symbol = 'i',
color='text-[#4F4F4F]',
positionClassName
}:ICustomTooltip){
    const [show, setShow] = useState(false)
    const wrapper: any = useRef(null);

    useEffect(() => {
      function handleClick(event: any) {
        if (wrapper.current && !wrapper.current.contains(event.target)) {
          setShow(false);
        }
      }

      document.addEventListener('mousedown', handleClick)
      return () => {
        document.removeEventListener('mousedown', handleClick)
      }

    }, [show, setShow, wrapper]);

    return (
      <>
        <div className={'hidden md:block cursor-pointer flex justify-center h-fit'}>
          <div className='relative group'>
            <IIcon className={`w-[19px] h-[19px] cursor-pointer ${symbol === 'i'? '': 'rotate-180' }`} color={color}/>
            <Modal positionClassName={positionClassName}>{children}</Modal>
          </div>

        </div>
        <div className='md:hidden block' ref={wrapper}>
          <div>
            <div onClick={() => setShow(!show)} >
              <IIcon color={color} className={`${symbol === 'i'? '': 'rotate-180' } w-[19px] h-[19px] cursor-pointer`}/>
            </div>
             <Modal show={show} positionClassName={positionClassName}>
              {children}
            </Modal>
          </div>
        </div>
      </>
    )
  }

  function Modal({ show, children, positionClassName }: {show?: boolean, children: string, positionClassName: string}) {
    return (
      <div className={`absolute duration-200 p-5 scale-0 md:group-hover:scale-100 bg-gray-10 dark:bg-gray-40 rounded-[18px] text-main text-xs font-semibold shadow-md ${show && 'scale-100'} ${positionClassName}`}>
        {children}
      </div>
    )
  }
