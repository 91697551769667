import React, { forwardRef, useState } from 'react'
import DatePicker, { registerLocale } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import ru from 'date-fns/locale/ru';
import { getMonth, getYear } from 'date-fns';
import moment from 'moment';
import ArrowIcon from '../../icons/ArrowIcon';
registerLocale('ru', ru)


export default function DateInput({value, onChange=(x: any) => {}, minDate = null, maxDate = null, className=''}: any) {

  const handleDateChange = (date: any) => {
    onChange(date);
  }

  const [startDate, setStartDate] = useState(new Date());
  const months = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ];

  const CustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
    <button className="relative flex items-center pl-[25px] text-sm font-medium text-gray-60 w-full h-10 bg-gray-20 dark:bg-gray-40 dark:text-gray-20 rounded-[10px]" onClick={onClick} ref={ref}>
      {moment(value).format('DD.MM.YY')}
      <div className={'absolute right-5'}>
        <ArrowIcon className='w-[10px] h-[10px] rotate-90'/>
      </div>
    </button>
  ));

  CustomInput.displayName = 'CustomInput';

  return (
    <DatePicker 
      className={className}
      selected={value} 
      onChange={handleDateChange}
      locale="ru"
      maxDate={maxDate}
      minDate={minDate}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled
      }: any) => (
        <div className='flex justify-between items-center px-5 pb-3'>
          <div className='text-[10px] font-medium text-gray-30 dark:text-gray-20'>{months[getMonth(date)]} {getYear(date)}</div>
          <div className='flex gap-5'>
            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
              <ArrowIcon className='w-[10px] h-[10px] -rotate-90'/>
            </button>

            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
              <ArrowIcon className='w-[10px] h-[10px] rotate-90'/>
            </button>
          </div>     
        </div>
      )}

      customInput={<CustomInput />}

      popperPlacement="bottom-start"
      popperModifiers={[
        {
          name: "offset",
          options: {
            offset: [0, -50],
          },
        }
      ]}
    />
  )
}
