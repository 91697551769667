interface IBaseButton {
  className: string,
  children: any,
  disabled?: boolean,
  onClick?: ()=>void,
  type?: 'button' | 'submit' | 'reset',
}

export function BaseButton({className, children, type='button', ...props}: IBaseButton) {
  return (
    <button
      type={type}
      className={`disabled:cursor-default disabled:opacity-50 disabled:pointer-events-none font-medium flex justify-center items-center transition duration-300 ease-out text-sm px-[22px] py-2 rounded-[18px] ${className}`}
      {...props}
    >{children}</button>
  )
}
