import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import ArrowIcon from "../../icons/ArrowIcon";
import CloseIcon from "../../icons/CloseIcon";
import { BaseButton } from "../../common/button/BaseButton";
import { Preloader } from "../../common/preloader";
import { IStory } from "../../../types/story";
import { localStorageWrap } from './../../../libs/helpers/localStorageWrap';
import { useDetectorBrowser } from './../../../hooks/useDetectorBrowser';
import { useWindowResize } from './../../../hooks/useWindowResize';

export interface IStoriesProps {
  stories: IStory[];
}

export default function Stories({ stories }: IStoriesProps) {
  const slider = useRef<any>(null);
  const [activeStoryIndex, setActiveStoryIndex] = useState<number>(-1);
  // const [isLoading, setIsLoading] = useState(false);
  const [viewedStories, setViewedStories] = useState<number[]>([])
  const [tmpViewedStories, setTmpViewedStories] = useState<number[]>([])
  const [sortStories, setSortStories] = useState<IStory[]>(stories)

  const [isShowLeftArrow, setIsShowLeftArrow] = useState(false)
  const [isShowRightArrow, setIsShowRightArrow] = useState(false)
  const [sliderInit, setSliderInit] = useState(false)
  
  const settingsSlider = {
    className: "slider variable-width",
    slidesToShow: 1,
    slidesToScroll: 2,
    variableWidth: true,
    infinite: false,
    arrows: false,
    onInit: () => setSliderInit(true)
  };

  const isSafari = useDetectorBrowser().includes('safari')

  const checkIsVideo = (story: IStory) => {
    let reg = /.+(\.mp4|\.avi|\.webm)$/i;
    if (story) {
      if (story.media === undefined) {
        return false;
      }
      return reg.test(story.media);
    }
  };

  useEffect(() => {
    let initialViewedStories = JSON.parse(localStorageWrap.getItem("stories") || '[]');
    for (let storyId of tmpViewedStories) {
      if (!initialViewedStories.includes(storyId))
        initialViewedStories.push(storyId)
    }
    setViewedStories(initialViewedStories)
    setTmpViewedStories([])
    localStorageWrap.setItem("stories", JSON.stringify(initialViewedStories))

    if(activeStoryIndex === -1 || !initialViewedStories.length) {
      let pinnedStories = stories.filter((story: IStory) => story.fixed)
      let unpinnedStories = stories.filter((story: IStory) => !story.fixed)
      setSortStories([...pinnedStories, ...unpinnedStories.sort((first: IStory, second: IStory) => initialViewedStories.includes(first.id) - initialViewedStories.includes(second.id))])
    }
  },[stories, activeStoryIndex === -1])

  useEffect(() => {
    if (stories.length > 4) {
      setIsShowRightArrow(true)
    } else setIsShowRightArrow(false)
  }, [stories.length])

  useEffect(() => {
    if (activeStoryIndex === -1)
      return
    const story = sortStories[activeStoryIndex]
    if (!story)
      return
    if (tmpViewedStories.includes(story.id))
      return
    setTmpViewedStories([...tmpViewedStories, story.id])
  }, [stories, activeStoryIndex])

  const controlsDivRef = useRef<HTMLDivElement | null>(null)
  const overlayDivRef = useRef<HTMLDivElement | null>(null)
  const backgroundDivRef = useRef<HTMLDivElement | null>(null)
  const curPlayingStory = useRef({
    playing: false,
    duration: -1,
    storyIndex: -1,
    video: null as HTMLVideoElement | null,
    image: null as HTMLImageElement | null,
    timeoutHandle: null as any,
    progressIntervalHandle: null as any,
    delayedCleanups: {} as any
  }).current
  const isMobileScreen = useWindowResize({cvt: (w) => w < 520})
  const storyPlayingClass = isMobileScreen ? 'story-playing-mobile' : 'story-playing'

  const storyProgressBarRef = useRef<HTMLDivElement | null>(null)

  function stopProgressBarAnimations() {
    if (storyProgressBarRef.current) {
      for (const anim of storyProgressBarRef.current.getAnimations()) {
        anim.cancel()
      }
    }
  }

  function handleOnProgress(duration: number, progress: number, paused: boolean) {
    if (storyProgressBarRef.current) {
      duration = Math.max(duration, 1)
      progress = Math.min(progress, duration)
      storyProgressBarRef.current.style.width = `${progress / duration * 100}%`
    }
  }

  function resetStoryProgress() {
    if (storyProgressBarRef.current) {
      storyProgressBarRef.current.style.width = '0%'
      stopProgressBarAnimations()
    }
  }

  function handleOnProgressVideo(video: HTMLVideoElement) {
    if (video && video === curPlayingStory.video) {
      const {currentTime, duration} = video;
      const isVideoPlaying = (video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2)
      if (video.buffered.length > 0 && video.buffered.end(video.buffered.length-1) >= currentTime) {
        handleOnProgress(duration * 1000, currentTime * 1000, !isVideoPlaying)
      }
    }
  }

  function handleOnPlayVideo(video: HTMLVideoElement) {
    video.currentTime = 0;
  }

  function setShowingFullscreen(noscroll: boolean) {
    if (noscroll) {
      document.body.classList.add("noscroll")
      document.querySelector("html")?.classList.add("noscroll")
      backgroundDivRef.current?.classList.remove("hidden")
      controlsDivRef.current?.classList.remove("hidden")
      overlayDivRef.current?.classList.remove("hidden")
    } else {
      document.body.classList.remove("noscroll")
      document.querySelector("html")?.classList.remove("noscroll")
      backgroundDivRef.current?.classList.add("hidden")
      controlsDivRef.current?.classList.add("hidden")
      overlayDivRef.current?.classList.add("hidden")
    }
  }

  function endPlaying(isSwitchBetweenStories: boolean = false) {
    if (!curPlayingStory.playing)
      return

    let delayedActions: any[] = []
    let storyElement: null | HTMLElement = null
    let parentNode = document.getElementById(`story-container-${curPlayingStory.storyIndex}`)
    if (curPlayingStory.video) {
      const video = curPlayingStory.video
      curPlayingStory.video.pause()
      storyElement = curPlayingStory.video
      curPlayingStory.video = null
      // force show thumbnail
      // delayedActions.push(() => {
      //   const videoSrc = video.src
      //   video.src = ""
      //   window.setImmediate(() => video.src = videoSrc)
      // })
    }
    if (curPlayingStory.image) {
      const story = sortStories[curPlayingStory.storyIndex]
      const image = curPlayingStory.image
      storyElement = curPlayingStory.image
      delayedActions.push(() => {
        image.src = story?.preview
      })
      curPlayingStory.image = null
    }
    delayedActions.push(() => {
      if (storyElement) {
        storyElement.classList.remove(storyPlayingClass)
      }
      if (storyElement && parentNode) {
        parentNode.appendChild(storyElement)
      }
    })
    if (curPlayingStory.timeoutHandle) {
      clearTimeout(curPlayingStory.timeoutHandle)
      curPlayingStory.timeoutHandle = null
    }
    if (curPlayingStory.progressIntervalHandle) {
      clearInterval(curPlayingStory.progressIntervalHandle)
      curPlayingStory.progressIntervalHandle = null
    }
    if (!isSwitchBetweenStories) {
      setShowingFullscreen(false)
      setActiveStoryIndex(-1)
      curPlayingStory.playing = false
      resetStoryProgress();
    }

    if (storyElement) {
      const storyElementId = storyElement.id
      let prevCleanupTimeoutHandle = curPlayingStory.delayedCleanups[storyElementId]
      if (prevCleanupTimeoutHandle) {
        clearTimeout(prevCleanupTimeoutHandle)
        console.log("cleared")
      }
      if (isSwitchBetweenStories) {
        curPlayingStory.delayedCleanups[storyElementId] = setTimeout(() => {
          for (let f of delayedActions) f()
          curPlayingStory.delayedCleanups[storyElementId] = null
        }, 300)
      } else {
        curPlayingStory.delayedCleanups[storyElementId] = null
        for (let f of delayedActions) f()
      }
    }
  }

  function startPlayingVideo(video: HTMLVideoElement, index: number) {
    const wasPlaying = curPlayingStory.playing
    if (curPlayingStory.video === video) {
      return
    }

    endPlaying(true)
    setActiveStoryIndex(index)
    curPlayingStory.playing = true
    curPlayingStory.video = video
    curPlayingStory.storyIndex = index
    curPlayingStory.duration = video.duration * 1000
    setShowingFullscreen(true)
    resetStoryProgress()

    curPlayingStory.timeoutHandle = setTimeout(() => {
      curPlayingStory.timeoutHandle = null
      video.pause()
      video.play().then(() => {
        if (curPlayingStory.timeoutHandle)
          return
        // открываем видео на весь экран с небольшой задержкой после начала проигрывания,
        // чтобы избежать мелькания
        curPlayingStory.timeoutHandle = setTimeout(() => {
          backgroundDivRef.current?.appendChild(video)
          video.classList.remove(storyPlayingClass)
          video.classList.add(storyPlayingClass)
        }, wasPlaying ? 200 : 0)
      }).catch(() => {
        // Auto-play was prevented
        console.log("play error");
      });

    }, 0)
  }

  function startPlayingImage(image: HTMLImageElement, index: number) {
    if (curPlayingStory.image === image) {
      return
    }
    endPlaying()
    setActiveStoryIndex(index)
    const IMAGE_PLAYING_DURATION = 9500

    curPlayingStory.playing = true
    curPlayingStory.image = image
    curPlayingStory.storyIndex = index
    setShowingFullscreen(true)
    image.classList.remove(storyPlayingClass)
    image.classList.add(storyPlayingClass)
    image.src = sortStories[index].media
    curPlayingStory.duration = IMAGE_PLAYING_DURATION

    backgroundDivRef.current?.appendChild(image)
    curPlayingStory.timeoutHandle = setTimeout(() => {
      handleStoryEndedPlaying(image)
    }, IMAGE_PLAYING_DURATION + 500)

    resetStoryProgress()

    let beginAtTime = Date.now()
    curPlayingStory.progressIntervalHandle = setInterval(() => {
      if (curPlayingStory.image === image) {
        handleOnProgress(IMAGE_PLAYING_DURATION, Date.now() - beginAtTime, false)
      }
    }, 500)
  }

  function playStoryAtIndex(idx: number) {
    if (idx < 0 || idx >= sortStories.length)
      return false
    const nextStoryImg = document.getElementById(`story-image-${idx}`)
    const nextStoryVideo = document.getElementById(`story-video-${idx}`)
    if (nextStoryVideo) {
      startPlayingVideo(nextStoryVideo as HTMLVideoElement, idx)
      return true
    } else if (nextStoryImg) {
      startPlayingImage(nextStoryImg as HTMLImageElement, idx)
      return true
    }
    return false
  }

  function playNextStory() {
    if (curPlayingStory.playing) {
      if (!playStoryAtIndex(curPlayingStory.storyIndex + 1)) {
        endPlaying()
      }
    }
  }

  function playPrevStory() {
    if (curPlayingStory.playing) {
      if (!playStoryAtIndex(curPlayingStory.storyIndex - 1)) {
        endPlaying()
      }
    }
  }

  function handleStoryEndedPlaying(elem: HTMLElement) {
    if (curPlayingStory.video === elem || curPlayingStory.image === elem) {
      playNextStory()
    }
  }

  // завершить проигрывание при выходе из страницы
  useEffect(() => () => endPlaying(false), [])


  //стили
  const viewedStoryClass = ''
  const notViewedStoryClass = 'md:border-4 border-2 border-main'
  const baseStoryClass = `p-1 lg:w-[239px] lg:h-[309px] md:w-[142px] md:h-[185px] w-[95px] h-[120px] overflow-hidden lg:rounded-[23px] rounded-[15px] bg-no-repeat object-center object-cover`
  const minWidthStory = 'lg:min-w-[269px] lg:min-h-[309px] md:min-h-[185px] md:min-w-[162px] min-w-[105px] min-h-[120px]'

  const closeIconPosition = isMobileScreen
    ? 'top-7 right-[18px]'
    : 'top-[calc(50vh-min(90vh,900px)/2-15px)] right-[calc(50vw-min(60vh,600px)/2-15px)]'

  const arrowRightIconPosition = isMobileScreen
    ? 'right-0'
    : 'right-[calc(50vw-min(60vh,600px)/2-50px)]'

  const arrowLeftIconPosition = isMobileScreen
    ? 'left-0'
    : 'left-[calc(50vw-min(60vh,600px)/2-50px)]'

  const buttonLinkPosition = isMobileScreen
    ? 'fixed bottom-5 left-[calc(50vw-45vw)] w-[90vw]'
    : 'absolute bottom-[calc(50vh-min(90vh,900px)/2+20px)] left-[calc(50vw-min(60vh,600px)*0.45)] w-[calc(min(60vh,600px)*0.9)]'

  const progressBarPosition = isMobileScreen
    ? 'absolute top-5 left-[calc(50vw-45vw)] w-[90vw]'
    : 'absolute top-[calc(50vh-min(90vh,900px)/2+20px)] left-[calc(50vw-min(60vh,600px)*0.45)] w-[calc(min(60vh,600px)*0.9)]'

  const loadingPosition = isMobileScreen
    ? 'w-[100vw] h-[100vh]'
    : 'absolute top-[calc(50vh-min(90vh,900px)/2)] left-[calc(50vw-min(60vh,600px)/2)] h-[min(90vh,900px)] w-[min(60vh,600px)] border-2 border-main xs:rounded-[20px]'

  const highlightSvg = isMobileScreen
    ? 'active:fill-main'
    : 'hover:fill-main'

  return (
    <>
      <div className={'hidden fixed top-0 left-0 w-screen h-screen z-[200] bg-[#0c0c0cc7] backdrop-blur-[2em]'} ref={backgroundDivRef}>
        {/*------loader-----*/}
        <div className={`${loadingPosition} flex justify-center items-center `}>
          <Preloader/>
        </div>
      </div>
      <div className={'hidden fixed top-0 left-0 w-screen h-screen z-[300]'} ref={controlsDivRef}>

        {/*------progress bar-----*/}
        <div className={`${progressBarPosition} h-[5px] bg-orderbtn rounded-full`}>
          <div className={'bg-main h-full rounded-full story-progress-bar'} ref={storyProgressBarRef}></div>
        </div>

        {/*------arrow prev-----*/}
        <div onClick={playPrevStory} className={`${activeStoryIndex > 0? 'block':'hidden'} xs:w-[25px] w-[60px] h-full absolute ${arrowLeftIconPosition} flex justify-start`} >
          <button>
            <ArrowIcon
              className="xs:w-[25px] xs:h-[25px] w-[20px] h-[15px] rotate-180"
              colorClassName={`fill-white ${highlightSvg} duration-100`}
            />
          </button>
        </div>

        {/*------arrow next-----*/}
        <div onClick={playNextStory} className={`${activeStoryIndex === sortStories?.length - 1? 'hidden':'block'} xs:w-[25px] w-[60px] h-full absolute ${arrowRightIconPosition} flex justify-end`} >
          <button>
            <ArrowIcon
              className="xs:w-[25px] xs:h-[25px] w-[20px] h-[15px] rotate-0"
              colorClassName={`fill-white ${highlightSvg} duration-100`}
            />
          </button>
        </div>

        {/*------close icon-----*/}
        <div
          onClick={(event) => {
            event.stopPropagation()
            endPlaying()
          }}
          className={` w-[40px] h-[40px] absolute ${closeIconPosition} flex justify-center items-center`}>
          <div className={` cursor-pointer w-[20px] h-[20px] bg-main rounded-full flex justify-center items-center`}>
          <CloseIcon
            onClick={() => endPlaying()}
            colorClassName={"fill-white"}
          />
        </div>
        </div>

        {/*------button-----*/}
        {sortStories[activeStoryIndex]?.hasLink && <a className={`${buttonLinkPosition}`} href={sortStories[activeStoryIndex]?.url || ''} target="_blank">
          <BaseButton className={`w-full bg-main text-white`}>
            {sortStories[activeStoryIndex]?.textLink}
          </BaseButton>
        </a>}
      </div>


      <div className={`${sortStories.length > 0 ? `mt-[30px]`:''} relative ${!sliderInit && 'opacity-0 pointer-events-none'}`}>
        <div className={'hidden absolute z-[150] bg-light dark:bg-dark top-0 left-0 w-full h-full'} ref={overlayDivRef}></div>
         <button
          className={`${isShowLeftArrow? 'lg:block' : 'lg:hidden'} absolute hidden h-full top-0 left-[-70px]`}
          onClick={() => slider.current?.slickPrev()}
        >
          <div className="rotate-180">
            <ArrowIcon className="w-[13px] h-[21px]" />
          </div>
        </button>

        <Slider {...settingsSlider} ref={slider}
          afterChange={(currentSlide) => {
            if(currentSlide === 0){
              setIsShowLeftArrow(false)
            } else{
              setIsShowLeftArrow(true)
            }
            if (currentSlide >= sortStories.length - 2){
              setIsShowRightArrow(false)
            } else if(sortStories.length <= 4 ){
              setIsShowRightArrow(false)
            } else {
              setIsShowRightArrow(true)
            }
          }}
        >
          {sortStories.map((story: any, index: number) => (
            <div  key={ story.id } className={ `lg:pr-[30px] md:pr-[20px] pr-[10px] focus:outline-none relative` }>
              <div
                className={ `relative` }
                id={ `story-container-${ index }` }
              >
                {
                  checkIsVideo(story) ?
                    <>
                      <video
                        playsInline
                        id={ `story-video-${ index }` }
                        key={ `${ story.id }` }
                        className={ `${
                          viewedStories && viewedStories.includes(story.id)
                            ? viewedStoryClass
                            : notViewedStoryClass
                        } ${ baseStoryClass }` }
                        src={ story.media }
                        poster={ story.preview }
                        onClick={ (e) => {
                          e.preventDefault()
                          startPlayingVideo(e.target as HTMLVideoElement, index)
                        } }
                        loop={ false }
                        onEnded={ (e) => {
                          if (e.target === curPlayingStory.video) {
                            handleStoryEndedPlaying(e.target as HTMLElement)
                          }
                        } }
                        onTimeUpdate={ (e) => {
                          handleOnProgressVideo(e.target as HTMLVideoElement)
                        } }
                        onPlay={ (e) => {
                          handleOnPlayVideo(e.target as HTMLVideoElement)
                        } }

                      />

                      {story?.preview && <img src={story.preview} alt="" className="absolute left-0 top-0 w-full h-full pointer-events-none rounded-[15px] p-1"/>}
                    </> :
                    <>
                      <link rel="prefetch" href={ story.media }></link>

                      <img
                        src={ story.preview }
                        id={ `story-image-${ index }` }
                        alt={ '' }
                        key={ `${ story.id }` }
                        className={ `${
                          viewedStories && viewedStories.includes(story.id)
                            ? viewedStoryClass
                            : notViewedStoryClass
                        } ${ baseStoryClass }` }
                        onClick={ (e) => {
                          e.preventDefault()
                          startPlayingImage(e.target as HTMLImageElement, index)
                        } }/>
                    </>
                }
              </div>
            </div>
          )) }
        </Slider>

        <button
          className={ `${ isShowRightArrow ? 'lg:block' : 'lg:hidden' } absolute hidden h-full top-0 ${ isSafari ? 'right-[-70px]' : 'right-[-50px]' }` }
          onClick={ () => slider.current?.slickNext() }
        >
          <ArrowIcon className="w-[13px] h-[21px]"/>
        </button>
      </div>
    </>
  );
}
