"use client";

import { Outlet } from "react-router-dom";
import { AppContextWrapper } from './contexts/App';
import MetricsContextWrapper from "./contexts/MetricsContext";

export default function AppLayout() {
  return (
    <AppContextWrapper>
      <MetricsContextWrapper>
        <Outlet/>
      </MetricsContextWrapper>
    </AppContextWrapper>
  )
}
