import React, { useEffect, useLayoutEffect, useRef, useState, } from 'react'
import ImagesSlider from './ImagesSlider';
import Tooltip from './Tooltip';
import Tags from './Tags';
import Description from './Description';
import AdditionalProducts from './AdditionalProducts';
import OptionsGroup from './OptionsGroup';
import Sku from './Sku';
import ArrowIcon from '../../../../icons/ArrowIcon';
import Slider from 'react-slick';
import { useAppContext } from '../../../../../contexts/App';
import { useCartContext } from '../../../../../contexts/CartContext';
import { useAddressContext } from '../../../../../contexts/AddressContext';
import { useProductDetailCardContext } from '../../../../../contexts/ProductDetailCardContext';
import { useLoaderContext } from '../../../../../contexts/LoaderContext';
import { useAnimationProductsCartContext } from '../../../../../contexts/AnimationCartContext';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { apiClient } from '../../../../../libs/api/apiClient';
import { OptionProductType } from '../../../../../types/cart';
import { useDetectorBrowser } from '../../../../../hooks/useDetectorBrowser';
import PopupActionSheet from '../../../../common/popup/PopupActionSheet';
import { BaseButton } from '../../../../common/button/BaseButton';
import AddressSelectorPopup from '../../../../common/popup/citites/AddressSelector';
import CloseIcon from '../../../../icons/CloseIcon';
import { formatCurrency } from '../../../../../libs/helpers/formatCurrency';
import CrossedOldPriceIcon from '../../../../icons/CrossedOldPriceIcon';
import Counter from '../../../../common/button/Counter';
import { Preloader } from '../../../../common/preloader';
import { useLocation, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';


export default function DetailProduct() {
  const { branch, address, city, company } = useAppContext()
  const { cart, addProduct, addProductServer,  getProductCart, updateProduct, updateProductServer, setNoAddressWarning, noAddressWarning } = useCartContext()
  const { successSelectCallback, setSuccessSelectCallback } = useAddressContext()
  const { closeDetail } = useProductDetailCardContext()
  const setLoader = useLoaderContext()
  const {addProductToAnimation} = useAnimationProductsCartContext()
  const {updateUser, user} = useAuthContext()
  const [searchParams, setSearchParams] = useSearchParams();

  const [product, setProduct] = useState<any>(null)
  const [mainProduct, setMainProduct] = useState<any>(null)
  const [productId, setProductId] = useState<any>(null)
  const [isOpenNoAddressModal, setIsOpenNoAddressModal] = useState(false)
  const [isHaveBranchPickupPoints, setIsHaveBranchPickupPoints] = useState(false)
  const [isAuthDialogOpen, setAuthDialogOpen] = useState(false)
  const [showDescription, setShowDescription] = useState(false)
  let location = useLocation();

  // когда варнинг открывается во время открытой модалки - закрыть модалку и открыть выбор адреса
  useEffect(() => {
    //тост там по умолчанию в addProduct поэтому проходит условие и модалка открывается через эту штуку
    if (noAddressWarning === 'toast' && productId) {
      setNoAddressWarning(null)
      setIsOpenNoAddressModal(true)
      // setNoAddressWarning('popup')
      closeDetail()
    }
  }, [noAddressWarning])

  useEffect(() => {
    let productInQuery = searchParams.get('product')
    if (productInQuery) {
      setProductId(productInQuery)
    } else {
      setProductId(null)
    }
    setLoader(false)
  }, [searchParams])

  useEffect(() => {
    if (productId) {
      setProduct(null)
      apiClient.catalog.getProduct(branch.id, productId).then(({data, status}: any) => {
        if (status !== 200) {
          closeDetail()
          toast.error(`Товар не найден`, { 
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: company.template.theme,
          });
          return
        } 
        setProduct(data)
        setMainProduct(data)
      })
    } else {
      setProduct(null)
    }
  }, [productId])

  const [productCart, setProductCart] = useState<any>(null)
  const [options, setOptions] = useState<OptionProductType[]>([])

  useEffect(() => {
    if (product) {
      setProductCart(getProductCart(product.id, options))
    } else {
      setProductCart(null)
    }
  }, [product, cart, options])

  useEffect(() => {
    setOptions([])
  }, [product]) 

  function addOption(id: number, groupId: number|string, price: number, title: string): void {
    let tempOptions:OptionProductType[] = JSON.parse(JSON.stringify(options))
    let option = tempOptions.filter(item => (item.id === id && item.groupId === groupId))[0]
    if (option) {
      option.count++
      setOptions(tempOptions)
    } else {
      setOptions([...options, {groupId, id, count: 1, price, title }])
    }
  }

  function removeOption(id: number|string, groupId: number|string) {
    let tempOptions:OptionProductType[] = JSON.parse(JSON.stringify(options))
    let option = tempOptions.filter(item => (item.id === id && item.groupId === groupId))[0]
    
    if (option && option.count > 1) {
      option.count--
      setOptions(tempOptions)
    } else {
      setOptions(tempOptions.filter((item) => (item.id !== id || item.groupId !== groupId)))
    }
  }

  function changeProduct(product: any) {
    setProduct(product)
  }

  const [minLimitAllOptionGroup, setMinLimitAllOptionGroup] = useState(false)

  useEffect(() => {
    if (product) {
      let temp = true
      let optionsGroupsWithMinLimit = product.optionsGroups.filter((group: any) => group.minSelect)
      optionsGroupsWithMinLimit.forEach((group: any) => {
        let count = options.filter((option: OptionProductType) => option.groupId === group.id)?.reduce((count: number, option: OptionProductType) => count + option.count, 0)
        temp = temp && (count >= group.minSelect)
      });
      setMinLimitAllOptionGroup(temp)
    }
  }, [product, options])

  useEffect(() => {
    if (!!productId) {
      document.body.classList.add('overflow-y-hidden');
    } else {
      document.body.classList.remove('overflow-y-hidden');
    }
  }, [!!productId])

  useEffect(() => {
    const currentBranch = city.branches?.find((branchItem: any)=> branchItem.id === branch.id)
    currentBranch && setIsHaveBranchPickupPoints(Boolean(currentBranch.pickupPoints?.length))
  }, [city]);


  const isSafari = useDetectorBrowser().includes('safari')
  const isYandex = useDetectorBrowser().includes('yandex')

  let paddingBottom = () => {
    if (isYandex) return 'pb-[170px]'
    if (isSafari) return 'pb-[140px]'
    return 'pb-[81px]'
  }

  let [slider, setSlider] = useState<any>()
  let [sliderControll, setSliderControll] = useState<any>()
  let scrollWrapperMobile = useRef<any>(null)
  let [scrollTop, setScrollTop] = useState(180)

  const isLightTheme = company?.template?.theme !== 'dark';

  // useEffect(() => {
  //   if (scrollWrapperMobile.current && product) {
  //     // scrollWrapperMobile.current.scrollTo(0, 180)
  //   }
  // }, [scrollWrapperMobile, product])

  const [addressPopupActive, setAddressPopupActive] = useState(false)
  const [typeForSelectorAddress, setTypeForSelectorAddress] = useState('')

  const [load, setLoad] = useState(false)

  return (
    <>
    <PopupActionSheet isActive={isOpenNoAddressModal} width='500px' close={() => setIsOpenNoAddressModal(false)} closeIcon>
     <div className='flex flex-col'>
      <p className='text-2xl font-bold mb-[10px] text-dark dark:text-light'>Какой у вас адрес?</p>
      <p className='text-gray-40 mb-[20px]'>Хотим убедиться, что вы в зоне доставки. Адрес сохраним для будущих заказов.</p>
      <div className='flex flex-col gap-y-3 mb-[20px]'>
        <BaseButton
          className='bg-main text-white font-bold'
          onClick={()=> {
            setTypeForSelectorAddress('delivery')
            setAddressPopupActive(true)
            setIsOpenNoAddressModal(false)
          }}
        >
          Указать адрес доставки
        </BaseButton>
        {isHaveBranchPickupPoints &&
          <BaseButton
            className='bg-orderbtn text-main font-bold'
            onClick={()=> {
              setTypeForSelectorAddress('pickup')
              setAddressPopupActive(true)
              setIsOpenNoAddressModal(false)
            }}
          >
            Выбрать точку самовывоза
          </BaseButton>
        }

      </div>
      {Boolean(!user) &&
        <div className='flex justify-center gap-x-3 font-bold'>
          <p>
            Уже есть аккаунт?
          </p>
          <p className='text-main cursor-pointer'
             onClick={() => {
               setAuthDialogOpen(true)
               setIsOpenNoAddressModal(false)
             }}>
            Войти
          </p>
        </div>
      }

     </div>
    </PopupActionSheet>

    <AddressSelectorPopup
      isActive={addressPopupActive}
      setActive={setAddressPopupActive}
      type={typeForSelectorAddress}
    />

      {/* Декстоп */}
      <div className={"fixed z-[110] overflow-y-auto no-scroll-bar overflow-x-hidden w-full max-w-screen h-screen top-0 left-0 hidden md:block " + (!productId && 'md:hidden')}>
        <div className={`relative w-full flex justify-center  items-center min-h-screen p-4 `}>
          <div onClick={closeDetail} className='absolute w-full h-full min-h-screen top-0 left-0 bg-[#bdbdbd9e] dark:bg-[#0c0c0cc7]'></div>
          <div
            className={`relative w-full max-w-[893px] pt-[20px] px-[20px] rounded-[29px] bg-white dark:bg-gray-50 shadow-[4px_4px_20px_rgba(0,0,0,0.07)] transition-all`}>
            <div onClick={closeDetail} className={"absolute top-[0px] flex justify-center items-center  right-[0px] rounded-full  w-[20px] h-[20px] bg-main cursor-pointer"}>
              <CloseIcon
                colorClassName={"fill-white"}
                className={"w-[10px] h-[10px] "}
              />
            </div>
            {
              product ?
              <div>
                <div className='flex gap-[15px] md+:gap-[40px]'>
                  <ImagesSlider product={product}/>

                  <div className='grow flex flex-col h-[395px]'>
                    
                    <div className={'px-[5px]'}>
                      <div className='flex items-center justify-between text-gray-50 dark:text-gray-10'>
                        <div className='font-bold text-[22px] '>{ product.title }</div>

                        {
                          product.foodValuePerHundredGram.energyValue ||  product.foodValuePerHundredGram.protein || product.foodValuePerHundredGram.fat || product.foodValuePerHundredGram.carbohydrate ?
                          <Tooltip product={product}/> : <></>
                        }
                      </div>

                      <div className='font-bold text-gray-30 flex items-center gap-4'>
                        {
                          !!(product.weight || product.size || product.volume) &&
                          <>
                          { !!product.weight && <div>{ product.weight } г.</div> }
                          { !!product.size && <div>{ product.size } { product.measure_unit }</div> }
                          { !!product.volume && <div>{ product.volume } мл.</div> }
                          </>
                        }

                      </div>

                    </div>

                    <div className='px-[5px] grow pretty-scroll pb-4 overflow-y-auto'>
                      <Tags product={product}/>

                      <Description product={product} mainProduct={mainProduct} showDescription={showDescription} setShowDescription={setShowDescription}/>

                      <Sku skuGroups={mainProduct.allSkuGroupsWithValues} products={[mainProduct, ...mainProduct.childGoods]} product={product} setProduct={changeProduct}/>

                      {
                        !!product.optionsGroups?.length &&
                        <div className='mt-[15px]'>
                          {
                            product.optionsGroups.map((optionsGroup: any) => (
                              <OptionsGroup options={options} addOption={addOption} removeOption={removeOption} key={optionsGroup.id} optionsGroup={optionsGroup}/>
                            ))
                          }
                        </div>
                      }
                    </div>

                    <div className='bg-gradient-to-r from-gray-20/20 via-gray-20 to-gray-20/20 dark:from-gray-50/50 dark:via-gray-40 dark:to-gray-50/50 min-h-[2px]'></div>
                    
                    
                    <div className={'flex items-center justify-between px-[5px] pt-[15px] w-full'}>
                      { 
                        (!!product.oldPrice && product.stockBalance !== 0 && product.isActive && !productCart || (!product?.canBuyForFree && !product?.price)) &&
                          <div className='font-bold'>
                            <div className='flex items-center gap-1 text-xl leading-[24px] dark:text-gray-10'>{formatCurrency(branch.extended.currency.code, product.price + options.reduce((sum, option) => sum + option.price * option.count, 0))}</div>
                              {
                                !!product.oldPrice &&
                                <div className='relative w-min text-sm leading-[17px] text-gray-50 dark:text-gray-30'>
                                  <div className='flex items-center gap-1 opacity-60'> {formatCurrency(branch.extended.currency.code, product.oldPrice + options.reduce((sum, option) => sum + option.price * option.count, 0))}</div>
                                  <div className='absolute w-full h-full left-0 top-0'>
                                    <CrossedOldPriceIcon width={'100%'} height={'auto'}/>
                                  </div>
                                </div>
                              }
                          </div>
                      }

                      {
                        !!(product?.canBuyForFree && !product?.price || product?.price) &&
                        <>
                          {
                            product.stockBalance === 0 || !product.isActive ?
                            <BaseButton className='w-full bg-orderbtn !font-bold text-main' disabled>Будет позже</BaseButton>:
                            <>
                            {
                              !!productCart ?
                              <Counter
                                count={productCart.count}
                                add={() => {
                                  if (location.pathname.split('/').pop() === 'cart') {
                                    setLoad(true)
                                    updateProductServer(productCart.uid, {count: productCart.count + 1}).then(() => setLoad(false))
                                  } else {
                                    updateProduct(productCart.uid, {count: productCart.count + 1})
                                  }
  
                                  if(address){
                                    addProductToAnimation(product.id)
                                  }
                                }}
                                remove={() => {
                                  updateProduct(productCart.uid, {count: (product.minCount && product.minCount == productCart.count) ? 0 : productCart.count - 1})
                                  if (location.pathname.split('/').pop() === 'cart') {
                                    setLoad(true)
                                    updateProductServer(productCart.uid, {count: (product.minCount && product.minCount == productCart.count) ? 0 : productCart.count - 1}).then(() => setLoad(false))
                                  } else {
                                    updateProduct(productCart.uid, {count: (product.minCount && product.minCount == productCart.count) ? 0 : productCart.count - 1})
                                  }
                                }}
                                className='!w-full !h-[40px] bg-orderbtn border-none'
                                disabledAdd={product.stockBalance && productCart.count >= product.stockBalance}
                                price={formatCurrency(branch.extended.currency.code, product.price + options.reduce((sum, option) => sum + option.price * option.count, 0))}
                                loading={load}
                              /> :
                              <div 
                                onClick={event => {
                                  event.stopPropagation()
                                  if (location.pathname.split('/').pop() === 'cart') {
                                    setLoad(true)
                                    addProductServer(product.id, options, product.minCount).then(() => setLoad(false))
                                  } else {
                                    addProduct(product.id, options, product.minCount)
                                  }
  
                                  closeDetail()
                                  if(address){
                                    addProductToAnimation(product.id)
                                  } else {
                                    setSuccessSelectCallback(() => () => {
                                      addProduct(product.id, options, product.minCount, {}, false)
                                      addProductToAnimation(product.id)
                                    })
                                  }
                                }} 
                                className={`px-10 h-10 ${!product.oldPrice && 'w-full'} rounded-[18px] bg-main hover:opacity-80 text-white font-bold flex items-center justify-center cursor-pointer ${(!minLimitAllOptionGroup || (!product.canBuyForFree && product.price === 0)) && 'opacity-50 pointer-events-none'}`}
                              >
                                {
                                  load ? 
                                  <Preloader countOfDot={3} color={'white'} size={'8px'} className={'min-w-[122px] gap-2'}/> :
                                  <>
                                    {
                                      !!product.oldPrice ? 
                                      <span className='hidden md+:block'>Добавить в корзину</span> :
                                      <span className='hidden md+:flex items-center gap-1'><div>Добавить в корзину за  {formatCurrency(branch.extended.currency.code, product.price + options.reduce((sum, option) => sum + option.price * option.count, 0))} </div></span>
                                    }
                                  </>
                                }
                                <span className='md+:hidden block'>Добавить</span>
                              </div>
                            }
                            </>
                          }
                        </>
                      }
                      
                    </div>
                  </div>
                </div>
                
                <AdditionalProducts product={product}/>
              </div>
              :
              <div className="h-[400px] flex items-center justify-center"><Preloader size='10px'/></div>
            }
          </div>
        </div>
      </div>

      {/* Мобилка */}
      <div className={"fixed z-[110] w-screen h-screen bg-white dark:bg-gray-50 top-0 left-0 md:hidden block " + (!productId && 'hidden')}>
        <div className={'w-full h-full flex flex-col'}>
          {
            product ?
            <>
              <div onClick={closeDetail} className={`flex items-center justify-center top-3 left-3 w-10 h-10 cursor-pointer absolute z-[200] bg-white dark:bg-gray-50 rounded-full  shadow-[0px_0px_12px_#00000030] mt-3 `}>
                <div className='rotate-180'><ArrowIcon className='w-2 h-[14px]'/></div>
              </div>

              <div style={{opacity: scrollTop < 250 ? 1 : (scrollTop < 450 ? (450 - scrollTop) / 450 : 0)}} className='absolute w-full duration-75'>
                <div className='m-auto max-w-[395px] w-full md:min-w-[395px] md:w-[395px] product-detail-slider'>
                  {
                    !![product.image, ...product.images.map((img: any) => img.link)].length && 
                    <>
                      <Slider
                        dots={true}
                        arrows={false}
                        infinite={false}
                        ref={(ref) => setSlider(ref)}
                        asNavFor={sliderControll}
                      >
                        {
                          [product.image, ...product.images.map((img: any) => img.link)].map((img: string) => (
                            <img className='' key={img} src={img} alt="" />
                          ))
                        }
                      </Slider>
                    </>
                  }
                </div>
              </div>
              
              <div ref={scrollWrapperMobile} onScroll={() => setScrollTop(scrollWrapperMobile.current.scrollTop)} className={'z-[100] grow no-scroll-bar overflow-y-auto'}>
                <div className={`opacity-0 w-full`}>
                  <div className='m-auto max-w-[395px] w-full md:min-w-[395px] md:w-[395px] product-detail-slider'>
                    {
                      !![product.image, ...product.images.map((img: any) => img.link)].length && 
                      <>
                        <Slider
                          dots={true}
                          arrows={false}
                          infinite={false}
                          ref={(ref) => setSliderControll(ref)}
                          asNavFor={slider}
                        >
                          {
                            [product.image, ...product.images.map((img: any) => img.link)].map((img: string) => (
                              <img className='rounded-2xl' key={img} src={img} alt="" />
                            ))
                          }
                        </Slider>
                      </>
                    }
                  </div>
                </div>
                
                <div style={{
                  overflowX: 'hidden',
                  backdropFilter: 'blur(34px)',
                  background: isLightTheme? 'rgba(255, 255, 255, 0.8)' : '#161415',
                  WebkitBackdropFilter: 'blur(34px)',
                }} className={`px-[18px] rounded-t-2xl min-h-[50%] ${paddingBottom()} `}
                >

                  <div className='w-full flex items-center text-gray-50 dark:text-gray-10 '>
                    <div className='grow mt-[10px]'>
                      <div className='font-bold text-[18px] '>{ product.title }</div>
                      {
                        !!(product.weight || product.size || product.volume)  &&
                        <div className='font-bold text-sm text-gray-30 flex gap-2'>
                          { product.weight && <div>{ product.weight } г.</div> }
                          { product.size && <div>{ product.size } { product.measure_unit }</div> }
                          { product.volume && <div>{ product.volume } мл.</div> }
                        </div>
                      }
                    </div>
                    {product.foodValuePerHundredGram.energyValue ||  product.foodValuePerHundredGram.protein || product.foodValuePerHundredGram.fat || product.foodValuePerHundredGram.carbohydrate?
                      <div className='w-[44px] h-[44px] flex items-center justify-center'>
                        <Tooltip product={product}/>
                      </div>
                      :
                      <></>
                    }

                  </div>


                  <Tags product={product}/>

                  <Description product={product} mainProduct={mainProduct} showDescription={showDescription} setShowDescription={setShowDescription}/>
                    <Sku skuGroups={mainProduct.allSkuGroupsWithValues} products={[mainProduct, ...mainProduct.childGoods]} product={product} setProduct={changeProduct}/>

                  {
                    !!product.optionsGroups?.length &&
                    <div className='mt-[5px] md:mt-[15px]'>
                      {
                        product.optionsGroups.map((optionsGroup: any) => (
                          <OptionsGroup options={options} addOption={addOption} removeOption={removeOption} key={optionsGroup.id} optionsGroup={optionsGroup}/>
                        ))
                      }
                    </div>
                  }
                          

                  <div className={'md:pb-8 pb-0'}>
                    <AdditionalProducts product={product} className={'!mt-0'}/>
                  </div>
                </div>

              <div className={`w-full px-[18px] bg-white dark:bg-dark flex items-center justify-between fixed left-0 bottom-0 z-50 pt-5 pb-7`}>
                <div className='bg-gradient-to-r w-full absolute top-0 left-0 from-gray-20/20 via-gray-20 to-gray-20/20 dark:from-gray-50/50 dark:via-gray-40 dark:to-gray-50/50 min-h-[2px]'></div>
                  <div className='font-bold'>
                    {
                      !!product.oldPrice && product.stockBalance !== 0 && product.isActive && !productCart &&
                      <>
                        <div className='flex items-center gap-1 dark:text-gray-10'>{formatCurrency(branch.extended.currency.code, product.price + options.reduce((sum, option) => sum + option.price * option.count, 0))}</div>
                        <div className='relative w-min text-sm leading-[17px] text-gray-50 dark:text-gray-30'>
                          <div className='flex items-center gap-1 opacity-60'>{formatCurrency(branch.extended.currency.code, product.oldPrice + options.reduce((sum, option) => sum + option.price * option.count, 0))}</div>
                          <div className='absolute w-full h-full left-0 top-0'>
                            <CrossedOldPriceIcon width={'100%'} height={'auto'}/>
                          </div>
                        </div>
                      </>
                    }
                  </div>

                  {
                    product.stockBalance === 0 || !product.isActive ?
                    <BaseButton className='w-full bg-orderbtn !font-bold text-main' disabled>Будет позже</BaseButton>:
                    <>
                      {
                        !!productCart ?
                        <Counter
                            count={productCart.count}
                            add={() => {
                              if (location.pathname.split('/').pop() === 'cart') {
                                setLoad(true)
                                updateProductServer(productCart.uid, {count: productCart.count + 1}).then(() => setLoad(false))
                              } else {
                                updateProduct(productCart.uid, {count: productCart.count + 1})
                              }

                              if(address){
                                addProductToAnimation(product.id)
                              }
                            }}
                            remove={() => {
                              if (location.pathname.split('/').pop() === 'cart') {
                                setLoad(true)
                                updateProductServer(productCart.uid, {count: (product.minCount && product.minCount == productCart.count) ? 0 : productCart.count - 1}).then(() => setLoad(false))
                              } else {
                                updateProduct(productCart.uid, {count: (product.minCount && product.minCount == productCart.count) ? 0 : productCart.count - 1})
                              }
                            }}
                            className='!w-full !h-[40px] bg-orderbtn border-none'
                            disabledAdd={product.stockBalance && productCart.count >= product.stockBalance}
                            price={formatCurrency(branch.extended.currency.code, product.price + options.reduce((sum, option) => sum + option.price * option.count, 0))}
                          />:
                          <div
                            onClick={event => {
                              event.stopPropagation()
                              if (location.pathname.split('/').pop() === 'cart') {
                                setLoad(true)
                                addProductServer(product.id, options, product.minCount).then(() => setLoad(false))
                              } else {
                                addProduct(product.id, options, product.minCount)
                              }
                              
                              closeDetail()
                              if(address){
                                addProductToAnimation(product.id)
                              } else {
                                setSuccessSelectCallback(() => () => {
                                  addProduct(product.id, options, product.minCount, {}, false)
                                  addProductToAnimation(product.id)
                                })
                              }
                            }}
                            className={`px-8 md:px-10 h-10 rounded-[18px] bg-main text-white font-bold flex items-center justify-center cursor-pointer ${!product.oldPrice && 'w-full' }  ${(!minLimitAllOptionGroup || (!product.canBuyForFree && product.price === 0)) && 'opacity-50 pointer-events-none'}`}
                          >
                            {
                              !!product.oldPrice ?
                                <span className=''>Добавить в корзину</span> :
                                <span className='flex gap-1 items-center xxs:text-base text-sm'><div><span className=' xxs:inline'>Добавить в корзину за</span> {formatCurrency(branch.extended.currency.code, product.price + options.reduce((sum, option) => sum + option.price * option.count, 0))} </div></span>
                            }
                          </div>
                      }
                    </>
                  }
                </div>
              </div>
            </> :
            <div className='flex items-center justify-center h-full bg-white dark:bg-dark'><Preloader size='10px'/></div>
          }
        </div>
      </div>    
    </>
  )
}
