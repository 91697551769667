import React, { createContext, useContext, useEffect, useState, } from 'react'
import { useLoaderContext } from './LoaderContext';
import DetailProduct from '../components/pages/mainPage/Catalog/detailProduct';
import { useSearchParams } from 'react-router-dom';


interface ContextType {
  openDetail: (productId: number|string) => void;
  closeDetail: () => void;
}

const ProductDetailCardContext = createContext<ContextType>({
  openDetail: () => {},
  closeDetail: () => {}
})

export function ProductDetailCardContextWrapper({children}: any) {
  const setLoader = useLoaderContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const closeDetail = () => {
    setLoader(true);

    setSearchParams(params => {
      params.delete("product");
      return params;
    });
  }

  const openDetail = (productId: number|string) => {
    setLoader(true);

    setSearchParams(params => {
      params.set("product", productId + '');
      return params;
    });
  }
  
  return (
    <ProductDetailCardContext.Provider value={{openDetail, closeDetail}}>
      {children}
      <DetailProduct/>
    </ProductDetailCardContext.Provider>
  )
}



export function useProductDetailCardContext() {
  return useContext(ProductDetailCardContext)
}
