import Logo from "../../images/logoFooter.svg";
import bridge from "@vkontakte/vk-bridge";
import NotLinkedToCrm from "./VkPageNotLinkedToCrm";
import { BaseButton } from "./button/BaseButton";


export default function NotFoundInVkGroup({vkLaunchParams}: any){
  if (vkLaunchParams?.vk_group_id) return <NotLinkedToCrm/>

  return <>
    <div style={{background: 'white'}}
          className={"flex flex-col items-center sm:items-start justify-center h-screen w-full px-5 sm:px-10"}>
      <div className={"flex flex-col-reverse sm:flex-row justify-center w-full items-center"}>
        <div className={"flex flex-col gap-y-3 text-dark sm:pr-5"}>
          <p className={"text-2xl font-medium"}>
            Приложение для заказов ВКонтакте
          </p>
          <p className={"text-xl font-medium"}>
            <span className={'text-[#55B27A]'}>Удобное</span> оформление заказа в <span className={'text-[#55B27A]'}>вашем сообществе</span> ВКонтакте
          </p>
        </div>
        <img src={Logo} className={"w-[300px] h-[100px]"} alt={""}/>
      </div>
      <div className={"w-full text-dark text-sm sm:text-[15px] mt-6"}>
        <div className={"flex flex-col gap-y-2"}>
          <p>Приложение позволяет полностью автоматизировать процесс приёма <br/> и обработки заказов.</p>
          <p>Пользователи могут оформить заказ внутри вашего сообщества, работать<br/>
            с корзиной, рассчитывать стоимость покупки с доставкой, выбирать подарки к заказу<br/>
            и доп. товары на выбор.</p>
        </div>

      </div>
      <div className={"mt-7"} style={{width: '250px'}}>
        <BaseButton
          onClick={() => {
            bridge.send("VKWebAppAddToCommunity").then((res)=> {
              console.log('added app to community', res)
            })
          }}
          className={"w-full bg-[#55B27A] hover:bg-[#D6EDDF] hover:text-dark text-white"}>Подключить сообщество
        </BaseButton>
      </div>

    </div>
  </>
}


