export function resizeImage(url: string, size: string|number = 0, extension: string = 'jpg') {
  let name = url.split('.').slice(0, -1).join('.')
  
  if (url.lastIndexOf('jpg') !== -1) {
    name = url.substring(0, url.lastIndexOf('.jpg'))
  } else if (url.lastIndexOf('png') !== -1) {
    name = url.substring(0, url.lastIndexOf('.png'))
  } else if (url.lastIndexOf('webp') !== -1) {
    name = url.substring(0, url.lastIndexOf('.webp'))
  } 

  if (!size) {
    return `${name}.${extension}`
  }

  return `${name}-${size}.${extension}` 
}

export function resizeImages(url: string, size: number[] = [0], extension: string[] = ['jpg']) {
  if (!url) {
    return []
  }

  let name = url.split('.').slice(0, -1).join('.')
  
  if (url.lastIndexOf('jpg') !== -1) {
    name = url.substring(0, url.lastIndexOf('.jpg'))
  } else if (url.lastIndexOf('png') !== -1) {
    name = url.substring(0, url.lastIndexOf('.png'))
  } else if (url.lastIndexOf('webp') !== -1) {
    name = url.substring(0, url.lastIndexOf('.webp'))
  } 

  return extension.reduce((acc: any, ext: string) => {
    acc[ext] = size.reduce((acc: any, s: number) => {
      acc[s] = s ? `${name}-${s}.${ext}` : `${name}.${ext}`
      return acc
    }, {})
    return acc
  }, {})
}