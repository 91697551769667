import React, { useState, useRef, useEffect } from "react";
import { useAppContext } from "../../contexts/App";
import { sendEvent, useVisitorContext } from "../../contexts/VisitorContext";
import { DaysOfWeekDictionary } from "../../constants/daysOfWeek";
import PopupActionSheet from "../../components/common/popup/PopupActionSheet";
import { AboutMap } from "../../components/pages/aboutPage/aboutMap";
import { formatOpenHours } from "../../libs/helpers/formatOpenHours";
import { apiClient } from "../../libs/api/apiClient";
import { Preloader } from "../../components/common/preloader";
import { useMetricsContext } from "../../contexts/MetricsContext";


interface IOpenHours {
  monday: {active: boolean, hours: string[]}
  tuesday: {active: boolean, hours: string[]}
  wednesday: {active: boolean, hours: string[]}
  thursday: {active: boolean, hours: string[]}
  friday: {active: boolean, hours: string[]}
  saturday: {active: boolean, hours: string[]}
  sunday: {active: boolean, hours: string[]}
}

type Day = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday'
 
interface IPickupPoints {
  code: string,
  id: string,
  lat: string,
  lon: string,
  title: string
}

interface IContacts {
  phone: string,
  description: string
}

interface IBranch {
  addresses: string[],
  android_app_link: string | null,
  city: string,
  contacts: IContacts[],
  description: string,
  id: number
  ios_app_link: string | null,
  openHours: IOpenHours,
  pickupPoints: IPickupPoints[] | [],
  short_description: string,
  telegram_link: string | null | undefined,
  title: string,
  vk_communities: any[] | null
}

interface ICompanyInfo {
  address: string,
  bank:string,
  bik: string,
  paymentAccount: string | null,
  correspondentAccount: string | null,
  branches: IBranch[],
  company_name: string,
  company_name_plural: string | null,
  description: string,
  email: string,
  id: number,
  inn: string,
  kpp: string,
  ogrn: string,
  phone: string,
  hide_details: boolean
}
interface Props {
  company: ICompanyInfo
}

export default function About() {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [shouldSetFocusOnCurrentBranch, setShouldSetFocusOnCurrentBranch] = useState<boolean>(false)
  const { branch } = useAppContext();
  const { metricsEvent } = useMetricsContext()
  const { visitorId } = useVisitorContext()
  const refDescription = useRef<any>(null)
  const [companyInfo, setCompanyInfo] = useState<any>(null)
  const [currentBranch, setCurrentBranch] = useState<any>(null)
  const [otherBranch, setOtherBranch] = useState<any>(null)
  const [isTherePickupPoints, setTherePickupPoints] = useState(false)

  useEffect(() => {
    apiClient.about.get().then(({data}: any) => {
      setCompanyInfo(data)
      setTherePickupPoints(!!data.branches.filter((b: any) => b.id === branch.id)[0].pickupPoints.length)
      setCurrentBranch(data.branches.find((item: IBranch) => item.id === branch.id))
      setOtherBranch(data.branches.filter((item: IBranch) => item.id !== branch.id))
    })
  }, [])

  useEffect(() => {
    if (refDescription.current && currentBranch) {
      refDescription.current.innerHTML = currentBranch?.description
    }
  }, [refDescription, currentBranch])

  useEffect(() => {
    if (visitorId) {
      sendEvent('section-visited', {target: 'О нас'})
    }
  }, [visitorId])

  if (!companyInfo) return <>
    <div className="container">
      <div className="md:mt-[20px] md+:mb-[35px] mb-[25px]">
        <h2 className="md:text-4xl font-bold text-2xl text-dark dark:text-light">О нас</h2>
      </div>
      <div className={'py-[20vh]'}>
        <Preloader countOfDot={4}/>
      </div>
    </div>
  </>

  return (
    <>
      <div className="container">
        <div className="md:mt-[20px] mb-[25px] md:mt-0 mt-[15px]">
          <h2 className="md:text-4xl font-bold text-2xl text-dark dark:text-light">О нас</h2>
        </div>
        <div 
          className="sm:mb-[50px] mb-[30px] break-words text-gray-50 dark:text-white md:text-base text-sm whitespace-pre-wrap [&_a]:text-main [&_ul]:list-disc [&_li]:ml-5 [&_ol]:list-decimal"
          ref={refDescription}>
          
        </div>
        <div className="mb-[30px]">
          <h2 className="md:text-2xl font-bold text-xl text-dark dark:text-light">
            Филиал на{" "}
            <span className={`text-main ${isTherePickupPoints && 'cursor-pointer'} hover:opacity-80`} onClick={() => {
              if (isTherePickupPoints) {
                setShouldSetFocusOnCurrentBranch(true);setIsOpenModal(true)
              }
            }}>
              {currentBranch?.addresses.length ? currentBranch?.addresses[0] : ''}
            </span>
          </h2>
        </div>
        <div className="mb-[50px] flex w-[100%] sm:gap-[2%]  sm:flex-row flex-col">

          <div className={`px-[20px] py-[23px] md:w-[32%] w-[100%] bg-white dark:bg-gray-50 rounded-[29px] overflow-hidden shadow-md mb-[25px] md:mb-[0]`}>
            <h3 className={`md:text-[22px]  font-bold md:mb-[20px] mb-[13px] text-dark dark:text-light`}>
              Контакты
            </h3>

            {currentBranch?.contacts.map((item: IContacts, index: number) => (
              <div key={index} className="text-gray-50 dark:text-gray-10 mb-[20px]">
                <h4 className={`text-base font-semibold `}>
                  {item.description}:
                </h4>
                <a className="text-sm md:text-base" onClick={() => metricsEvent('phone')}>{item.phone}</a>
              </div>
            ))}

            {currentBranch?.vk_communities?
              <div className="text-gray-50 dark:text-gray-10 mb-[20px]">
                <h4 className={`text-base font-semibold `}>
                  Мы ВКонтакте:
                </h4>
                {currentBranch?.vk_communities.map((item: any) => (
                  <p key={item.key} className="break-all text-sm md:text-base text-main cursor-pointer">
                    <a target={"_blank"} href={`https://vk.com/public${item.groupId}`}>https://vk.com/public{item.groupId}</a>
                  </p>
                ))}
              </div>
              :
              <span></span>
            }
            
            {currentBranch?.telegram_link?
              <div className="text-gray-50 dark:text-gray-10 mb-[20px]">
                <h4 className={`text-base font-semibold `}>
                  Мы в Телеграм:
                </h4>
                <p className="text-sm md:text-base text-main cursor-pointer">
                  {currentBranch?.telegram_link &&
                    <a href={currentBranch?.telegram_link}>
                      {currentBranch?.telegram_link}
                    </a>
                  }
                </p>
              </div>
              :
              <span></span>
            }
            
          </div>

          <div className={`px-[20px] py-[23px] md:w-[32%] w-[100%] bg-white  dark:bg-gray-50 rounded-[29px] overflow-hidden shadow-md mb-[25px] md:mb-[0]`}>
            <h3 className={`md:text-[22px] font-bold md:mb-[20px] mb-[13px] text-dark dark:text-light`}>
              Режим работы
            </h3>
            {currentBranch?.openHours && Object.keys(currentBranch.openHours).map((key) => {
                return (
                  <p
                    className="flex text-gray-50 dark:text-gray-10 text-sm md:text-base"
                    key={key}
                  >
                    <span className="font-bold w-[22px]">
                      {DaysOfWeekDictionary[key as keyof typeof DaysOfWeekDictionary]}
                    </span>
                    <span className="ml-[20px]">
                      {currentBranch.openHours[key as keyof typeof currentBranch.openHours].active? 
                        currentBranch.openHours[key as keyof typeof currentBranch.openHours].hours
                        :
                        'Выходной'
                      }
                    </span>
                  </p>
                )
              } 
              )}
          </div>
          {
            !companyInfo.hide_details && 
            <div className={`px-[20px] py-[23px] md:w-[32%] w-[100%] bg-white dark:bg-gray-50 rounded-[29px] overflow-hidden shadow-md mb-[25px] md:mb-[0]`}>
              <h3 className={`md:text-[22px] font-bold md:mb-[20px] mb-[13px] text-dark dark:text-light`}>
                Реквизиты
              </h3>
              <div className="text-gray-50 dark:text-gray-10 text-sm md:text-base">
                {companyInfo.company_name &&
                  <p>Компания: {companyInfo.company_name}</p>
                }
                {companyInfo.inn &&
                  <p>ИНН: {companyInfo.inn}</p>
                }
                {companyInfo.kpp && (
                  <p>КПП: {companyInfo.kpp}</p>
                )}
                {companyInfo.ogrn &&
                  <p>ОГРН: {companyInfo.ogrn}</p>
                }
                {companyInfo.bik && (
                  <p>БИК: {companyInfo.bik}</p>
                )}
                {companyInfo.address &&
                  <p>Юридический адрес: {companyInfo.address}</p>
                }
                {companyInfo.bank &&
                  <p>Банк: {companyInfo.bank}</p>
                }
                {companyInfo.correspondentAccount && (
                  <p>Корр. счет: {companyInfo.correspondentAccount}</p>
                )}
                {companyInfo.paymentAccount && (
                  <p>Расчетный счет: {companyInfo.paymentAccount}</p>
                )}  
                {companyInfo.phone &&
                  <a onClick={() => metricsEvent('phone')}>Телефон: {companyInfo.phone}</a>
                }
                {companyInfo.email &&
                  <p>E-mail: {companyInfo.email}</p>
                }
              </div>
            </div>
          }
        </div>
        <PopupActionSheet
          isActive={isOpenModal}
          close={() => {setIsOpenModal(false)}}
          width="90%"
          closeIcon={true}
          classNamePopup="md:py-0 py-0 md:px-0 px-0 about"
          isMap
        >
          <AboutMap isOpenModal={isOpenModal} shouldFocusOnCurrentBranch={shouldSetFocusOnCurrentBranch} company={companyInfo} currentBranch={currentBranch}/>
        </PopupActionSheet>
        {!!otherBranch.length &&
          <>
            <div className="mb-[30px] flex gap-8 items-center sm:flex-row flex-col">
              <h2 className="text-2xl font-bold text-dark dark:text-light">Другие филиалы</h2>
              <button
                className="rounded-[18px] hover:bg-main hover:text-white bg-orderbtn font-bold  p-[9px] text-main md:w-auto w-[100%]"
                onClick={() => {setShouldSetFocusOnCurrentBranch(false);setIsOpenModal(true)}}
              >
                <span className="m-[15px] text-dark dark:text-light">Посмотреть на карте</span>
              </button>
            </div>
  
            <div className="mb-[50px] flex w-[100%] flex-wrap sm:gap-[2%] sm:flex-row flex-col">
              {otherBranch.map((branch: any) => {
                const openHours = formatOpenHours(branch);
                return (
                  <div
                    key={branch.id}
                    className="mb-[25px] md:w-[32%] w-[100%] p-[30px] bg-white dark:bg-gray-50 rounded-[29px] overflow-hidden shadow-md"
                  >
                    <p className="mb-[9px] text-dark dark:text-light">{branch.city}</p>
                    <h3 className="text-2xl font-bold text-main mb-[7px]">
                      {branch.title}
                    </h3>

                    {branch.addresses?.map((address: any, index: number) => (
                      <p className="text-gray-50 dark:text-gray-10" key={index}>
                        {address}
                      </p>
                    ))}

                    <div className="mt-[8px] mb-[6px] flex justify-between flex-wrap">
                      {Object.keys(openHours).map((key) => {
                        if(openHours[key]){
                          return (
                            <p
                              className="flex w-[100%] text-gray-50 dark:text-gray-10 xxs:text-sm md:text-base"
                              key={key}
                            >
                              <span className="font-bold xl:w-[20%] lg:w-[30%] md+:w-[25%] xs:w-[30%] w-[20%] text-nowrap">{key}</span>
                              <span className="ml-[10px]">{openHours[key]}</span>
                            </p>
                          )
                        }
                      })}
                    </div>

                    {branch.contacts.map((contact: any, index: number) => (
                      <a onClick={() => metricsEvent('phone')} className="text-gray-50 dark:text-gray-10 font-bold mt-[4px]" key={index}>
                        {contact.phone}
                      </a>
                    ))}
                  </div>
                );
              })}
            </div>
          </>
        }
        
      </div>
    </>
  );
}