import React, {useEffect, useMemo, useRef, useState} from "react";
import { useDetectorBrowser } from "../../../../hooks/useDetectorBrowser";
import GeomarkIcon from "../../../icons/GeomarkIcon";
import TextEllipsis from "../../util/TextEllipsis";
import ArrowIcon from "../../../icons/ArrowIcon";
import { Preloader } from "../../preloader";


export interface IInputSelector<T> {
  variants: {name: string, id: T}[],
  value: T | null,
  onChange: (id: T | null) => void,
  placeholder?: string,
  reset?: string,
  noFilter?: boolean,
  arrowSelect?: boolean,
  showArrowIcon?:boolean,
  arrowSelectIdxRef?: React.MutableRefObject<number>,
  closeOnSelection?: boolean,
  reverseEllipsis?: boolean,
  valueOverride?: string,
  className?: string,
  classNameActive?: string,
  classNameInactive?: string,
  inputElementId?:string,
  icon?: string,
  errors?: any,
  name?: string,
  customComponent?: (curVal: string | undefined, f: {
    show: boolean, setShow: (b: boolean) => void,
    showPreloader: boolean, setShowPreloader: (b: boolean) => void,
  }) => any,
}

export default function InputSelector<T>({
  variants,
  value,
  onChange,
  placeholder = '',
  valueOverride,
  reset,
  noFilter,
  arrowSelect = true,
  arrowSelectIdxRef,
  closeOnSelection = true,
  className = 'w-full',
  classNameActive = 'bg-white dark:bg-gray-30',
  classNameInactive = 'bg-gray-20 dark:bg-gray-40',
  icon='',
  showArrowIcon = true,
  customComponent,
  reverseEllipsis = false,
  name = '',
  errors = {},
}: IInputSelector<T>) {
  const [show, setShow] = useState(false)
  const [showPreloader, setShowPreloader] = useState(false)
  const [arrowSelectIdx, setArrowSelectIdx] = useState(-1)
  const ref: any = useRef(null);
  const onEnterPressedRef = useRef<any>(null);
  const isSafari = useDetectorBrowser().includes('safari')

  const error = errors[name]; 

  useEffect(() => {
    delete errors[name];
  }, [value]);

  if (arrowSelectIdxRef) arrowSelectIdxRef.current = arrowSelectIdx;
  onEnterPressedRef.current = () => {
    const variant = variants[arrowSelectIdx];
    if (variant) {
      onChange(variant.id)
      if (closeOnSelection) setShow(false)
      return true;
    }
  }

  useEffect(() => {
    function handleClick(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShow(false);
      }
    }
    function handleKeyDown({key}: any) {
      if (key === 'ArrowDown') {
        setArrowSelectIdx(x => Math.min(variants.length - 1, x + 1));
      } else if (key === 'ArrowUp') {
        setArrowSelectIdx(x => Math.max(x - 1, -1));
      } else if (key === 'Enter') {
        return onEnterPressedRef.current();
      }
    }

    if (show) document.addEventListener('mousedown', handleClick)
    if (show && arrowSelect) window.addEventListener('keydown', handleKeyDown)
    return () => {
      if (show) document.removeEventListener('mousedown', handleClick)
      if (show && arrowSelect) window.removeEventListener('keydown', handleKeyDown)
    }
  }, [show, setShow, arrowSelect, setArrowSelectIdx, variants.length, ref, onEnterPressedRef]);

  useEffect(() => {
    if (!show || !arrowSelect) setArrowSelectIdx(-1);
  }, [show, arrowSelect])

  let curVal = useMemo(() => {
    return value !== null ? variants.find(({id}) => id === value)?.name : null
  }, [value, variants])

  if (valueOverride) curVal = valueOverride;

  if (!reset && !noFilter) {
    variants = variants.filter(({id}) => id !== value);
  }

  const actualItemCount = (show ? variants.length + 1 : 0) + (showPreloader ? 1 : 0);
  return (
    <>
      <div className={`${show ? 'z-10' : 'z-5'} ${className}`}>
        <div className='h-9 w-full relative'>
          <div
            ref={ref}
            className={`absolute w-full overflow-hidden rounded-[10px] pretty-scroll2 cursor-pointer duration-300 border-[1px] border-transparent ${show ? 'z-10' : 'z-5'} ${error && 'border-yellow'} ${show && showArrowIcon ? `${classNameActive} shadow-md` : `${classNameInactive}`}`}
            style={{height: (actualItemCount > 0  && showArrowIcon ? `${2.25 * Math.min(actualItemCount, 4)}em` : '2.4em'), overflowY: 'auto'}}
          >
            {
              (customComponent && customComponent(curVal as string | undefined, {show, setShow, showPreloader, setShowPreloader})) ||
              <div
                onClick={() => {
                  if (show && reset) {
                    onChange(null);
                  }
                  setShow(!show)
                }}
                className={`w-full h-9 flex items-center justify-between ${reset && show && curVal ? 'hover:text-main' : ''} ${!curVal || reset ? 'text-gray-30' : ''}`}
              >
                  <div className={"flex items-center grow"}>
                    {icon == 'geoMark' && <div className={"ml-[15px]"}><GeomarkIcon className={'w-[15px]'}/></div>}
                      <TextEllipsis
                          className={`
                            ${icon === 'geoMark'
                                ? "ml-[10px]"
                                : 'ml-[25px]'
                            }
                            ${
                                !reverseEllipsis ?
                                  'text-ellipsis whitespace-nowrap overflow-hidden ' :
                                (isSafari ?
                                  `ellipsis reverse-ellipsis box duration-300 
                                    ${show && showArrowIcon 
                                        ? classNameActive 
                                        : classNameInactive}` 
                                  : 'text-ellipsis whitespace-nowrap overflow-hidden '
                                )
                              }
                              dark:text-gray-20 text-sm `}
                              style={reverseEllipsis && !isSafari ? {direction: 'rtl', textAlign: 'left'} : {}}>
                          <span>{reset && show && curVal ? reset : (curVal || placeholder)}</span>
                      </TextEllipsis>
                  </div>
                  {showArrowIcon && <div className='rotate-[90deg] mx-5'><ArrowIcon className='w-[10px] h-[10px]'/></div>}
              </div>
            }

            <div className={""}>
              {showPreloader && <Preloader countOfDot={4} size={'10px'} className={'h-9 gap-2'}/>}
              {
                show && variants.map(({name, id}, idx) => {
                  return (
                    <div
                      key={id + ''}
                      onClick={() => {
                        onChange(id);
                        if (closeOnSelection) setShow(false)
                      }}
                      className={`h-9 flex break-words items-center text-sm hover:text-main ${idx === arrowSelectIdx ? 'text-main' : id != null ? 'text-dark dark:text-light' : 'text-gray-30'} ${icon ? "ml-[38px]" : " ml-[25px]"}`}>
                      {name}
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>

      {error && (<span className={'text-yellow text-xs font-medium'}>{error}</span>)}
    </>
  )
}
