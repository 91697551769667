import {useEffect, useState} from "react";
import bridge from "@vkontakte/vk-bridge";
import { useAppContext } from "../contexts/App";

export function useVkScroll({isActive, contentHeight, padTop = 0, addTop = 0}: {isActive: any, contentHeight?: number, padTop?: number, addTop?: number}) {
  const {vkLaunchParams} = useAppContext()
  const isMobileVk = !!vkLaunchParams?.vk_platform?.includes("mobile")
  const isDesktopVk = !isMobileVk

  const VK_HEADER_HEIGHT = 100

  let [vkScrollTop, setVkScrollTop] = useState(-1)
  useEffect(() => {
    if (isActive && !isMobileVk) {
      const update = () => {
        bridge.send("VKWebAppScrollTop").then(({scrollTop}) => {
          scrollTop -= VK_HEADER_HEIGHT;
          if (addTop)
            scrollTop += addTop;
          if (contentHeight != null)
            scrollTop = Math.min(window.innerHeight - contentHeight, scrollTop)
          setVkScrollTop(Math.round(Math.max(scrollTop, 0)))
        })
      }
      update()
      const handle = setInterval(update, 1000);
      return () => clearInterval(handle)
    } else {
      setVkScrollTop(-1)
    }
  }, [!!isActive, isMobileVk, contentHeight])

  return { vkScrollTop: Math.max(vkScrollTop, padTop), vkScrollEnabled: vkScrollTop >= 0, isMobileVk, isDesktopVk }
}
