import React, { useRef, useState } from "react";
import Counter from "../button/Counter";
import { useAppContext } from "../../../contexts/App";
import { resizeImage } from "../../../libs/helpers/resizeImage";
import productImgStub from '../../../images/productImgStub.jpg'
import { OptionProductType } from "../../../types/cart";
import TrashCanIcon from "../../icons/TrashCanIcon";
import { formatCurrency } from "../../../libs/helpers/formatCurrency";


interface IProduct {
  productData: any,
  removeProduct?: (setLoad: (value: boolean) => void) => void,
  removeAllProduct?: (setLoad: (value: boolean) => void) => void,
  addProduct?: (setLoad: (value: boolean) => void) => void,
  isCart?: boolean,
  isLastProduct?: boolean,
}

export default function Product({productData, removeProduct, removeAllProduct, addProduct, isCart = false, isLastProduct = false} : IProduct) {
  const {branch} = useAppContext()
  const [load, setLoad] = useState(false)
  const infoWrapper = useRef()

  return (
    <>
     <div className='py-[25px] md:border-none md:p-[15px] md:bg-white dark:md:bg-gray-50 md:rounded-[29px] md:shadow-lg'>
      <div className="flex gap-5 md:gap-[30px]">
        {
          productData?.image ? 
          <div className='rounded-[17px] overflow-hidden h-[125px] min-w-[125px]'>
            <picture className={'h-[125px]'}>
              <source className={'h-[125px]'} type="image/webp" srcSet={`${resizeImage(productData.image, '125', 'webp')} 1x, ${resizeImage(productData.image, '300', 'webp')} 2x, ${resizeImage(productData.image, '400', 'webp')}3x`}/>    
              <source className={'h-[125px]'} type="image/jpeg" srcSet={`${resizeImage(productData.image, '125', 'jpg')} 1x, ${resizeImage(productData.image, '300', 'jpg')} 2x, ${resizeImage(productData.image, '400', 'jpg')} 3x`}/>
              <img 
                className={'h-[125px]'}
                src={resizeImage(productData.image, '125', 'jpg')} 
                srcSet={`${resizeImage(productData.image, '125', 'jpg')} 1x, ${resizeImage(productData.image, '300', 'jpg')} 2x, ${resizeImage(productData.image, '400', 'jpg')} 3x`}
                alt=""
              />
            </picture>
          </div> :
          <img
            className='w-[125px] h-[125px] rounded-[17px]'
            src={productImgStub} 
            alt=""
          />
        }

        <div className='flex flex-col grow'>
          <div className='flex md:items-center justify-between'>
            <div className='flex items-center flex-wrap gap-x-4 md:text-[20px] font-bold -tracking-[.01em] pr-[10px] md:pr-0'>
              <span className="text-dark dark:text-light">{ productData?.title }</span> 

              { 
                productData?.skuGroupsValues?.map((sky: any) => (
                  <span className='text-main' key={sky.valueId}>{ sky.title }</span>
                ))
              }

            </div>
            
            { removeAllProduct && !productData.isAdded && productData.isDeletable && <div onClick={() => removeAllProduct(setLoad)} className='cursor-pointer md:mr-3'><TrashCanIcon/></div> }
          </div>

          {
            !!productData?.options.length &&
            <div 
              className='text-xs text-gray-40 font-normal mb-1' 
              dangerouslySetInnerHTML={{__html: `+ ${ productData?.options.map((option: OptionProductType) => `${option.title}${option.count > 1 ? '&nbsp;x&nbsp;' + option.count : ''}`).join(', ') }`}}
            />
          }

          {
            !!(productData?.weight || productData?.size || productData?.volume) &&
            <div className={`flex gap-2 -tracking-[.01em] font-bold text-gray-30`}>
              { !!productData?.weight && <div>{ productData?.weight } г.</div> }
              { !!productData?.size && <div>{ productData?.size } { productData?.measure_unit }</div> }
              { !!productData?.volume && <div>{ productData?.volume } мл.</div> }
            </div> 
          }

          {isCart?
            <div className={`overflow-hidden text-sm grow mt-[11px] h-[17px] -tracking-[.01em]  leading-[17px] text-gray-50 dark:text-gray-10`}>
              <div className={'lines-ellipsis w-[170px] xs:w-auto'}>
                {productData.shortDescription}
              </div>
            </div>
            :
            <div className={`text-sm grow mt-[11px] -tracking-[.01em]  leading-[17px] text-gray-50 dark:text-gray-10`}>{ productData.shortDescription }</div>
          }

          {productData.comment && <div className='text-xs text-main mt-1'>{productData.comment}</div>}
          
          {
            productData?.discountsComments?.map((comment: string, index: number) => (
              <div key={index} className='text-xs text-main mt-1'>{comment}</div>
            ))
          }
            
          <div className={`flex xs:pt-1 md:flex-row gap-y-4 xs:mt-4 mt-2 md:mt-0  ${(productData?.count && !addProduct && !removeProduct ? 'gap-x-2' : 'justify-between md:items-center')}`}>
            <div className='flex items-center gap-1 text-lg xl:text-xl xl:leading-[24px] font-bold text-main'>
              {
                (!!productData.amount && !productData.isFree) ? 
                <>
                  {formatCurrency(branch.extended.currency.code, productData.amount)}
                </> 
                : 
                <>
                  {productData.isAdded?
                    'Прилагается к каждому заказу'
                    :
                    'Бесплатно'
                  }
                </>
              }
            </div>
            {
              (addProduct && removeProduct && !productData.isAdded && !productData.isFree && productData.isCountChangeable && (productData.canBuyForFree && !productData.price || productData.price)) ?
              <Counter
                count={productData.count}
                add={() => addProduct(setLoad)}
                remove={() => removeProduct(setLoad)}
                loading={load}
              /> : 
              <>
                {
                  (productData?.count && productData.count > 1) &&
                  <div className='text-xl text-main font-bold'>x {productData.count}</div>
                }
              </>
            }
          </div>
        </div>
      </div>
      
    </div>
      {!isLastProduct && <div
        className='md:hidden bg-gradient-to-r from-gray-20/20 via-gray-20 to-gray-20/20 dark:from-gray-50/50 dark:via-gray-40 dark:to-gray-50/50 min-h-[2px]'></div>}
    </>
   
  )
}

