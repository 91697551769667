import React from "react";

export default function DeliveryMapIcon({className='', colorClassName = "fill-additional", width=25, height=20}: {
  className?: string;
  colorClassName?: string;
  width?: number|string;
  height?: number|string;
}){
  return (
    <svg
      className={`${className} ${colorClassName}`}
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5428 14.7669C17.3019 14.7683 17.07 14.6755 16.8965 14.5083L12.9817 10.7227C12.3806 10.1478 11.9022 9.45705 11.5754 8.69218C11.2486 7.9273 11.0801 7.10415 11.0801 6.27237C11.0801 5.4406 11.2486 4.61745 11.5754 3.85257C11.9022 3.0877 12.3806 2.39695 12.9817 1.82201C14.2122 0.652273 15.8451 0 17.5428 0C19.2406 0 20.8735 0.652273 22.104 1.82201C22.7051 2.39695 23.1834 3.0877 23.5103 3.85257C23.8371 4.61745 24.0056 5.4406 24.0056 6.27237C24.0056 7.10415 23.8371 7.9273 23.5103 8.69218C23.1834 9.45705 22.7051 10.1478 22.104 10.7227L18.1891 14.5083C18.0157 14.6755 17.7837 14.7683 17.5428 14.7669ZM17.5428 1.84047C16.321 1.83299 15.1447 2.30352 14.2651 3.15158C13.8415 3.55357 13.5042 4.03756 13.2738 4.57409C13.0433 5.11061 12.9244 5.68844 12.9244 6.27237C12.9244 6.85631 13.0433 7.43414 13.2738 7.97066C13.5042 8.50719 13.8415 8.99118 14.2651 9.39317L17.5428 12.5601L20.8206 9.39317C21.2441 8.99118 21.5814 8.50719 21.8119 7.97066C22.0424 7.43414 22.1612 6.85631 22.1612 6.27237C22.1612 5.68844 22.0424 5.11061 21.8119 4.57409C21.5814 4.03756 21.2441 3.55357 20.8206 3.15158C19.9399 2.3052 18.7643 1.83495 17.5428 1.84047Z"
      />
      <path
        d="M7.38651 23.9991C7.22477 24.0002 7.06557 23.9588 6.92485 23.8791L0.461664 20.1858C0.320762 20.1045 0.203858 19.9873 0.122788 19.8463C0.0417178 19.7052 -0.000638641 19.5452 7.27836e-06 19.3825V2.76292C-0.000638641 2.60022 0.0417178 2.44024 0.122788 2.29918C0.203858 2.15812 0.320762 2.04099 0.461664 1.95964C0.602025 1.8786 0.761245 1.83594 0.92332 1.83594C1.0854 1.83594 1.24461 1.8786 1.38498 1.95964L7.84816 5.65289C7.98907 5.73424 8.10597 5.85137 8.18704 5.99243C8.26811 6.13349 8.31047 6.29347 8.30982 6.45617V23.0758C8.31047 23.2385 8.26811 23.3985 8.18704 23.5395C8.10597 23.6806 7.98907 23.7977 7.84816 23.8791C7.70745 23.9588 7.54825 24.0002 7.38651 23.9991ZM1.84663 18.847L6.4632 21.4877V6.99169L1.84663 4.35102V18.847Z"
      />
      <path
        d="M7.38621 24.0025C7.22447 24.0036 7.06527 23.9622 6.92455 23.8825C6.78365 23.8011 6.66675 23.684 6.58568 23.5429C6.50461 23.4018 6.46225 23.2419 6.4629 23.0792V6.45955C6.46225 6.29685 6.50461 6.13687 6.58568 5.99581C6.66675 5.85475 6.78365 5.73761 6.92455 5.65626L12.4644 2.4893C12.5704 2.42863 12.6873 2.38956 12.8084 2.37437C12.9295 2.35917 13.0525 2.36815 13.1701 2.40078C13.2877 2.43341 13.3977 2.48904 13.4937 2.56446C13.5897 2.63988 13.6698 2.73359 13.7294 2.84016C13.8497 3.05217 13.8814 3.30318 13.8174 3.53843C13.7534 3.77368 13.5989 3.97406 13.3877 4.09587L8.30952 6.99507V21.4911L13.3877 18.5826C13.4932 18.522 13.6096 18.4828 13.7303 18.4671C13.8509 18.4515 13.9735 18.4598 14.091 18.4915C14.2084 18.5232 14.3185 18.5777 14.4149 18.652C14.5112 18.7263 14.5921 18.8188 14.6527 18.9243C14.7133 19.0298 14.7526 19.1462 14.7682 19.2668C14.7838 19.3875 14.7756 19.51 14.7438 19.6275C14.7121 19.7449 14.6576 19.855 14.5833 19.9514C14.5091 20.0478 14.4165 20.1286 14.3111 20.1892L7.84787 23.8825C7.70715 23.9622 7.54795 24.0036 7.38621 24.0025Z"
      />
      <path
        d="M20.3133 23.9981C20.1515 23.9992 19.9923 23.9579 19.8516 23.8781L13.3884 20.1848C13.2475 20.1035 13.1306 19.9864 13.0495 19.8453C12.9685 19.7042 12.9261 19.5443 12.9268 19.3816V11.0718C12.9268 10.8269 13.024 10.592 13.1972 10.4189C13.3704 10.2457 13.6052 10.1484 13.8501 10.1484C14.095 10.1484 14.3298 10.2457 14.503 10.4189C14.6761 10.592 14.7734 10.8269 14.7734 11.0718V18.846L19.39 21.4867V11.9951C19.39 11.7502 19.4872 11.5153 19.6604 11.3422C19.8335 11.169 20.0684 11.0718 20.3133 11.0718C20.5581 11.0718 20.793 11.169 20.9661 11.3422C21.1393 11.5153 21.2366 11.7502 21.2366 11.9951V23.0748C21.2372 23.2375 21.1949 23.3975 21.1138 23.5386C21.0327 23.6796 20.9158 23.7967 20.7749 23.8781C20.6342 23.9579 20.475 23.9992 20.3133 23.9981Z"
      />
      <path
        d="M17.5434 9.22738C16.9955 9.22738 16.46 9.06492 16.0045 8.76056C15.549 8.45619 15.1939 8.02359 14.9843 7.51745C14.7746 7.01131 14.7198 6.45437 14.8267 5.91705C14.9335 5.37974 15.1974 4.88618 15.5847 4.4988C15.9721 4.11141 16.4657 3.8476 17.003 3.74072C17.5403 3.63385 18.0972 3.6887 18.6034 3.89835C19.1095 4.108 19.5421 4.46303 19.8465 4.91854C20.1509 5.37406 20.3133 5.9096 20.3133 6.45744C20.3133 7.19207 20.0215 7.89662 19.502 8.41608C18.9826 8.93555 18.278 9.22738 17.5434 9.22738ZM17.5434 5.53413C17.3608 5.53413 17.1822 5.58828 17.0304 5.68973C16.8786 5.79119 16.7602 5.93539 16.6903 6.1041C16.6205 6.27282 16.6022 6.45846 16.6378 6.63757C16.6734 6.81667 16.7614 6.98119 16.8905 7.11032C17.0196 7.23945 17.1841 7.32738 17.3632 7.36301C17.5424 7.39864 17.728 7.38035 17.8967 7.31047C18.0654 7.24059 18.2096 7.12224 18.3111 6.9704C18.4125 6.81857 18.4667 6.64005 18.4667 6.45744C18.4667 6.21256 18.3694 5.97771 18.1963 5.80456C18.0231 5.6314 17.7883 5.53413 17.5434 5.53413Z"
      />
    </svg>
  )
}
