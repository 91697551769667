export default function OneStartIcon({ className = 'xs:w-[30px] xs:h-[30px] w-[25px] h-[25px]', rateOfAllReviews }: { className?: string, rateOfAllReviews: any }) {
  return (
    <div>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        viewBox="0 0 35 32"
      >
        <defs>
          <linearGradient id="half_grad">
            <stop
              offset={`${(rateOfAllReviews / 5) * 100}%`}
              stopColor="#F2A716"
            />
            <stop
              offset={`${(rateOfAllReviews / 5) * 100}%`}
              stopColor="#BDBDBD"
              stopOpacity="1"
            />
          </linearGradient>
        </defs>
        <path
          d="M17.5 0L23.0546 9.85479L34.1435 12.0922L26.4875 20.4202L27.7862 31.6578L17.5 26.95L7.21376 31.6578L8.51252 20.4202L0.85651 12.0922L11.9454 9.85479L17.5 0Z"
          fill="url(#half_grad)"
        />
      </svg>
    </div>
  );
}
