import React from 'react'
import { useAppContext } from '../../../contexts/App'
import { Link } from 'react-router-dom'
import appStore from '../../../images/appStore.png'
import googlePlay from '../../../images/googlePlay.png'
import logoFooter from '../../../images/logoFooter.svg'
import VkIcon from '../../icons/VkIcon'
import TelegramIcon from '../../icons/TelegramIcon'
import { NotificationBlock } from '../../../contexts/VisitorContext'
import { useMetricsContext } from '../../../contexts/MetricsContext'


export default function Footer() {
  const { company, city, branch } = useAppContext()
  const { metricsEvent } = useMetricsContext()

  return (
    <div className='bg-gray-10 dark:bg-gray-50 shadow-[0px_-4px_80px_rgba(0,0,0,0.06)]'>
      <NotificationBlock place='footer'/>
      <footer className='container flex flex-col md:flex-row justify-between pt-5 pb-8 md:py-[50px] px-[15px] lg:px-[75px] bg-gray-10 dark:bg-gray-50'>
        <div className='md:hidden mb-[15px]  text-dark dark:text-white font-bold -tracking-[.02em]'>
          <div className='flex justify-between mb-1 mt-2'>
            <Link className={``} to={`/${city.slug}`}>Каталог</Link>
            <Link className={``} to={`/${city.slug}/about`}>О нас</Link>
            <Link className={``} to={`/${city.slug}/payment`}>Доставка</Link>
          </div>
          <div className='flex justify-between'>
            <Link className={``} to={`/${city.slug}/stock`}>Акции</Link>
            <Link className={``} to={`/${city.slug}/reviews`}>Отзывы</Link>
            <Link className={``} to={`/${city.slug}/payment`}>Оплата</Link>
          </div>
        </div>
        
        <div className='flex flex-col xl:flex-row xl:gap-10'>
          <div className="text-sm xl:text-base leading-[29px] flex flex-col">
            <Link to={`/${city.slug}/personalDataPolicy`} className='text-main underline'>Политика конфиденциальности</Link>
            <Link to={`/${city.slug}/termsOfUse`} className='text-main underline'>Пользовательское соглашение</Link>
          </div>

          <div className="text-sm xl:text-base leading-[29px] flex xs:flex-row xs:items-center xl:items-start flex-col xl:flex-col gap-1 xl:gap-0">
            <div className="dark:text-gray-10">Работает на технологии </div> 
            <Link to={process.env.REACT_APP_BASE_URL || '/'} target='_blank' className='text-additional underline xl:w-fit md+:w-[70px] w-[90px]'>
              <img src={logoFooter} className='xl:h-[18px] md+:h-[22px] xs:h-[29px] xl:w-fit lg:mt-1' alt='' />
            </Link>
          </div>
        </div>

        <div className='hidden xl:grid gap-x-[25px] 2xl:gap-x-[45px] grid-cols-[60px_60px_140px] text-dark dark:text-white font-bold -tracking-[.02em]'>
          <Link className={`hover:text-main dark:hover:text-main duration-300`} to={`/${city.slug}`}>Каталог</Link>
          <Link className={`hover:text-main dark:hover:text-main duration-300`} to={`/${city.slug}/about`}>О нас</Link>
          <Link className={`hover:text-main dark:hover:text-main duration-300`} to={`/${city.slug}/payment`}>Доставка</Link>
          <Link className={`hover:text-main dark:hover:text-main duration-300`} to={`/${city.slug}/stock`}>Акции</Link>
          <Link className={`hover:text-main dark:hover:text-main duration-300`} to={`/${city.slug}/reviews`}>Отзывы</Link>
          <Link className={`hover:text-main dark:hover:text-main duration-300`} to={`/${city.slug}/payment`}>Оплата</Link>
        </div>

        <div className='hidden md:grid xl:hidden  gap-x-[40px] grid-cols-[54px_130px] text-sm text-dark dark:text-white font-bold -tracking-[.02em]'>
          <Link className={`hover:text-main dark:hover:text-main duration-300`} to={`/${city.slug}`}>Каталог</Link>
          <Link className={`hover:text-main dark:hover:text-main duration-300`} to={`/${city.slug}/reviews`}>Отзывы</Link>
          <Link className={`hover:text-main dark:hover:text-main duration-300`} to={`/${city.slug}/stock`}>Акции</Link>
          <Link className={`hover:text-main dark:hover:text-main duration-300`} to={`/${city.slug}/payment`}>Доставка</Link>
          <Link className={`hover:text-main dark:hover:text-main duration-300`} to={`/${city.slug}/about`}>О нас</Link>
          <Link className={`hover:text-main dark:hover:text-main duration-300`} to={`/${city.slug}/payment`}>Оплата</Link>
        </div>

        <div className="flex gap-5 md:justify-start justify-between md:mt-0 mt-[30px]">
          <div className='flex lg:gap-5 gap-[10px] md:flex-col lg:flex-row'>
            {
              !!company?.iosAppLink &&
              <a href={company.iosAppLink} target='_blank' onClick={() => metricsEvent('app-icon')} className="bg-[#1C1C1B] px-[13px] w-[108px] lg:w-[125px] h-[35px] lg:h-[50px] flex items-center justify-center rounded-full">
                <img src={appStore} className='' alt='' />
              </a> 
            }
            {
              !!company?.androidAppLink &&
              <a href={company.androidAppLink} target='_blank' onClick={() => metricsEvent('app-icon')} className="bg-[#1C1C1B] px-[13px] w-[108px] lg:w-[125px] h-[35px] lg:h-[50px] flex items-center justify-center rounded-full">
                <img src={googlePlay} className='' alt='' />
              </a> 
            }
          </div>

          <div className='flex gap-2'>
            {
              (branch.extended?.vkCommunities?.length && branch.extended.vkCommunities[0]) &&
              <a href={`https://vk.com/club${branch.extended.vkCommunities[0].groupId}`} target='_blank' className="flex justify-center items-center w-[35px] lg:w-[50px] h-[35px] lg:h-[50px] rounded-full bg-gray-30 hover:bg-gray-40">
                <VkIcon className={'lg:w-[26px] w-[18px]'}/>
              </a>  
            }

            {
              !!branch?.telegramLink &&
              <a href={branch.telegramLink} target='_blank' className="flex justify-center items-center w-[35px] lg:w-[50px] h-[35px] lg:h-[50px] rounded-full bg-gray-30 hover:bg-gray-40">
                <TelegramIcon className={'lg:w-[26px] w-[18px]'}/>
              </a> 
            }
          </div>
        </div>
      </footer>
    </div>
  )
}

