import React, {useState, useEffect} from 'react'
import useDebounce from './useDebounce'
import { useAppContext } from '../contexts/App'
import { apiClient } from '../libs/api/apiClient'

export default function useSearch(setCatalog: (value: any) => void) {
  const { branch } = useAppContext()
  const [activeSearch, setActiveSearch] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [searchTags, setSearchTags] = useState<number[]>([])
  const [noSearchResult, setNoSearchResult] = useState(false)

  const debouncedSearch = useDebounce(searchQuery, 500);

  useEffect(() => {
    if (activeSearch) {
      apiClient.catalog.get(branch.id, {search: searchQuery.trim(), byTags: searchTags}).then(({data}) => {
        setCatalog(data?.catalog || [])

        if (data?.catalog?.reduce((productsCount: number, category: any) => productsCount + category.goods.length, 0)) {
          setNoSearchResult(false)
        } else {
          setNoSearchResult(true)
        }
      })
    }
  }, [debouncedSearch])

  useEffect(() => {
    if (!activeSearch) {
      setNoSearchResult(false)
    }
  }, [activeSearch])

  return {
    searchQuery,
    setSearchQuery,
    activeSearch,
    setActiveSearch,
    searchTags, 
    setSearchTags, 
    noSearchResult,
  }
}
