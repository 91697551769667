import React, {useEffect, useRef, useState} from "react";
import { useAuthContext } from "../../../../contexts/AuthContext";
import { useAppContext } from "../../../../contexts/App";
import useForm from "../../../../hooks/useForm";
import { localStorageWrap } from "../../../../libs/helpers/localStorageWrap";
import { apiClient } from "../../../../libs/api/apiClient";
import { ProfileLayout } from "../../../../components/layouts/profile";
import { TextInput, TextInputWithSave } from "../../../../components/common/form/input/TextInput";
import { TextInputPhone } from "../../../../components/common/form/input/TextInputPhone";
import { BaseButton } from "../../../../components/common/button/BaseButton";
import VkIcon from "../../../../components/icons/VkIcon";
import TgIcon from "../../../../components/icons/TgIcon";
import PopupActionSheet from "../../../../components/common/popup/PopupActionSheet";
import AuthPopup from "../../../../components/common/popup/auth/Auth";
import useAllowMessageVk from "../../../../libs/helpers/allowMessageVk";
import { useNavigate } from "react-router-dom";


export default function Profile() {
  const { user, updateUser, logout, isLoadingUser} = useAuthContext()
  const { city, branch, company, vkCommunityMessagesAllowed } = useAppContext()
  const navigate = useNavigate();

  const [authDialogType, setAuthDialogType] = useState('none')

  const [phone, setPhone] = useState(user?.phone)
  const [localPhone, setLocalPhone] = useState<string | null>(null)
  const [name, setName] = useState(user?.name)

  const {data, updateData, updateField, errors, updateErrors, submit} = useForm(JSON.parse(JSON.stringify(user || {})))

  const emailRef = useRef<any>(null)


  useEffect(() => {
    setLocalPhone(localStorageWrap.getItem('clientPhone'))
  }, [typeof localStorage === 'undefined' || localStorageWrap.getItem('clientPhone')])

  useEffect(() => {
    if (user && !isLoadingUser) {
      updateData(user);
    }
  }, [isLoadingUser, !!user?.token])

  const onSaveCallBackHandler = (name: string, value: string) => {
    updateData({...data, [name]: value})
    return apiClient.profileInfo.infoProfile({[name]: value})
      .then(({data, errors, status}: any) => {
        if (status === 200) {
          updateUser(data)
          updateData(data)
          setName(data.name)
          return true;
        }
        if(status === 422) {
          updateErrors(errors)
        }
        return false;
      })
  }

  let profileTg = data.socials?.find((c: any) => {
    return c.network == 'tg';
  })

  let profileVk = data.socials?.find((c: any) => {
    return c.network == 'vk';
  })

  const removeProfile = (network: string) => {
    apiClient.profileInfo.unlinkSocial({network}).then(({data}) => {
      if (data) {
        updateUser(data)
        updateData(data)
      }
    })
  }
  //--------------------------------------
  const [isMessageButton, setIsMessageButton] = useState(false)


  useEffect(() => {
    setIsMessageButton(!vkCommunityMessagesAllowed)
  }, [profileVk?.uid, vkCommunityMessagesAllowed, branch?.id])


  const [isSureAddBirthDate, setIsSureAddBirthDate] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const getEditAuxButton = (edit = false, setEdit : (x : any) => void, setDisabled: (x:boolean) => void) => {
    return <div 
      className="pl-2"
      onClick={() => {
        setEdit(!edit)
        setIsEdit(!edit)
        setIsSureAddBirthDate(!edit)
        setDisabled(edit)
        if(edit){
          onSaveCallBackHandler('birthDate', data.birthDate)
        }
      }}
    >{edit ? 'Сохранить' : 'Изменить'}</div>
  }


  return (
    <ProfileLayout name={name} phone={phone}>
      <div className={"flex w-full flex-col grow "}>
        {profileVk && <div className="relative top-[-6%]">
          {isMessageButton && <MessageVkButton closeButton={setIsMessageButton}/>}
        </div>
        }
        {!user?.phone_confirmed && <div className="relative top-[-6%]">
            <MessageNoConfirmUserPhone company={company} cb={()=>{
              setAuthDialogType('phone')
            }}/>
        </div>
        }
        <div className="flex flex-col gap-y-10">
        <div className={"flex w-full flex-col gap-y-5"}>
            <p className={"text-2xl font-medium  text-dark dark:text-light"}>Контактная информация</p>

            <div>
              <p className="text-gray-50 dark:text-gray-30 text-sm ml-3 mb-2">Имя пользователя</p>
              <TextInputWithSave
                name={'name'}
                value={data.name || ''}
                onChange={(value) => updateField('name', value)}
                placeholderInput={"Имя"}
                onSave={() => onSaveCallBackHandler('name', data.name)}
                errors={errors}
              />
            </div>
            
            <div>
              <p className="text-gray-50 dark:text-gray-30 text-sm ml-3 mb-2">Телефон</p>
              <TextInputPhone
                name={'phone'}
                value={data.phone || ''}
                onChange={(value) => updateField('phone', value)}
                placeholderInput={"Телефон"}
                getAuxElement={() => {
                  if (user?.phone_confirmed) {
                    return <span onClick={() => {
                      setAuthDialogType('phone')
                    }}>{'Изменить'}</span>
                  } else {
                      return <span onClick={() => {
                        setAuthDialogType('phone')
                      }}>{'Подтвердить'}</span>
                    }
                  }
                }
                disabled={authDialogType === 'phone'}
                onClick={(e) => {
                  setAuthDialogType('phone');
                  e.preventDefault()
                }}
                classNameInput={"cursor-pointer caret-transparent dark:text-gray-20 text-gray-40 "}
                className={"cursor-pointer"}
                errors={errors}
              />
            </div>
            
            <div>
              <p className="text-gray-50 dark:text-gray-30 text-sm ml-3 mb-2">Email</p>
              <TextInputWithSave
                inputRef={emailRef as any}
                name={'email'}
                type={'email'}
                value={data.email || ''}
                onChange={(value) => updateField('email', value)}
                placeholderInput={"Почта"}
                errors={errors}
                onSave={()=> {
                  const valid = emailRef.current?.checkValidity()
                  if (!valid) {
                    updateErrors({...errors, 'email': 'Некорректная почта'})
                    return false;
                  } else {
                    updateErrors({...errors, 'email': null})
                    return onSaveCallBackHandler('email', data.email)
                  }
                }}
              />
            </div>
            
            <div>
              <p className="text-gray-50 dark:text-gray-30 text-sm ml-3 mb-2">Дата рождения</p>
              <TextInput
                type={'date'}
                max={new Date(Date.now() - 3600 * 24 * 1000).toISOString().split('T')[0]}
                name={'birthDate'}
                value={data.birthDate && data.birthDate.split(".").reverse().join("-") || ''}
                onChange={(value) => updateField('birthDate', value)}
                getAuxElement={getEditAuxButton}
                errors={errors}
                allowEdit={!user?.birthDate}
                disabled={true}
                // placeholderInput={'дд.мм.гггг'}
              />
            </div>


          </div>
        </div>
         

      </div>

        <PopupActionSheet
          isActive={isSureAddBirthDate && isEdit}
          closeIcon
          close={() => setIsSureAddBirthDate(false)}
        >
          <p className="text-dark dark:text-light">Оповещаем вас о том, что дату рождения вы можете указать один раз, без возможности ее отредактировать!</p>
          <BaseButton className="w-full bg-orderbtn text-main mt-6" onClick={() => setIsSureAddBirthDate(false)}>Хорошо</BaseButton>
        </PopupActionSheet>

        <AuthPopup
          titleByMode={{'none': '', 'phone': (!user?.phone_confirmed? 'Подтвердить номер телефона':'Изменить номер телефона'), 'telegram': (!user?.phone_confirmed? 'Подтвердить номер телефона через Telegram':'Привязать профиль Telegram'),'vk':'Привязать профиль ВК'}}
          startingType={authDialogType}
          startingStep={'phoneNumber'}
          defaultPhone={data.phone?.length ? data.phone || '' : localPhone || ''}
          isActive={authDialogType !== 'none'}
          close={() => setAuthDialogType('none')}
          onSuccess={({ token, user}) => {
            setTimeout(() => setAuthDialogType('none'), 500)
            if (user) {
              if (token) {
                updateUser({...user, token})
              } else {
                updateUser(user)
              }
              setPhone(user.phone)
            }
            updateData(user)
          }}/>

        <AuthPopup
          titleByMode={{'none': '', 'phone': 'Введите номер телефона', 'telegram': 'Введите номер телефона через Telegram'}}
          startingType={'phone'}
          isActive={!user?.phone?.length}
          showCloseButton={false}
          phoneConfirmMode={'check'}
          startingStep={'phoneNumber'}
          close={() => navigate(`/${city.slug}`)}
          defaultPhone={data.phone?.length ? data.phone || '' : localPhone || ''}
          onSuccess={({ phone, user, token }) => {
            if (phone) {
              localStorageWrap.setItem('clientPhone', phone)
              updateUser({phone})
              updateData({phone})
              setPhone(phone)
            }
            if (user) {
              if (token) {
                updateUser({...user, token})
              } else {
                updateUser(user)
              }
              updateData(user)
            }
          }}
        />

    </ProfileLayout>
)}

//------------------------------------------------MINI PROFILE--------
interface IMiniProfile {
  name: string,
  link: string,
  type: string,
  addedProfile: () => void,
  removeProfile: () => void,
}

export function MiniProfile({name, link, type, addedProfile, removeProfile} : IMiniProfile) {
  return (<div className={"flex flex-row gap-x-4 items-end"}>

      <div className={"flex flex-col justify-end gap-y-1"}>
        <div className={"font-medium"}>{name}</div>
        <div className={"flex gap-x-4 xs:items-center xs:flex-row flex-col"}>
          <div className={"flex gap-x-2  items-center"}>
            <div className={""}>{type === 'vk'
              ? <div className={"relative h-[16px] w-[16px] rounded-full bg-[#45668E] flex items-center justify-center"}>
                <VkIcon className={"w-[10px] h-[10px] "} colorClassName={"fill-white"}/>
              </div>
              : <div className={"relative h-[16px] w-[16px] rounded-full bg-[#33ABDE] flex items-center justify-center"}>
                <TgIcon className={"w-[10px] h-[10px]"} colorClassName={"fill-white"}/>
              </div>}</div>
            <div className={"text-sm "}>{link}</div>
          </div>
          <div className={"flex flex-row gap-x-4"}>
            <div onClick={()=>addedProfile()} className={"text-main text-sm cursor-pointer"}>Изменить</div>
            <div onClick={()=>removeProfile()} className={"text-main text-sm cursor-pointer"}>Отвязать</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export function MessageVkButton({closeButton} : {closeButton: (v: boolean)=>void}) {
  const allowMessageVk = useAllowMessageVk()
  return <>
    <div
      className={"py-3 xs:text-base text-xs bg-white p-3 rounded-[20px] dark:text-white dark:bg-gray-50 shadow-md my-3"}>
      <p className={"mb-3"}>
        Разрешите нам присылать вам сообщения от имени сообщества Вконтакте.<br/>
        Мы будем уведомлять вас о статусе вашего заказа. Иногда будем рассказывать о наших акциях и
        скидках.
      </p>
        <BaseButton
          onClick={() => {
            allowMessageVk().then((res) => {
              if (res)
                closeButton(false)
            })
          }}
          className={"w-full xs:w-auto bg-orderbtn text-main hover:bg-main hover:text-white xs:text-base text-xs"}>
          Я согласен получать сообщения
        </BaseButton>
    </div>
  </>
}

export function MessageNoConfirmUserPhone({company, cb}: {company: any, cb: () => void}) {
  return (
  <div className={"py-3 xs:text-base text-xs bg-white p-3 rounded-[20px] dark:text-white dark:bg-gray-50 shadow-md my-3"}>
      <p className={"mb-3"}>
        {company.loyaltyProgram !== 'none' ? 'Подтвердите номер телефона, для того, чтобы начать пользоваться бонусными баллами' : 'Подтвердите номер телефона, для полноценной работы личного кабинета'}
      </p>
      <BaseButton
        onClick={cb}
        className={"w-full xs:w-auto bg-orderbtn text-main hover:bg-main hover:text-white xs:text-base text-xs"}>
        Подтвердить номер телефона
      </BaseButton>
  </div>)
}