import React from 'react'
import { BaseButton } from '../components/common/button/BaseButton'

export default function ServerError() {
  return (
    <div className='container flex items-center justify-center flex-col min-h-screen'>
      <div className='text-[32px] '>Что-то пошло не так</div>
      <div className='text-[16px] mb-3'>Попробуйте ещё раз</div>
      <BaseButton className={'bg-[#55B37A] text-white'} onClick={() => window.location.reload()}>Обновить страницу</BaseButton>
    </div>
  )
}
