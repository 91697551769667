import React, {useRef} from "react";
import Slider from "react-slick";
import { useAppContext } from "../../../contexts/App";
import { useAuthContext } from "../../../contexts/AuthContext";
import { Link, useLocation, useNavigate } from "react-router-dom";

export function NavSite () {
  const { city } = useAppContext()
  const { logout, user } = useAuthContext()
  const navigate = useNavigate();
  let location = useLocation();

  const links = [
    {text: 'Уведомления', to: 'account/notifications'},
    {text: 'Профиль', to: 'account/profile'},
    {text: 'Мои адреса', to: 'account/address'},
    {text: 'История заказов', to: 'account/history'},
    {text: 'Выход', to: '#', className: 'text-gray-30', onClick: (e: any) => { navigate(`/${city.slug}`); logout(); e.preventDefault(); }},
  ]

  return (
    <div className={"ml-5 xl:ml-10 justify-end gap-y-6 hidden md:flex"}>
      <div
        className={`sticky top-40 z-40 h-[200px] md+:w-[180px] w-auto`}>
        <div className={"flex flex-col gap-y-2 py-[18px] px-8 rounded-[20px] text-sm shadow-md bg-white dark:bg-gray-50"}>
          {
            links.slice(0 , links.length - 1).map(({text, to, className = 'whitespace-nowrap', onClick}, index) => {
              return <Link key={index}
                           className={className + (location.pathname.indexOf(to) !== -1 ? ' text-main' : 'text-dark dark:text-light')}
                           to={`/${city.slug}/${to}`}
                           onClick={onClick}
              >{text}</Link>
            })
          }
          {
            user?.unreadNotificationsCount && user?.unreadNotificationsCount > 0 ?
            <div className={"flex justify-center items-center absolute right-7 top-[20px] w-[18px] h-[18px] bg-main rounded-full"}>
              <p className={"text-white text-[9px]"}>{user?.unreadNotificationsCount > 99 ? '+99' : user?.unreadNotificationsCount}</p>
              {/* если уведомлений будет больше 100 сделать +99, иначе 1000 числа уже не влезают */}
            </div> : <></>
          }
        </div>
      </div>
    </div>
  )
}

export function NavPhone () {
  const { city } = useAppContext()
  const { logout, user } = useAuthContext()
  const slider = useRef<any>(null)
  const navigate = useNavigate();
  let location = useLocation();


  const settings = {
    className: "slider variable-width",
    slidesToScroll: 1,
    variableWidth: true,
    infinite: false,
    arrows: false,
  }

  const links = [
    {text: 'Уведомления', to: 'account/notifications'},
    {text: 'Профиль', to: 'account/profile'},
    {text: 'Мои адреса', to: 'account/address'},
    {text: 'История заказов', to: 'account/history'},
    {text: 'Выход', to: '#', className: 'text-gray-30', onClick: (e: any) => { navigate(`/${city.slug}`); logout(); e.preventDefault(); }},
  ]

  return (
    <div className={"relative flex justify-center flex-col md:hidden"}>
      <div className={"my-3 mx-[18px] sm:mx-[15px]"}>
        <Slider {...settings} ref={slider}>
          { 
            links.slice(0, links.length - 1) .map(({text, to, className = '', onClick}, index) => {
              return <div key={index}>
                <Link key={index}
                      className={"flex items-center gap-x-2 mr-[40px] " + className + (location.pathname.indexOf(to) !== -1 ? ' text-main' : 'text-dark dark:text-light')}
                      to={`/${city.slug}/${to}`}
                      onClick={onClick}
                >{text}
                { user?.unreadNotificationsCount && user?.unreadNotificationsCount > 0 ?
                  <div className={`${text !== 'Уведомления' && 'hidden'} flex justify-center items-center w-[19px] h-[19px] bg-main rounded-full`}>
                    <p className={"text-white text-[9px]"}>{user?.unreadNotificationsCount > 99 ? '+99' : user?.unreadNotificationsCount}</p>
                  </div>
                  :
                  <></>
                }
                    {/* если уведомлений будет больше 100 сделать +99, иначе 1000 числа уже не влезают */}
                </Link>
              </div>
            })
          }
        </Slider>
      </div>
      <hr className={"border-b-[1px] border-gray-20 dark:border-gray-50"}/>
    </div>
  )
}
