import React, { useState, useEffect, useRef } from 'react'
import { useAppContext } from '../../../contexts/App'
import { formatAddress } from '../../../libs/helpers/formatAddress'
import InputSelector from './input/InputSelector'


export default function PickupPointsSelector({ value, onChange, city, allPointCity=true, filterByCity=false, reverseEllipsis }: any) {
  const { address, branch } = useAppContext()

  const [pickupPoints, setPickupPoints] = useState<any>([])
  useEffect(() => {
    let temp: any = []
    if (allPointCity) {
      city.branches.forEach((branch: any) => {
        branch.pickupPoints?.forEach((pickupPoint: any) => {
          temp.push({city: city.slug, branchId: branch.id, ...pickupPoint})
        });
      });
    } else {
      city.branches.filter((b: any) => b.id === branch.id)[0]
      .pickupPoints?.forEach((pickupPoint: any) => {
        temp.push({city: city.slug, branchId: branch.id, ...pickupPoint})
      });
    }

    temp.forEach((p: any) => {
      p.title = formatAddress(p, [])
    })

    setPickupPoints(temp)

    if (address?.type === 'pickup' && address?.point?.city === city.slug) {
      onChange(address.point)
    } else {
      onChange(temp[0])
    }
  }, [city?.slug])

  return <InputSelector
    variants={pickupPoints.map(({title, id}: any) => ({name: title, id}))}
    value={value?.id}
    onChange={(id) => {onChange(pickupPoints.find((p: any) => p.id === id))}}
    reverseEllipsis={reverseEllipsis}
  />
}
