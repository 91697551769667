export const formatDate = (receivedDate: Date) => {
  function addZero(num: number) {
    if (num >= 0 && num <= 9) {
      return "0" + num;
    }
    return num;
  }
  const date =
    receivedDate.getFullYear() +
    "." +
    addZero(receivedDate.getMonth() + 1) +
    "." +
    addZero(receivedDate.getDate())

  const resTime = addZero(receivedDate.getHours()) + ':' + addZero(receivedDate.getMinutes())

  let resDate = date.split('.').reverse().join('.')

  return {resDate, resTime}
};

export const getDayAndFullMonth = (receivedDate: Date) => {
  return new Intl.DateTimeFormat("ru-RU", { day: "2-digit", month: "long" }).format(receivedDate)
}

export const getDate = (receivedDate: Date) => {
  return receivedDate.getDate()
}
