import React, { useEffect, useState } from 'react'
import { useCartContext } from '../../../../contexts/CartContext'
import { useAppContext } from '../../../../contexts/App'
import { formatCurrency } from '../../../../libs/helpers/formatCurrency'



export default function Сheck({deliveryAmount = 0, deliveryShow = false}: any) {
  const { cart } = useCartContext()
  const { allProducts, branch } = useAppContext()

  const [products, setProducts] = useState([])

  useEffect(() => {
    let tempProducts: any = {}
    cart.products.forEach((product) => {
      let productData: any = {}
              
      if (allProducts[product.productId]) {
        productData = allProducts[product.productId]
      }

      productData = {...productData, ...product}
      if (tempProducts[`${product.uid}${product.isGift}${product.isAdded}${!product.total}`]) {
        tempProducts[`${product.uid}${product.isGift}${product.isAdded}${!product.total}`].count += product.count
        tempProducts[`${product.uid}${product.isGift}${product.isAdded}${!product.total}`].amount += product.amount
        tempProducts[`${product.uid}${product.isGift}${product.isAdded}${!product.total}`].total += product.total
      } else {
        tempProducts[`${product.uid}${product.isGift}${product.isAdded}${!product.total}`] = productData
      }
    })

    setProducts(Object.values(tempProducts))
  }, [cart.products])


  return (
    <div className='min-h-full relative hidden md:block'>
      <div style={{maxHeight: window.innerHeight - 200 + 'px'}} className={`w-[300px] h-min sticky top-[160px] flex flex-col pt-8 pb-10 px-6 bg-white dark:bg-gray-50 shadow-[4px_4px_20px_rgba(0,0,0,0.07)] rounded-[29px] mt-[6px]`}>
        <div className='text-[20px] font-bold -tracking-[.01em] text-dark dark:text-light'>Состав заказа</div>
        <div className="overflow-y-auto pretty-scroll pr-[10px] mr-[-10px]">
          {
            products.map((productData: any) => {
              return (
                <div key={productData.uid} className='mt-[15px] pb-[15px] border-b-[1px] border-gray-20 dark:border-gray-40'>
                  <div className={'font-medium -tracking-[.01em] text-dark dark:text-light'}>{ productData?.title }</div>
                  {
                    !!(productData?.weight || productData?.size) && 
                    <div className={` text-sm flex gap-2 -tracking-[.01em] font-semibold text-gray-30`}>
                      { productData?.weight && <div>{ productData?.weight } г.</div> }
                      { productData?.size && <div>{ productData?.size } { productData?.measure_unit }</div> }
                    </div> 
                  }
                  <div className={'flex items-center gap-1 font-bold text-dark dark:text-light'}>
                    {
                      productData?.isGift ? 'Подарок' : 
                      <>
                        <span>{ productData?.count } x {formatCurrency(branch.extended.currency.code, productData?.itemAmount)}</span>
                      </>
                    }
                  </div>
                </div>
              )
            })
          }
        </div>

        <div className="flex justify-between mt-5 pb-[27px] border-b-[2px] border-gray-50 dark:border-gray-10 -tracking-[.01em] text-sm fomt-medium ">
          <div className="flex flex-col gap-1 text-dark dark:text-light">
            <div>{ cart.count } товара</div>
            {
              !!Math.floor(cart.discounts.bonusScoreWillBeAdded) &&
              <div>Начислим баллы</div>
            }
            {
              deliveryShow && (deliveryAmount || !branch.order.hideDeliveryPrice) &&
              <div>Доставка</div>
            }
          </div>

          <div className="flex flex-col gap-1 w-max text-dark dark:text-light">
            <div className="flex items-center gap-1">{formatCurrency(branch.extended.currency.code, cart.amount)}</div>
            {
              !!Math.floor(cart.discounts.bonusScoreWillBeAdded) &&
              <div>+ {Math.floor(cart.discounts.bonusScoreWillBeAdded)} баллов</div>
            }
            {
              deliveryShow && (deliveryAmount || !branch.order.hideDeliveryPrice) &&
              <div className='flex items-center gap-1'>{ deliveryAmount ? <>{formatCurrency(branch.extended.currency.code, deliveryAmount)}</> : 'Бесплатно' } </div>
            }
          </div>
        </div>

        <div className="flex justify-between mt-6 text-dark dark:text-light">
          <div>
            {cart.discounts.promoCodeDiscount > 0 && <div className='-tracking-[.01em] text-gray-40 font-bold'>Скидка</div>}
            {
              !!cart.discounts.bonusDiscount &&
              <div className='-tracking-[.01em] text-gray-40 font-bold'>Списано баллов</div>
            }
            <div className='mt-3 text-[20px] font-bold -tracking-[.01em]'>Сумма заказа</div>
          </div>
          <div className="w-max text-dark dark:text-light">
            {cart.discounts.promoCodeDiscount > 0 && <div className='flex items-center gap-1 text-gray-40 font-bold'>{formatCurrency(branch.extended.currency.code, cart.discounts.promoCodeDiscount)}</div>}
            {
              !!cart.discounts.bonusDiscount &&
              <div className='flex items-center gap-1 text-gray-40 font-bold'>{ cart.discounts.bonusDiscount }</div>
            }
            <div className="mt-3 flex items-center gap-1 text-[20px] font-bold">{formatCurrency(branch.extended.currency.code, cart.total)}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
