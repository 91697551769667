import React, {useEffect, useRef, useState} from "react";

export function SVGWrap({src, load = true, ...props}: any) {
  const ref = useRef<SVGSVGElement | null>(null)
  const [fallback, setFallback] = useState(false)
  const [xlink, setXlink] = useState(false)

  useEffect(() => {
    if (!load) return
    fetch(src, {
      method: "GET"
    })
      .then(r => r.text())
      .then((text) => {
        if (!text.includes('xlink:href')) {
          const container = document.createElement('div')
          container.innerHTML = text;
          const svg = container.getElementsByTagName('svg')[0];
          // Remove any invalid XML tags as per http://validator.w3.org
          svg.removeAttribute('xmlns:a');
          // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
          if(!svg.getAttribute('viewBox') && svg.getAttribute('height') && svg.getAttribute('width')) {
            svg.setAttribute('viewBox', '0 0 ' + svg.getAttribute('height') + ' ' + svg.getAttribute('width'))
          }
          if (ref.current) {
            while (ref.current.firstChild) {
              ref.current.removeChild(ref.current.firstChild)
            }
            for (let i = 0; svg.attributes[i]; i++) {
              let attr = svg.attributes[i];
              if (!props.hasOwnProperty(attr.name))
                ref.current.setAttribute(attr.name, attr.value);
            }
            while (svg.firstChild) {
              let el = svg.firstChild;
              svg.removeChild(el);
              ref.current.appendChild(el);
            }
            setFallback(false);
          }
        } else {
          setXlink(true);
        }
      })
      .catch(e => {
        setFallback(true);
      });
  }, [src, load])

  if (xlink || fallback || !load) {
    return <svg ref={ref} {...props}><image {...props} xlinkHref={src} ></image></svg>
  }

  return <svg ref={ref} {...props}></svg>
}
