import {useEffect, useState} from "react";

type BrowserTag = 'safari' | 'yandex' | 'iphone' | 'mobile_safari'
let cachedTags: (BrowserTag[] | null) = null

//Со временем будем пополнять
export function useDetectorBrowser() {
  const initialTags: BrowserTag[] = []
  const [result, setTags] = useState<BrowserTag[]>(initialTags)

  useEffect(() => {
    const tags: BrowserTag[] = []
    if (navigator.userAgent.includes('iPhone')) {
      tags.push('iphone')
    }
    if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
      tags.push('safari')
    }
    if (navigator.userAgent.search(/YaBrowser/) > 0) {
      tags.push('yandex')
    }
    if (tags.includes('iphone') && tags.includes('safari')) {
      tags.push('mobile_safari')
    }
    setTags(tags)
  }, [])
  return result
}
