import ArrowIcon from '../../icons/ArrowIcon';
import { useWindowResize } from '../../../hooks/useWindowResize';
import moment from 'moment';
import React, { useEffect, useRef } from 'react'
import { timeoutUntilSuccess } from '../../../libs/helpers/timeoutUntilSuccess';

type WrapperProps = {
  children: React.ReactNode,
  className: string,
  statuses: Status[],
  isMobileScreen: boolean,
}

export type Status = {
  status: string,
  title: string,
  createdAt: string
}

interface Props {
  statuses: Status[];
  acceptedStatus: Status,
  className?: string;
}

const Wrapper = (props: WrapperProps) => {
  const {
    children,
    className,
    statuses,
    isMobileScreen,
  } = props;

  const ref = useRef<any>(null);

  useEffect(() => {
    return timeoutUntilSuccess(() => {
      if (! ref.current) {
        return false;
      }

      ref.current.scrollTo({ left: ref.current.scrollWidth, behavior: 'smooth' })
      return true
    })
  }, [statuses.length]);

  // это для возможности перетаскивать блок для скролла на компьютерных разрешениях
  // (!!! при попытке таскать с включенным девтулом когда выбираем режим отзывч дизайна и вместо указателя мышки кружок -  работать будет только прри разр ниже 769...>
  //  >... так как это воспринимается как тап на телефоне плюс ниже прописано ограничение на мобильный скрин)
  let mouseDown = false;
  let startX: any, scrollLeft: any;

  const startDragging = (event: React.MouseEvent) => {
    mouseDown = true;
    startX = event.pageX - ref.current.offsetLeft;
    scrollLeft = ref.current.scrollLeft;
  }

  const stopDragging = () => {
    mouseDown = false;
  }

  const move = (event: React.MouseEvent) => {
    event.preventDefault();
    if (! mouseDown) return
    const x = event.pageX - ref.current.offsetLeft;
    const scroll = x - startX;
    ref.current.scrollLeft = scrollLeft - scroll;
  }

  const additionalProps = isMobileScreen ? {} : {
    onMouseMove: (event: React.MouseEvent) => {
      move(event)
    },
    onMouseLeave: (event: React.MouseEvent) => {
      stopDragging()
    },
    onMouseUp: (event: React.MouseEvent) => {
      stopDragging()
    },
    onMouseDown: (event: React.MouseEvent) => {
      startDragging(event)
    }
  }

  return (
    <div
      ref={ ref }
      className={ `relative w-full ${ className } overflow-x-auto no-scroll-bar pb-1` }
      { ...additionalProps }
    >
      { children }
    </div>
  );
}

const Status = ({ children, isMobileScreen, isEnd }: {
  children: React.ReactNode,
  isMobileScreen: boolean,
  isEnd: boolean,
}) => {
  const className = isEnd
    && 'min-w-[106px] flex w-full justify-end order__statuses-trace--status__container'
    || 'min-w-[106px] w-full flex flex-col items-center order__statuses-trace--status relative order__statuses-trace--status__container';

  const status = isEnd
    && (<div className={ 'flex flex-col items-center order__statuses-trace--status relative' }>{ children }</div>)
    || (<>{ children }</>);

  return (
    <div style={ isMobileScreen ? {} : { userSelect: 'none', touchAction: 'none' } } className={ className }>
      { status }
    </div>
  );
}

export default function StatusBar({ statuses, acceptedStatus, className = '' }: Props) {
  const isMobileScreen = useWindowResize({ cvt: (w) => w <= 768 });
  const isWarning = statuses.at(-1)?.status === 'cancelled';
  const cancelledTextClassName = isWarning ? 'text-yellow' : 'text-main';
  const cancelledBorderClassName = isWarning ? 'border-yellow' : 'border-main'
  const isEnd = !! statuses.length && ['cancelled', 'completed'].includes(statuses.at(-1)?.status || '');

  const getStatuses = () => {
    return [acceptedStatus, ...statuses];
  }

  const renderedStatuses = getStatuses().map((status) => {
    return (
      <Status key={ status.createdAt } isMobileScreen={ isMobileScreen } isEnd={ isEnd }>
        <div>{ status.title }</div>
        <div
          className={ `mb-[10px] text-xs text-gray-30` }>{ moment(status.createdAt).format('HH:mm') }</div>
        <div
          className={ `w-[15px] h-[15px] rounded-full bg-gray-10 border-[3px] z-20 ${ cancelledBorderClassName }` }></div>
      </Status>
    )
  });

  return (
    <Wrapper className={ className } statuses={ statuses } isMobileScreen={ isMobileScreen }>
      <div
        className={ `relative z-[5] ${ isEnd ? 'order__statuses-trace--finished' : 'order__statuses-trace--processing' } ${ isWarning && 'order__statuses-trace--status__container--warning' || '' }` }>
        <div
          className={ `flex justify-between text-sm font-bold z-20 order__statuses-trace relative ${ cancelledTextClassName }` }
          style={ { minWidth: `${ statuses.length * 118 }px` } }>
          { renderedStatuses }
          <ArrowIcon
            className="absolute bottom-[-4px] right-0 w-[15px] h-[23px] opacity-25 order__statuses-trace__arrow"
            colorClassName="fill-main"/>
        </div>
      </div>
    </Wrapper>
  );
}
