import React from 'react'
import { Preloader } from '../preloader';

interface ICounter {
  count: number|string;
  add: () => void;
  remove: () => void;
  disabledAdd?: boolean;
  className?: string;
  isAdded?: boolean;
  price?: any
  loading?: boolean;
}

export default function Counter({ price, count, add, remove, disabledAdd=false, className='', isAdded=false, loading=false }: ICounter) {
  return (
    <div className={`flex w-[110px] h-[35px] items-center justify-between border-[2px] overflow-hidden rounded-[18px] ${isAdded? 'border-gray-30 text-gray-30' : 'border-main text-main'}  ${className}`} onClick={event => event.stopPropagation()}>
      {
        loading ? 
        <Preloader countOfDot={3} size={'8px'} className={'min-w-[110px] gap-1 w-full '}/> :
        <>
          <div onClick={() => !loading && remove()} className={`h-full flex justify-center items-center ${isAdded? '' : 'cursor-pointer'}  w-[45px]`}>
            <svg className={`${isAdded? 'fill-gray-30 stroke-gray-30 ' : 'fill-main stroke-main hover:opacity-50'}`} width="15" height="3" viewBox="0 0 15 3" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.8" y="2.2" width="1.5" height="13.4" transform="rotate(-90 0.8 2.2)" strokeWidth="0.5"/>
            </svg>
          </div>
          {price?
            <div>{price} x {count}</div>
          :
            <div>{count}</div>
          }
          
          <div onClick={() => !loading && add()} className={`${disabledAdd && 'opacity-50 pointer-events-none'} justify-center w-[45px] h-full flex items-center ${isAdded? '' : 'cursor-pointer'}`}>
            <svg className={`${isAdded? 'fill-gray-30 stroke-gray-30 ' : 'fill-main stroke-main hover:opacity-50'}`} width="16" height="16" viewBox="0 0 15 15"  xmlns="http://www.w3.org/2000/svg">
              <path d="M8.2 1V0.8H8H7H6.8V1V6.8H1H0.8V7V8V8.2H1H6.8V14V14.2H7H8H8.2V14V8.2H14H14.2V8V7V6.8H14H8.2V1Z" strokeWidth="0.5"/>
            </svg>
          </div>
        </>
      }
    </div>
  )
}
