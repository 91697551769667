import {checkResponse, prepareConfig} from "./helpers";
import {serialize} from "../helpers/serialize";

/**
 * Отправка запроса к бэкенду
 * @param {string} url
 * @param method
 * @param query
 * @param body
 * @param {object} options
 * @return {Promise<any>}
 */

export async function apiRequest(path: string, {method = 'GET', query = {}, body, ...options}: any = {}): Promise<any> {
  const config: any = prepareConfig(method, options);

  if (body instanceof FormData) {
    delete config.headers['Content-Type'];
    config['body'] = body;
  } else {
    body && (config['body'] = JSON.stringify(body));
  }

  if (path.endsWith("company-info")) {
    delete config.headers['branch']
  }

  const url = new URL(`${process.env.REACT_APP_BACKEND_BASE_URI}${path}`)

  if (0 < Object.keys(query).length) {
    url.search = serialize(query);
  }

  const request = new Request(url.toString(), config);

  return new Promise((resolve, reject) => {
    fetch(request)
      .then(async response => {
        let reason;

        if ((reason = await checkResponse(response))) {
          reject(reason);
        }

        const {status} = response;

        response.clone().json()
        .then(data => { data.status = status; resolve(data); })
        .catch(() => response.text())
        .then(data => resolve(data))
      })
      .catch(error => {
        reject(error)
      })
  })
}
