import React, {useEffect, useRef, useState} from "react"
import {loadAndProcessImage} from "./CropAndResizeImage";
import CloseIcon from "../../icons/CloseIcon";
import imageWithoutAddedPhotos from '../../../images/imageWithoutAddedPhotos.svg'

interface IImageLoadProps {
  className?: string
  classPlaceholderBox?: string
  checkImageValid?: (img: HTMLImageElement, dataUrl: string, file: File) => string | null
  onInput: Function
  name: string
  errors?: any
  placeholder?: string
  onRemove?: Function
  forceAspectRatio?: any
  resizeTo?: Array<number>|any
  allowUpscale?: any,
  inPopup?: boolean,
}

export default function ImageLoad({
  className='',
  checkImageValid=()=>null,
  onInput,
  name,
  errors = {},
  onRemove=()=>{},
  forceAspectRatio = null,
  resizeTo = null,
  allowUpscale = false,
  inPopup = false,
}: IImageLoadProps) {
  const input = useRef<any>(null);
  const [files, setFiles] = useState<any>([])
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [incorrectFilesError, setIncorrectFilesError] = useState('');
  const error = errors[name];

  const MAX_FILE_SIZE = 5242880;

  useEffect(() => {
    onInput && onInput(files.map((file: any, index: number) => { return {file, position: index} }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);
	
  const handleInput = ({target}: any) => {
    let targetFiles: any = []
    const urls: any = []
    const reasons: any[] = []

    setIncorrectFilesError('')
    delete errors[name]

    const bigFiles = [];
    targetFiles = targetFiles.filter((file: any) => {
      if (MAX_FILE_SIZE < file.size) {
        bigFiles.push(file.name)
      }

      return MAX_FILE_SIZE >= file.size
    })

    if (0 < bigFiles.length) {
      setIncorrectFilesError(`Не все файлы были добавлены: слишком большой размер. Максимальный размер файла: 5Mb`)
    }

    Promise.allSettled([...target.files].map(getImageUrl)).then(results => {
      results.map((result) => {
        const {value, reason}: any = result
        if (reason === 'cancel')
          return

        if (value && !selectedFiles.includes(value.url)) {
          urls.push(value.url);
          targetFiles.push(value.file);
        }
        reason && reasons.push(reason);
      })
      0 < urls.length && setFiles((files: any) => [...files, ...targetFiles]);
      0 < urls.length && setSelectedFiles((selectedFiles: any) => [...selectedFiles, ...urls])
      0 < reasons.length && (setIncorrectFilesError(value => `${0 >= value.length && 'Не все выбранные файлы были добавлены: ' || ', '} ${reasons.join(', ')}`))
    }).then(() => {
      if (input.current)
        input.current.value = null
    })
  }

  function getImageUrl(file: any) {
    return loadAndProcessImage(file, {
      forceAspectRatio,
      resizeTo,
      allowUpscale,
      checkImageValid,
    });
  }

  const handleClear = (currentPhoto: string, index: number) => {
    input.current.value = null;
    setFiles((prev: any) => prev.filter((_: any, photo: number) => photo !== index));
    setSelectedFiles((prev: string[]) => prev.filter((photo: string) => photo !== currentPhoto));
    setIncorrectFilesError('');
    onRemove()
    delete errors[name];
  }

  const handleClick = () => {
    input.current.click();
  }

  return (
    <div>
        <div className={`flex gap-3 ${className}`}>
      {selectedFiles.length > 0 &&
        <div className="flex flex-wrap gap-3">
          {selectedFiles.map((item: any, index: any) => (
            <div key={index} className=''>
              <div className={`${inPopup? 'w-[65px] h-[65px]' : 'w-[120px] h-[120px]'} relative flex item-center justify-center`}>
                <div className="overflow-hidden rounded-[18px] w-full">
                  <img src={item} className="h-full w-full object-cover" alt={'uploaded-image'} />
                </div>
            
                <div onClick={() => handleClear(item, index)} className={"absolute cursor-pointer  top-[-5px] flex justify-center items-center  right-[-5px] rounded-full  w-[20px] h-[20px] bg-main"}>
                  <CloseIcon
                    colorClassName={"fill-white"}
                    className={"w-[10px] h-[10px]"}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      }

      {
        selectedFiles.length < 4 &&
        <div onClick={handleClick} className={`${inPopup? 'w-[65px] h-[65px]' : 'w-[120px]'} cursor-pointer `}>
          <img src={imageWithoutAddedPhotos} alt="" className="w-full h-full"/>
        </div>
      }

      <input className="invisible absolute w-0" ref={input} onChange={handleInput} type="file" name="images" accept="image/*" multiple={true} />
   
    </div>
    {incorrectFilesError && (<span className={'text-yellow text-xs font-medium'}>{incorrectFilesError}</span>)}
    {error && (<span className={'text-yellow text-xs font-medium'}>{error}</span>)}
    </div>
    
  )
}
