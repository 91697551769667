import React, { useEffect, useState } from "react";
import { useAppContext } from "../../../../../contexts/App";
import { Link, useParams } from "react-router-dom";
import { apiClient } from "../../../../../libs/api/apiClient";
import { formatDate } from "../../../../../libs/helpers/formatDate";
import ArrowNext from "../../../../../components/icons/ArrowNext";
import { formatAddress } from "../../../../../libs/helpers/formatAddress";
import { BaseButton } from "../../../../../components/common/button/BaseButton";
import StatusBar from "../../../../../components/pages/OrderPage/StatusBar";
import Product from "../../../../../components/common/products/Product";
import GiftProduct from "../../../../../components/common/products/GiftProduct";
import { Preloader } from "../../../../../components/common/preloader";
import { ProfileLayout } from "../../../../../components/layouts/profile";


interface IOptions {
  option: {
    title: string;
    weight: string;
    volume: number;
  };
  quantity: number;
  amount: number;
}

interface IGoods {
  good: {
    title: string;
    weight: number | null;
    volume: number | null;
    measureUnit: string | null;
    size: string | null;
    description: string;
    image: string;
    skuGroupsValues: [
      {
        valueId: string;
        skuGroupId: number;
        title: string;
      }
    ];
  };
  options: IOptions[];
  quantity: number;
  amount: number;
  discount: number | null;
  total: number;
  isGift: boolean;
  bonusDiscount: number
}

interface IOrderDetails {
  id: number;
  createdAt: string;
  status: string;
  total: number | null;
  review: {
    status: string;
    estimation: string;
  } | null;
  address: { title: string } | null;
  pickupPoint: {
    address: {
      title: string;
    };
  } | null;
  flat: string | null;
  entrance: string | null;
  floor: number | null;
  amount: number;
  discount: number | null;
  completedAt: string;
  statusTraces: any[];
  clientName: string;
  clientPhone: string | null;
  goods: IGoods[];
  paymentType: string
  bonusDiscount: number
  deliveryAmount: number
  payment: {
    isPayed: boolean
    paymentLink: string | null;
  }
}

export default function OrderDetails() {
  const [order, setOrder] = useState<IOrderDetails | null>(null);
  const [resDateAndTime, setResDateAndTime] = useState<any>(null);
  const { city, company } = useAppContext();
  let { orderId } = useParams();

  useEffect(() => {
    const fetchOrders = async () => {
      await apiClient.profileOrders
        .getOrder(Number(orderId))
        .then(({ data }: any) => {
          setOrder(data)
          setResDateAndTime(formatDate(new Date(data.completedAt)))
        });
    };
    fetchOrders();

    const timer = setInterval(() => {
      fetchOrders()
    }, 30000);
    
    return () => clearInterval(timer);
  }, []);

  const paymentTypes: any = {
    'cash': "Наличные",
    'on-delivery': "Картой при получении",
    'online': "Online на сайте",
    'transfer': "Мобильный банк (перевод)",
  }

  return (
    <ProfileLayout>
      {order ? (
        <div className={"w-full flex"}>
          <div className={"w-full flex flex-col gap-y-3"}>
            <div className={"relative flex items-center gap-x-3"}>
              <Link
                to={`/${city.slug}/account/history`}
                className={"block lg:hidden"}
              >
                {" "}
                <ArrowNext colorClassName={"fill-gray-50 hover:fill-gray-40"} />
              </Link>
              <p className={"text-2xl font-medium text-dark dark:text-light"}>История заказов</p>
            </div>

            <p className={"text-xl font-medium mt-4 text-dark dark:text-light"}>Заказ №{orderId}</p>
            <div className="xs:leading-9 leading-7">
              {order.status === "completed" && (
                <p className={"text-gray-50 font-bold dark:text-white"}>
                  Доставлен
                  <span className="ml-[10px]">{resDateAndTime.resDate}</span> 
                  <span className="ml-[10px]">{resDateAndTime.resTime}</span>
                </p>
              )}

              {order.address &&
                <p className="text-gray-50 dark:text-white">
                  {formatAddress(order.address, [])}
                  <span className="ml-[5px]">
                    {order.entrance && <span className="mr-[3px]">{`под ${order.entrance},`}</span> }
                    {order.floor && <span className="mr-[3px]">{`эт ${order.floor},`}</span> }
                    {order.flat && <span className="mr-[3px]">{`кв ${order.flat}`}</span> }
                  </span>
                </p>
              }
              {order.pickupPoint &&
                <p className={"text-gray-50 dark:text-white"}>
                  {formatAddress(order.pickupPoint.address, [])}
                </p>
              }
              
              <p className={"text-gray-50 font-bold dark:text-white"}>Оплата: {paymentTypes[order.paymentType]}</p>

              <div className="mt-[10px] xs:mb-[10px] md:max-w-[calc(100vw-180px-1.25rem-30px)] lg:max-w-[calc(100vw-180px-1.25rem-150px)]">
              <StatusBar statuses={order.statusTraces} acceptedStatus={{status: 'accepted', createdAt: order.createdAt, title: company.statuses['accepted'].title || 'Принят'}}/>
              {
                order.paymentType === 'online' && !order?.payment?.isPayed && order?.payment?.paymentLink &&
                <a href={order?.payment?.paymentLink}  target='_blank' className="flex justify-end mt-4"> 
                  <BaseButton className='bg-main text-white font-bold'> Оплатить заказ</BaseButton>
                </a>
              }
              </div>
              
            </div>

            <div className={"flex flex-col gap-y-3"}>
              {
                order.goods.map((good, index) => {
                  if(!good.isGift){
                    return <Product
                      key={index}
                      productData={{
                        title: good.good.title,
                        weight: good.good.weight,
                        size: good.good.size,
                        measure_unit: good.good.measureUnit,
                        shortDescription: good.good.description,
                        skuGroupsValues: good.good.skuGroupsValues,
                        amount: good.total / good.quantity,
                        options: good.options?.map(o => ({count: o.quantity, title: o.option.title})) || [],
                        image: good.good.image,
                        count: good.quantity
                      }}
                      isLastProduct={order.goods.length - 1 == index}
                    />
                  }
                })
              }
              {order.goods.map((good, index) => {
                if(good.isGift){
                  return <GiftProduct key={index} productData={good.good}/>
                }
              })}
            </div>

            <div className={"flex flex-col gap-y-1 font-medium mt-[40px]"}>
              <div className={"flex justify-between text-dark dark:text-light"}>
                <p className={"flex text-xl"}>Сумма:</p>
                <p>{order.amount} ₽</p>
              </div>
              <div className={"flex justify-between"}>
                <p className={"flex text-md text-gray-40 dark:text-gray-30"}>Скидка:</p>
                <p className={"flex text-md text-gray-40 dark:text-gray-30"}>{order.discount? `${order.discount} ₽` : '0'}</p>
              </div>
              <div className={"flex justify-between border-b dark:border-gray-40 pb-3"}>
                <p className={"flex text-md text-gray-40 dark:text-gray-30"}>Списано баллов:</p>
                <p className={"flex text-md text-gray-40 dark:text-gray-30"}>{order.bonusDiscount? order.bonusDiscount : '0'}</p>
              </div>
              <div className={"flex justify-between border-b dark:border-gray-40 pb-3"}>
                <p className={"flex text-md text-gray-40 dark:text-gray-30"}>Стоимость доставки:</p>
                <p className={"flex text-md text-gray-40 dark:text-gray-30"}>{order.deliveryAmount? order.deliveryAmount : '0'}</p>
              </div>
              {/* <hr className={"my-2"} /> */}
              <div className={"flex justify-between mt-2"}>
                <p className={"text-2xl text-main"}>Итого:</p>
                <p className={"text-2xl text-dark dark:text-light"}>{order.total} ₽</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={"my-4 flex justify-center w-full"}>
          <Preloader countOfDot={4} size={"10px"} />
        </div>
      )}
    </ProfileLayout>
  );
}

