import { useAppContext } from "../../../../../contexts/App";
import productImgStub from '../../../../../images/productImgStub.jpg'
import { formatCurrency } from "../../../../../libs/helpers/formatCurrency";
import Counter from "../../../../common/button/Counter";


export default function Option({ option, addOption, removeOption, count, canMany, isMaxLimit }: any) {
  const {branch} = useAppContext()
  return (
    <div 
      onClick={() => {
        !count && !isMaxLimit && addOption(option.id, option.price, option.title);
        count && !canMany && removeOption(option.id);
      }} 
      className={`p-[2px] relative w-full flex flex-col items-center border-[2px] border-transparent rounded-[13px] overflow-hidden ${count && '!border-main shadow-md'} ${(!count && canMany && !isMaxLimit || !canMany) && 'cursor-pointer'}`}
    >
      {option.image
        ? <img className={`w-full object-cover h-[92px] rounded-[10px]`} src={option.image} alt="" />
        : <img
          className={`h-[92px] object-cover rounded-[10px]`}
          src={productImgStub}
          alt=""
        />
      }
      <div className={` text-center text-xs font-bold text-gray-50 dark:text-gray-10 grow my-1 md:my-[10px]`}>{option.title}</div>
      {
        canMany ?
        <>
          {
            count ? 
            <>
              <Counter
                count={count}
                add={() => addOption(option.id, option.price, option.title)}
                remove={() => removeOption(option.id, option.groupId)}
                disabledAdd={isMaxLimit}
                className='border-none'
              />
              <div className={'absolute top-1 left-1 flex items-center px-1 gap-1 text-xs bg-main rounded-full'}>
                <div className='font-bold text-white'>{formatCurrency(branch.extended.currency.code, option.price)}</div>
              </div>
            </>
            :
            <div className={`flex items-center justify-center gap-1 mb-[10px]`}>
              <div className='font-bold text-main'>{formatCurrency(branch.extended.currency.code, option.price)}</div>
            </div>
          }
        </> : 
        <>
          <div className={`flex items-center justify-center gap-1 mb-[10px]`}>
            <div className='font-bold text-main'>{formatCurrency(branch.extended.currency.code, option.price)}</div>
          </div>
          {
            !!count &&
            <div className={'absolute top-1 left-1 flex items-center justify-center w-[19px] h-[19px] gap-1 text-xs bg-main rounded-full'}>
              <svg className='mt-[2px]' width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.7907 0.749366L10.1743 0.175493C9.91787 -0.063194 9.51946 -0.057755 9.26966 0.187843L4.98474 4.35314L1.73104 1.13175C1.48147 0.88466 1.08185 0.878507 0.824819 1.1178L0.209086 1.69103C-0.0616869 1.94311 -0.0706865 2.36959 0.189213 2.63292L3.4492 5.93582L4.77588 7.27154C4.89164 7.38809 5.07961 7.38986 5.19755 7.27553L7.54432 5.00049L10.8105 1.69125C11.0704 1.42793 11.0614 1.00145 10.7907 0.749366Z" fill="#ffffff"/>
              </svg>
            </div>
          }
        </>
      }
    </div>
  )
}
