export const findDiffDate = (fromTo: string) => {
  const nowDate: Date = new Date();
  const laterDate: Date = new Date(fromTo);

  const diff = (laterDate.getTime() - nowDate.getTime()) / 1000;
  let days = Math.floor(diff / 86400); 
  let hours = Math.floor(diff / 3600 - days * 24);
  let mins = Math.floor(diff / 60 - hours * 60)

  // months: Math.floor(diff / 604800),

  return {days, hours, mins};
};
