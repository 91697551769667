import React, { useState, useEffect } from 'react'
import Slider from "react-slick";
import productImgStub from '../../../../../images/productImgStub.jpg'
import { resizeImage } from '../../../../../libs/helpers/resizeImage';

export default function ImagesSlider({ product=[], ...props }: any) {
  const [images, setImages] = useState<any>([])

  useEffect(() => {
    if (product.image) {
      setImages([product.image, ...product.images.map((img: any) => img.link)])
    } else {
      setImages(product.images.map((img: any) => img.link))
    }
  }, [product])

  return (
    <div className='m-auto max-w-[395px] w-full md:min-w-[395px] md:w-[395px] product-detail-slider'>
      {images.length ? 
        <Slider
          dots={true}
          arrows={false}
          infinite={false}
          {...props}
        >
          {images.map((img: string) => (
            <div key={img} className='rounded-2xl overflow-hidden'>
              <picture>
                <source type="image/webp" srcSet={`${resizeImage(img, '400', 'webp')} 1x, ${resizeImage(img, '600', 'webp')} 1.5x, ${resizeImage(img, 0, 'webp')} 2x`}/>    
                <source type="image/jpeg" srcSet={`${resizeImage(img, '400', 'jpg')} 1x, ${resizeImage(img, '600', 'jpg')} 1.5x, ${resizeImage(img, 0, 'jpg')} 2x`}/>
                <img 
                  src={resizeImage(img, '400', 'jpg')} 
                  srcSet={`${resizeImage(img, '600', 'jpg')} 1.5x, ${resizeImage(img, 0, 'jpg')} 2x`}
                  alt=""
                />
              </picture>
            </div>
          ))}
        </Slider> :
        <img
          className='rounded-2xl'
          src={productImgStub}
          alt=""
        />
      }
  
    </div>
  )
}
