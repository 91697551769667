export function formatAddress(point: any, without: string[], isRequest: boolean = false) {
  if (!point) return ''
  let addr: string[] = [];

  const hasHouse = (point?.house && !without.includes('house')) && ('null' !== `${point?.house}`);
  const hasCity = ((point?.cityWithType) && !without.includes('city')) && ('null' !== `${point?.cityWithType}`);
  const hasStreet = (point?.streetWithType && !without.includes('street')) && ('null' !== `${point?.streetWithType}`);
  const hasBlock = (point?.block && !without.includes('block') && 'null' !== `${point?.block}`)

  if (isRequest) {
    const hasRegion = ((point?.region || point?.regionWithType) && !without.includes('region'));
    hasRegion && addr.push(`${point.regionWithType}, `)
  }

  hasCity && addr.push(`${point.cityWithType}` + (hasStreet ? ',' : ''));
  hasStreet && addr.push(`${point.streetWithType}` + (hasHouse ? ',' : ''));
  hasHouse && addr.push(`д ${point.house}`);
  hasBlock && addr.push(`/${point?.block}`);
  return addr.join(' ');
}

