import {apiClient} from "../api/apiClient"
import { localStorageWrap } from "../helpers/localStorageWrap";

type BaseResponse = {
  company: object | null,
  branch?: any,
  city?: any
}

export default async function getBase(): Promise<BaseResponse> {
  const query = getQuery();

  let response = await apiClient.company.base(query);

  if (200 !== response.status && query.slug) {
    localStorageWrap.removeItem('city')
    localStorageWrap.removeItem('branchId')

    response = await apiClient.company.base({});
  }

  if (200 !== response.status) {
    return {company: null}
  }

  return response.data as BaseResponse;
}

const getQuery = (): {slug?: string, branch?: string} => {
  const city = localStorageWrap.getItem('city');
  const branch = localStorageWrap.getItem('branchId');

  if (! city) {
    return {};
  }

  if (city && branch) {
    return {slug: city, branch};
  }

  if (city) {
    return {slug: city};
  }

  return {branch};
}