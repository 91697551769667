import React, { useEffect, useState } from 'react'

interface ITextarea {
  name?: string;
  value: string;
  readOnly?: boolean;
  onChange: (value: string) => void,
  onFormat?: (value: string) => string | null,
  autoComplete?: string;
  className?: string;
  placeholder?: string;
  maxLength?: number;
  textareaClassName?: string;
  errorLabel?: boolean,
  errors?: any,
}

export default function  Textarea({
  name='',
  value,
  readOnly = false,
  onChange=()=>{},
  autoComplete = 'off',
  className = '',
  placeholder = '',
  maxLength,
  textareaClassName = '',
  errorLabel = true,
  onFormat,
  errors = {},
}: ITextarea) {
  const [enteredSymbols, setEnteredSymbols] = useState(0);

  const error = errors[name]; 

  useEffect(() => {
    delete errors[name];
  }, [value]);

  return (
    <div className={`${className} `}>
      <textarea
        maxLength={maxLength}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={e => {
          let val: string | null = e.target.value || '';
          if (onFormat) val = onFormat(val);
          onChange(val != null ? val : value);
          setEnteredSymbols(e.target.value.length)
        }}
        readOnly={readOnly}
        style={{outlineStyle: 'none'}}
        className={`${error && 'border-[1px] border-yellow'} h-[135px] resize-none outline-0 disabled:opacity-50 disabled:pointer-events-none py-[10px] px-[25px] dark:[&::-webkit-input-placeholder]:text-white w-full text-sm bg-gray-20 dark:bg-gray-40 rounded-[10px] text-dark dark:text-light ${textareaClassName}`}
        autoComplete={autoComplete}
      ></textarea>
      {error && errorLabel && (<span className={'text-yellow text-xs font-medium'}>{error}</span>)}
      {
        !!maxLength && 
        <p className="xs:text-sm text-xs text-gray-30 text-end mt-[5px]">
          {enteredSymbols} / {maxLength}
        </p>
      }
    </div>
  )
}
