import React, { useState, useEffect, useMemo } from 'react'
import { useAppContext } from '../../../../contexts/App'
import { useAddressContext } from '../../../../contexts/AddressContext'
import { Preloader } from '../../../common/preloader'
import PickupPointsSelector from '../../../common/form/PickupPointsSelector'
import { InputSelectorAddressSearchWithSaved } from '../../../common/form/input/InputSelectorDadata'
import { TextInput } from '../../../common/form/input/TextInput'
import { formatAddress } from '../../../../libs/helpers/formatAddress'
import { apiClient } from '../../../../libs/api/apiClient'

export default function AddressForm({data, updateField, errors, deliveryAddress, pickupPoint}: any) {
  const { city, branch, company } = useAppContext()

  const {trySaveDeliveryPoint, trySavePickupPoint, allUserAddresses, addressError} = useAddressContext()

  const [isLoadingButton, setLoadingButton] = useState('none')
  
  const allPickupPoints: any[] = useMemo(() => {
    let out: any = []
    city.branches.forEach((branch: any) => {
      branch.pickupPoints?.forEach((pickupPoint: any) => {
        out.push({city: city.slug, branchId: branch.id, ...pickupPoint})
      });
    });

    return out
  }, [])

  useEffect(()=> {
    if (allPickupPoints.length === 0) {
      updateField('deliveryType', 'courier')
    }
  }, [allPickupPoints.length, data.deliveryType])

  return (
    <>
      {
        branch.order.fields?.deliveryType?.isUsed && city.branches.reduce((accumulator: boolean, currentValue: any) => accumulator || (currentValue.hasDeliveryZones && !currentValue.pickupOnly), false) ?
        <div className='flex gap-[30px]'>
          <div 
            className={`flex items-center justify-center h-[38px] w-full rounded-[18px] text-sm font-bold cursor-pointer duration-500 
            ${data.deliveryType === 'courier' ? 'bg-main text-white' : 'bg-orderbtn text-main hover:bg-main hover:text-white'}
            ${isLoadingButton === 'pickup' ? 'opacity-50 !cursor-default' : ''}
            `}
            onClick={() => {
              if (isLoadingButton === 'pickup') return
              if (data.deliveryType === 'courier') return
              if (deliveryAddress.addr) {
                setLoadingButton('courier')

                trySaveDeliveryPoint(deliveryAddress.addr).then((success: any) => {
                  if (success === true) {
                    updateField('deliveryType', 'courier')
                  }
                  setLoadingButton('none')
                })
              } else {
                updateField('deliveryType', 'courier')
              }

            }}
          >{isLoadingButton === 'courier'? <div> <Preloader size={'10px'} color={'white'}/></div>: <p>Доставка</p>}</div>

          <div
            className={`flex items-center justify-center h-[38px] w-full rounded-[18px] text-sm font-bold cursor-pointer duration-500 
            ${data.deliveryType === 'pickup' ? 'bg-main text-white' : 'bg-orderbtn text-main hover:bg-main hover:text-white'} 
            ${allPickupPoints.length === 0 || isLoadingButton === 'courier' ? 'opacity-50 !cursor-default' : ''}`}
            onClick={() => {
              if (isLoadingButton === 'courier') return
              if (data.deliveryType === 'pickup') return
              if (allPickupPoints.length !== 0) {
                if (pickupPoint) {
                  setLoadingButton('pickup')
                  trySavePickupPoint(city, pickupPoint).then((success) => {
                    if (success) {
                      updateField('deliveryType', 'pickup')
                    }
                    setLoadingButton('none')
                  })
                } else {
                  updateField('deliveryType', 'pickup')
                }
              }
            }}
          >{isLoadingButton === 'pickup'? <div> <Preloader size={'10px'} color={'white'}/></div>: <p>Самовывоз</p>}</div>
        </div> :
        <div className='text-main font-bold'>{ data.deliveryType === 'courier' ? 'Адрес доставки' : 'Адрес самовывоза' }</div>
      }

      {
        data.deliveryType === 'pickup' ? 
        <div>
          <PickupPointsSelector
            value={pickupPoint}
            onChange={(point: any) => {
              if (point) {
                setLoadingButton('pickup')
                trySavePickupPoint(city, point).then(() => {
                  setLoadingButton('none')
                })
              }
            }}
            filterByCity={true}
            reverseEllipsis={true}
            city={city}
          />
        </div> : 
        <>
          <InputSelectorAddressSearchWithSaved
            value={deliveryAddress}
            placeholder={"Добавить новый адрес"}
            onChange={async (addr: any) => {
              if (addr) {
                setLoadingButton('courier')
                let { data, isCoordinatesCanBeClarify } = await apiClient.suggestions.getAddressSuggestions(addr.name, [city.guid], city.lat, city.lon)

                if (!data || (data && data?.length == 0)) {
                  console.log('не найден адрес')
                  return
                }

                if (isCoordinatesCanBeClarify && data[0]?.isCoordinatesNeedToClarify) {
                  let clarifyCoords = await apiClient.suggestions.getCoordsByAddress(formatAddress(data[0], [], true))
                  console.log({clarifyCoords})
                  if (clarifyCoords.data) {
                    await trySaveDeliveryPoint({ ...data[0], lat: clarifyCoords.data.lat, lon: clarifyCoords.data.lon, name: formatAddress(data[0], []) })
                    setLoadingButton('none')
                  }
                  return
                }

                data = data?.map(({ addressId, ...o }: any, i: number) => ({
                  type: 'delivery',
                  id: i,
                  name: formatAddress(o, []),
                  deliveryAddressId: addressId || null,
                  pickupPointId: null,
                  lat: parseFloat(o.lat),
                  lon: parseFloat(o.lon),
                  rawData: o
                }))
            
                if (data[0]) {
                  console.log({setDeliveryPoint: JSON.stringify({ ...data[0].rawData, name: data[0].name })})
                  await trySaveDeliveryPoint({ ...data[0].rawData, name: data[0].name })
                  setLoadingButton('none')
                }
              }
            }}
            without={[]}
            guids={[]}
            suggestionsGuids={city ? [city.guid] : []}
            lat={city ? city.lat : null}
            lon={city ? city.lon : null}
            allUserAddresses={allUserAddresses}
            reverseEllipsis={true}
          />
          
          <div className="flex gap-5">
            {
              branch.order.fields?.entrance?.isUsed &&
              <TextInput
                className='w-full'
                name={'entrance'}
                value={data.entrance || ''}
                onChange={(value) => updateField('entrance', value)}
                placeholderInput={`Подъезд${branch.order.fields?.entrance.isRequired ? '*' : ''}`}
                errors={errors}
                errorLabel={false}
              />
            }
            {
              branch.order.fields?.floor?.isUsed &&
              <TextInput
                className='w-full'
                name={'floor'}
                value={data.floor || ''}
                onChange={(value) => {
                  if (/^[0-9.]+$/.test(value)) {
                    updateField('floor', value)
                  }
                }}
                type='number'
                placeholderInput={`Этаж${branch.order.fields?.floor.isRequired ? '*' : ''}`}
                errors={errors}
                errorLabel={false}
              />
            }
            {
              branch.order.fields?.flat?.isUsed &&
              <TextInput
                className='w-full'
                name={'flat'}
                value={data.flat || ''}
                onChange={(value) => updateField('flat', value)}
                placeholderInput={`Квартира${branch.order.fields?.flat.isRequired ? '*' : ''}`}
                errors={errors}
                errorLabel={false}
              />
            }
          </div>
        </>
      }

      {
        addressError?.message &&
        <div className='text-yellow text-xs'>{addressError?.message}</div>
      }
      {
        (!isLoadingButton && data.deliveryType === 'courier' && data.deliveryZoneId == null && data.deliveryAddress && company.isCustomerAddressMustBeInDeliveryZone) &&
        <div className='text-yellow text-xs'>Выбранный адрес не входит в зону доставки</div>
      }
      {
        (!isLoadingButton && data.deliveryType === 'courier' && data.branchId != null && data.branchId !== branch.id && company.isCustomerAddressMustBeInDeliveryZone) &&
        <div className='text-yellow text-xs'>Выбранный адрес входит в зону доставки другого филиала</div>
      }
      {
        errors?.deliveryType && 
        <div className='text-yellow text-xs'>{ errors?.deliveryType[0] }</div>
      }

      {
        errors?.deliveryAddress && 
        <div className='text-yellow text-xs'>{ errors?.deliveryAddress[0] }</div>
      }
    </>
  )
}
