import React, { createContext, useCallback, useContext, useState, } from 'react'

const LoaderContext = createContext((func: any) => {})
const LoaderStateContext = createContext<boolean>(true)

export function LoaderContextWrapper({children}: any) {
  const [loader, setLoader] = useState(false);

  return (
    <LoaderStateContext.Provider value={loader}>
      <LoaderContext.Provider value={setLoader}>
        {children}
      </LoaderContext.Provider>
    </LoaderStateContext.Provider>
  )
}

export function useLoaderStateContext() {
  return useContext(LoaderStateContext)
}

export function useLoaderContext() {
  const setLoader = useContext(LoaderContext)
  const cb = useCallback((state: any) => setLoader(state), [setLoader])
  return cb;
}
